import { ValueWithProportionalChange } from "../../data-store";
import { ImpactCategory } from "../../domain/impactCategories";
import assertNever from "../../util/assertNever";

export function impactMagnitude(
  impact: {
    ghg: number | null;
    landUse: number | null;
    waterUse: number | null;
  },
  impactCategory: ImpactCategory
) {
  if (impactCategory === ImpactCategory.GHG) {
    return impact.ghg;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    return impact.landUse;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    return impact.waterUse;
  } else {
    assertNever(impactCategory, "Unsupported impact category");
  }
}

export function impactMagnitudeAndProportionChange(
  impact: {
    ghg: ValueWithProportionalChange<number | null>;
    landUse: ValueWithProportionalChange<number | null>;
    waterUse: ValueWithProportionalChange<number | null>;
  },
  impactCategory: ImpactCategory
) {
  if (impactCategory === ImpactCategory.GHG) {
    return impact.ghg;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    return impact.landUse;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    return impact.waterUse;
  } else {
    assertNever(impactCategory, "Unsupported impact category");
  }
}

export function impactPerKg(
  impact: {
    ghg: number | null;
    landUse: number | null;
    waterUse: number | null;
  },
  impactCategory: ImpactCategory,
  massKg: number | null
) {
  if (massKg === null) {
    return null;
  }
  const impactMagnitudeValue = impactMagnitude(impact, impactCategory);
  return impactMagnitudeValue === null ? null : impactMagnitudeValue / massKg;
}

export const formatString = (value: string | null) =>
  value !== null && value !== undefined ? value : "-";
export const formatNumber = (value: number | null) =>
  value !== null && value !== undefined ? value.toFixed(2) : "-";
