import { EffectType, ImpactRating } from "../../__generated__/globalTypes";
import { ImpactCategory } from "../../domain/impactCategories";
import { ProductsByImpactRating } from "./ProductsByImpactRating";

const stories = {
  name: "ProductsByImpactRating",
  render: () => <Story />,
};

function Story() {
  const impactRatingProportions = {
    ghg: {
      veryLow: { proportion: 0.2, productCount: 2 },
      low: { proportion: 0.1, productCount: 1 },
      medium: { proportion: 0, productCount: 0 },
      high: { proportion: 0.6, productCount: 6 },
      veryHigh: { proportion: 0.1, productCount: 1 },
    },
    landUse: {
      veryLow: { proportion: 0.5, productCount: 50 },
      low: { proportion: 0.1, productCount: 10 },
      medium: { proportion: 0.1, productCount: 10 },
      high: { proportion: 0.01, productCount: 1 },
      veryHigh: { proportion: 0.19, productCount: 19 },
    },
    waterUse: {
      veryLow: { proportion: 0.2, productCount: 2 },
      low: { proportion: 0.2, productCount: 2 },
      medium: { proportion: 0.2, productCount: 2 },
      high: { proportion: 0.2, productCount: 2 },
      veryHigh: { proportion: 0.2, productCount: 2 },
    },
  };

  const impactRatingInfos = {
    impactRatingInfos: [
      {
        effectType: EffectType.GHG_PER_KG,
        impactRatingInfo: [
          {
            lowerBound: 0.8,
            impactRating: ImpactRating.VLOW,
          },
          {
            lowerBound: 1.2,
            impactRating: ImpactRating.LOW,
          },
          {
            lowerBound: 1.6,
            impactRating: ImpactRating.MEDIUM,
          },
          {
            lowerBound: 2.0,
            impactRating: ImpactRating.HIGH,
          },
          {
            lowerBound: 2.4,
            impactRating: ImpactRating.VHIGH,
          },
        ],
      },
      {
        effectType: EffectType.LAND_USE_PER_KG,
        impactRatingInfo: [
          {
            lowerBound: 0.8,
            impactRating: ImpactRating.VLOW,
          },
          {
            lowerBound: 1.2,
            impactRating: ImpactRating.LOW,
          },
          {
            lowerBound: 1.6,
            impactRating: ImpactRating.MEDIUM,
          },
          {
            lowerBound: 2.0,
            impactRating: ImpactRating.HIGH,
          },
          {
            lowerBound: 2.4,
            impactRating: ImpactRating.VHIGH,
          },
        ],
      },
      {
        effectType: EffectType.WATER_USE_PER_KG,
        impactRatingInfo: [
          {
            lowerBound: 8,
            impactRating: ImpactRating.VLOW,
          },
          {
            lowerBound: 12,
            impactRating: ImpactRating.LOW,
          },
          {
            lowerBound: 16,
            impactRating: ImpactRating.MEDIUM,
          },
          {
            lowerBound: 20,
            impactRating: ImpactRating.HIGH,
          },
          {
            lowerBound: 24,
            impactRating: ImpactRating.VHIGH,
          },
        ],
      },
    ],
  };

  return (
    <ProductsByImpactRating
      impactCategory={ImpactCategory.GHG}
      impactRatingInfos={impactRatingInfos}
      impactRatingProportions={impactRatingProportions}
    />
  );
}

export default stories;
