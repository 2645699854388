import classNames from "classnames";
import gql from "graphql-tag";
import { FormattedMessage, useIntl } from "react-intl";

import {
  EffectTypeAbsoluteValue,
  useEffectTypeForImpactCategoryPerFunctionalUnit,
} from "../../domain/EffectType";
import { FunctionalUnit } from "../../domain/functionalUnits";
import { ImpactCategory } from "../../domain/impactCategories";
import {
  getImpactCategoryRating,
  useImpactRatingToLongName,
} from "../../domain/impactRatings";
import { unitLabel } from "../../domain/units";
import { useTracking } from "../../tracking";
import assertNever from "../../util/assertNever";
import * as comparators from "../../util/comparators";
import { Comparator } from "../../util/comparators";
import sort from "../../util/sort";
import ImpactRatingDisplay from "../impacts/ImpactRatingDisplay";
import { LinkButton } from "../utils/Button";
import { formatNumber } from "../utils/numberFormatting";
import { Table, TableColumn } from "../utils/Table";
import { Chevron, ChevronUp } from "../utils/Vectors";
import * as RecipeIngredientLinking from "./RecipeIngredientLinking";
import {
  RecipeIngredientsTable_Recipe as Recipe,
  RecipeIngredientsTable_RecipeImpact,
  RecipeIngredientsTable_RootRecipeIngredientImpact as IngredientImpact,
} from "./RecipeIngredientsTable.graphql";
import "./RecipeIngredientsTable.css";

interface RecipeIngredientsTableProps {
  functionalUnit: FunctionalUnit;
  impact: RecipeIngredientsTable_RecipeImpact;
  impactCategory: ImpactCategory;
  perPortionEffectType: EffectTypeAbsoluteValue;
  productWeightKgPerPortion: number;
  recipe: Recipe;
  totalIngredientImpactMagnitude: number;
}

interface Row extends IngredientImpact {
  isSubrecipeIngredient: boolean;
}

export default function RecipeIngredientsTable(
  props: RecipeIngredientsTableProps
) {
  const {
    functionalUnit,
    impact,
    impactCategory,
    perPortionEffectType,
    recipe,
    productWeightKgPerPortion,
    totalIngredientImpactMagnitude,
  } = props;

  const intl = useIntl();
  const { trackRecipeIngredientExpanded } = useTracking();
  const impactRatingToLongName = useImpactRatingToLongName;

  const functionalUnitEffectType =
    useEffectTypeForImpactCategoryPerFunctionalUnit(
      impactCategory,
      functionalUnit
    );

  const isExpandable = (ingredientImpact: Row) =>
    ingredientImpact.useRecipe !== null;

  const impactMagnitude = (ingredientImpact: IngredientImpact) => {
    const ingredientImpactPerPortion =
      perPortionEffectType.get(ingredientImpact);
    if (ingredientImpactPerPortion === null) {
      return <ImpactPending />;
    }
    if (functionalUnit === FunctionalUnit.PORTION) {
      return ingredientImpactPerPortion.toFixed(perPortionEffectType.decimals);
    } else if (functionalUnit === FunctionalUnit.KG) {
      return (ingredientImpactPerPortion / productWeightKgPerPortion).toFixed(
        perPortionEffectType.decimals
      );
    } else {
      assertNever(functionalUnit, "Unsupported FunctionalUnit");
    }
  };

  const percentage = (
    ingredientImpact: IngredientImpact,
    renderOptions: {
      isChildRow: boolean;
      isExpanded: boolean;
      toggleExpanded: (() => void) | null;
    }
  ) => {
    return perPortionEffectType.get(ingredientImpact) !== null &&
      !renderOptions.isChildRow
      ? `${(
          (perPortionEffectType.get(ingredientImpact)! /
            totalIngredientImpactMagnitude) *
          100
        ).toFixed(0)}%`
      : null;
  };

  const ingredientsTableColumns: Array<TableColumn<Row>> = [
    {
      key: "name",
      className: classNames("pl-0 medium-font"),
      label: intl.formatMessage({
        id: "components/recipes/RecipeBreakdown:IngredientsTable/columns/name/label",
        defaultMessage: "% Impact of Ingredients",
      }),
      renderCell: (
        ingredientImpact,
        renderOptions: {
          isChildRow: boolean;
          isExpanded: boolean;
          toggleExpanded: (() => void) | null;
        }
      ) => (
        <div>
          <>
            <div className="d-flex flex-row" style={{ gap: "8px" }}>
              <div className="medium-font">
                {percentage(ingredientImpact, renderOptions)}
              </div>
              {renderOptions.toggleExpanded ? (
                <LinkButton
                  className="p-0"
                  onClick={() =>
                    renderOptions.toggleExpanded &&
                    renderOptions.toggleExpanded()
                  }
                >
                  {ingredientImpact.name}
                  {renderOptions.isExpanded ? (
                    <ChevronUp className="ml-1" width={12} />
                  ) : (
                    <Chevron className="ml-1" width={12} />
                  )}
                </LinkButton>
              ) : (
                <div>{ingredientImpact.name}</div>
              )}
            </div>
            {renderOptions.isExpanded && (
              <div
                className="medium-font"
                style={{ marginLeft: "42px", marginTop: "24px" }}
              >
                <FormattedMessage
                  id="components/recipes/RecipeBreakdown:IngredientsTable/columns/quantity/labelOfWhich"
                  defaultMessage="of which"
                />
              </div>
            )}
          </>
        </div>
      ),
    },
    {
      key: "quantity",
      label: intl.formatMessage({
        id: "components/recipes/RecipeBreakdown:IngredientsTable/columns/quantity/label",
        defaultMessage: "Amount",
      }),
      renderCell: (ingredient) => {
        const quantityPerPortion = perPortionEffectType.getQuantity(ingredient);
        if (quantityPerPortion === null) {
          return null;
        } else {
          const scaledQuantity =
            functionalUnit === FunctionalUnit.PORTION
              ? quantityPerPortion
              : quantityPerPortion / productWeightKgPerPortion;
          return (
            formatNumber(scaledQuantity) +
            (ingredient.unit === null ? "" : " " + unitLabel(ingredient.unit))
          );
        }
      },
      align: "right",
    },
    {
      key: "impact",
      label: functionalUnitEffectType.impactCategoryUnit,
      align: "right",
      renderCell: (ingredientImpact) => impactMagnitude(ingredientImpact),
    },
  ];

  const impactRatingColumn: TableColumn<Row> = {
    key: "impactRating",
    label: intl.formatMessage({
      id: "components/recipes/RecipeBreakdown:IngredientsTable/columns/impactRating/label",
      defaultMessage: "Rating",
    }),
    renderCell: (ingredientImpact: Row) => {
      const impactRating = getImpactCategoryRating(
        ingredientImpact,
        impactCategory
      );
      if (impactRating == null) {
        return "-";
      } else {
        return (
          <div className="w-100 d-flex" style={{ justifyContent: "flex-end" }}>
            <ImpactRatingDisplay
              value={impactRating}
              text={impactRatingToLongName(impactRating)}
            />
          </div>
        );
      }
    },
    align: "right",
  };

  ingredientsTableColumns.push(impactRatingColumn);

  const defaultIngredientComparator: Comparator<IngredientImpact> =
    comparators.map(
      (ingredientImpact: IngredientImpact) =>
        perPortionEffectType.get(ingredientImpact) ?? 0,
      comparators.reversed(comparators.number)
    );

  const rootIngredientImpacts = sort(
    impact.ingredients,
    defaultIngredientComparator
  );

  const rows: Array<Row> = rootIngredientImpacts.map(
    (rootIngredientImpact) => ({
      ...rootIngredientImpact,
      isSubrecipeIngredient: false,
    })
  );

  const expandedRows = (row: Row) =>
    row.useRecipe === null
      ? []
      : sort(
          row.useRecipe.ingredients.map((subrecipeIngredientImpact) => ({
            ...subrecipeIngredientImpact,
            isSubrecipeIngredient: true,
            useRecipe: null,
          })),
          defaultIngredientComparator
        );

  function handleExpand(row: Row) {
    trackRecipeIngredientExpanded({
      recipeId: recipe.id,
      recipeName: recipe.name,
      ingredientId: row.ingredientId,
      ingredientName: row.name,
    });
  }

  return (
    <Table<Row>
      className="recipe-ingredient-table"
      columns={ingredientsTableColumns}
      expandedRows={expandedRows}
      fullWidth
      isExpandable={isExpandable}
      onExpand={handleExpand}
      rowKey={(ingredientImpact) => ingredientImpact.ingredientId.toString()}
      rows={rows}
    />
  );
}

function ImpactPending() {
  return (
    <span className="medium-font">
      <FormattedMessage
        id="components/recipes/RecipeBreakdown:IngredientsTable/impactPending"
        defaultMessage="Impact pending"
      />
    </span>
  );
}

const ingredientImpactFragment = gql`
  fragment RecipeIngredientsTable_RecipeIngredientImpact on RecipeIngredientImpact {
    foodClassId
    effects {
      ghgPerKg
      ghgPerRootRecipeServing
      landUsePerKg
      landUsePerRootRecipeServing
      waterUsePerKg
      waterUsePerRootRecipeServing
    }
    impactRatingGhg
    impactRatingLandUse
    impactRatingWaterUse
    ingredientId
    name
    quantity
    quantityPerRootRecipeServing
    unit
  }

  fragment RecipeIngredientsTable_RootRecipeIngredientImpact on RecipeIngredientImpact {
    ...RecipeIngredientsTable_RecipeIngredientImpact
    useRecipe {
      ingredients {
        ...RecipeIngredientsTable_RecipeIngredientImpact
      }
    }
  }
`;

RecipeIngredientsTable.fragments = {
  recipeImpact: gql`
    fragment RecipeIngredientsTable_RecipeImpact on RecipeImpact {
      effects {
        ghgPerKg
        ghgPerRootRecipeServing
        landUsePerKg
        landUsePerRootRecipeServing
        waterUsePerKg
        waterUsePerRootRecipeServing
      }
      ingredients {
        ...RecipeIngredientsTable_RootRecipeIngredientImpact
      }
    }

    ${ingredientImpactFragment}
  `,
  recipe: gql`
    fragment RecipeIngredientsTable_Recipe on Recipe {
      id
      ingredients {
        id
        ...RecipeIngredientLinking_RecipeIngredient
      }
      name
    }

    ${RecipeIngredientLinking.fragments.recipeIngredient}
  `,
};
