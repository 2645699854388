import gql from "graphql-tag";

import useQuery from "../components/graphql/useQuery";
import {
  UseImpactRatingInfos_ImpactRatingInfosQuery,
  UseImpactRatingInfos_ImpactRatingInfosQueryVariables,
} from "./useImpactRatingInfos.graphql";

export default function useImpactRatingInfos() {
  return useQuery<
    UseImpactRatingInfos_ImpactRatingInfosQuery,
    UseImpactRatingInfos_ImpactRatingInfosQueryVariables
  >(impactRatingInfosQuery, {});
}

const impactRatingInfosQuery = gql`
  query UseImpactRatingInfos_ImpactRatingInfosQuery {
    impactRatingInfos(filter: { effectTypes: null }) {
      ...useImpactRatingInfos_ImpactRatingInfos
    }
  }

  fragment useImpactRatingInfos_ImpactRatingInfos on ImpactRatingInfos {
    effectType
    impactRatingInfo {
      lowerBound
      impactRating
      label
    }
    label
  }
`;
