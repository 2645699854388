import { sum } from "lodash";
import React from "react";
import { Bar as BarChart } from "react-chartjs-2";
import ReactDOMServer from "react-dom/server";
import { useIntl } from "react-intl";

import * as comparators from "../../util/comparators";
import { TooltipCarat, TooltipCaratLeft } from "../utils/Vectors";
import {
  darkGreyCol,
  foodstepsTurquoiseCol,
  gridLineGreyCol,
  midGreyCol,
} from "./colors";

const fontSize = 12;
const maxLabelCharacters = 10;
const truncateLongLabelsAfterNumberOfBars = 10;

export interface Bar {
  label: string;
  value: Array<number | null>;
  color?: string;
}

interface BarChart2Props {
  bars: Array<Bar>;
  dependentAxisLabel: string;
  grace?: number | string | null;
  height?: number | string;
  horizontal?: boolean;
  maxBars?: number;
  hideFinalBar?: boolean;
  sorted?: boolean;
  maintainAspectRatio?: boolean;
  customTooltip?: (tooltip: { dataPoints: Array<any> }) => JSX.Element | null;
  truncateLabels?: boolean;
}

export default function BarChart2(props: BarChart2Props) {
  const {
    bars,
    dependentAxisLabel,
    grace = null,
    height,
    horizontal = false,
    maxBars,
    hideFinalBar = false,
    sorted = false,
    maintainAspectRatio = true,
    customTooltip = null,
    truncateLabels = true,
  } = props;

  const intl = useIntl();

  let formattedBars = bars;

  if (sorted) {
    formattedBars.sort(
      comparators.map(
        (bar) => sum(bar.value),
        comparators.reversed(comparators.number)
      )
    );
  }

  if (maxBars !== undefined && formattedBars.length > maxBars) {
    const fullBars = formattedBars.slice(0, maxBars);
    const finalBarValue = hideFinalBar
      ? [null]
      : formattedBars.slice(maxBars).flatMap((bar) => bar.value);
    const finalBarLabel = intl.formatMessage(
      {
        id: "components/frontend/src/components/graphs/BarChart2:nMore",
        defaultMessage: "{n} more",
      },
      { n: formattedBars.length - maxBars }
    );
    formattedBars = [
      ...fullBars,
      { label: finalBarLabel, value: finalBarValue },
    ];
  }

  // This is a string identifier that is used to stack data. It can be any string.
  const stack = "stack";

  const maxStackDepth = Math.max(
    ...formattedBars.map((bar: Bar) => {
      if (typeof bar.value === "number") {
        return 0;
      }
      return bar.value.length;
    })
  );

  let datasets = [];

  // Stacks in chart.js are made up of datasets. Each stack layer is a dataset.
  // So to achieve the stacking effect we want, we add the layers to each bar one by one.
  for (let i = 0; i < maxStackDepth; i++) {
    datasets.push({
      data: formattedBars.map((bar) => {
        if (bar.value.length < i) {
          return null;
        }
        return bar.value[i];
      }),
      stack,
      backgroundColor: formattedBars.map(
        (bar) => bar.color ?? foodstepsTurquoiseCol
      ),
      formattedBars,
    });
  }

  const labels = formattedBars.map((bar) => bar.label);

  const data = {
    labels,
    datasets,
  };

  const indexAxis = horizontal ? ("y" as const) : ("x" as const);

  const dependentAxisOptions = {
    border: { display: false },
    title: {
      color: darkGreyCol,
      display: true,
      text: dependentAxisLabel,
      font: { size: fontSize },
    },
    grid: { drawTicks: false, color: gridLineGreyCol },
    ticks: { color: midGreyCol, font: { size: fontSize } },
    ...(grace !== null && { grace }),
  };

  const formatLabel = (label: string) =>
    truncateLabels &&
    label.length > maxLabelCharacters &&
    bars.length > truncateLongLabelsAfterNumberOfBars
      ? `${label.substring(0, maxLabelCharacters)}...`
      : label;

  const independentAxisOptions = {
    grid: { display: false },
    ticks: {
      font: { size: fontSize },
      callback: (value: number | string, index: number) =>
        formatLabel(labels[index]),
    },
  };

  const scales = {
    x: {
      ...(horizontal ? dependentAxisOptions : independentAxisOptions),
    },
    y: {
      ...(horizontal ? independentAxisOptions : dependentAxisOptions),
    },
  };

  const options = {
    maintainAspectRatio,
    borderRadius: 4,
    borderColor: "white",
    borderWidth: 2,
    barPercentage: 1.0,
    indexAxis,
    maxBarThickness: 24,
    plugins: {
      datalabels: {
        display: false,
      },
      ...(customTooltip !== null
        ? {
            tooltip: {
              enabled: false,
              position: "nearest" as const,
              external: (context: any) =>
                externalTooltipHandler(context, horizontal, customTooltip),
            },
          }
        : {}),
    },
    scales,
    skipNull: true,
  };

  // Chart js can't figure out that the container has padding on it, so we need
  // to wrap it in a div so that it auto-scales to this size, but the div respects the padding
  return (
    <div>
      <BarChart height={height} data={data} options={options} />
    </div>
  );
}

// eslint-disable-next-line object-shorthand
function externalTooltipHandler(
  context: { chart: any; tooltip: any },
  horizontal: boolean,
  customTooltip?: (tooltip: { dataPoints: Array<any> }) => JSX.Element | null
) {
  const { chart, tooltip } = context;
  // Tooltip Element
  const tooltipEl = getOrCreateTooltip(chart, horizontal);

  tooltip.body = [
    {
      lines: [ReactDOMServer.renderToStaticMarkup(customTooltip!(tooltip)!)],
    },
  ];

  // Hide if no tooltip
  const tooltipModel = context.tooltip;
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = "0";
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove("above", "below", "no-transform");
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add("no-transform");
  }

  function getBody(bodyItem: any) {
    return bodyItem.lines;
  }

  // Set Text
  if (tooltipModel.body) {
    const bodyLines = tooltipModel.body.map(getBody);

    let innerHtml = "<thead style='padding-bottom: 0; margin-bottom: 0'>";

    bodyLines.forEach(function (body: any, i: any) {
      const colors = tooltipModel.labelColors[i];
      let style = "background:" + colors.backgroundColor;
      style += "; border-color:" + colors.borderColor;
      style += "; border-width: 8px";
      const span = '<span style="' + style + '">' + body + "</span>";
      innerHtml += "<tr><td>" + span + "</td></tr>";
    });
    innerHtml += "</tbody>";

    let tableRoot = tooltipEl.querySelector("table");
    // @ts-ignore
    tableRoot.innerHTML = innerHtml;
  }

  const position = context.chart.canvas.getBoundingClientRect();
  // const bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);

  // Display, position, and set styles for font
  tooltipEl.style.opacity = "1";
  tooltipEl.style.position = "absolute";
  tooltipEl.style.left =
    position.left + window.scrollX + tooltipModel.caretX + "px";
  tooltipEl.style.top =
    position.top + window.scrollY + tooltipModel.caretY + "px";
  // tooltipEl.style.font = bodyFont.string;
  tooltipEl.style.padding =
    tooltipModel.padding + "px " + tooltipModel.padding + "px";
  tooltipEl.style.pointerEvents = "none";
}

const getOrCreateTooltip = (chart: any, horizontal: boolean) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");
  const getOrCreateTooltipCarat = (chart: any) => {
    let tooltipCaratEl = chart.canvas.parentNode.querySelector("div");

    if (!tooltipCaratEl) {
      tooltipCaratEl = document.createElement("div");
      tooltipCaratEl.style.background = "transparent";
      tooltipCaratEl.style.display = "flex";
      tooltipCaratEl.style.transition = "all .5s ease";

      if (horizontal) {
        tooltipCaratEl.style.justifyContent = "flex-start";
        tooltipCaratEl.style.alignContent = "center";
        tooltipCaratEl.style.transform = "translate(3px, 0)";
        tooltipCaratEl.style.marginLeft = "-12px";
        tooltipCaratEl.innerHTML = ReactDOMServer.renderToStaticMarkup(
          <TooltipCaratLeft width={14} fill="rgba(0, 0, 0, 0.8)" />
        );
      } else {
        tooltipCaratEl.style.justifyContent = "center";
        tooltipCaratEl.style.alignContent = "flex-end";
        tooltipCaratEl.style.transform = "translate(0, 5px)";
        tooltipCaratEl.style.marginTop = "-6px";
        tooltipCaratEl.innerHTML = ReactDOMServer.renderToStaticMarkup(
          <TooltipCarat width={14} fill="rgba(0, 0, 0, 0.8)" />
        );
      }
    }
    return tooltipCaratEl;
  };

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "rgba(0, 0, 0, 0.8)";
    tooltipEl.style.borderRadius = "6px";
    tooltipEl.style.color = "white";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    const table = document.createElement("table");
    table.style.margin = "0px";

    if (horizontal) {
      tooltipEl.style.display = "flex";
      tooltipEl.style.flexDirection = "row";
      tooltipEl.style.transform = "translate(3px, -50%)";
      tooltipEl.appendChild(getOrCreateTooltipCarat(chart));
      tooltipEl.appendChild(table);
    } else {
      tooltipEl.style.transform = "translate(-50%, calc(-100% - 3px))";
      tooltipEl.appendChild(table);
      tooltipEl.appendChild(getOrCreateTooltipCarat(chart));
    }

    chart.canvas.parentNode.appendChild(tooltipEl);
  }
  return tooltipEl;
};
