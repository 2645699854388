import {
  AssessmentSummary,
  ImpactRatingProportion,
  ImpactRatingProportions,
} from "../../data-store";
import { ImpactCategory } from "../../domain/impactCategories";
import { ImpactRating } from "../../domain/impactRatings";
import assertNever from "../../util/assertNever";
import { usePages } from "../pages";
import { ProportionsWithProductCount } from "../scope-3/ImpactRatingProportionsChart";

export function getImpactCategoryImpactRatingProportions(
  assessment: AssessmentSummary,
  impactCategory: ImpactCategory
) {
  if (impactCategory === ImpactCategory.GHG) {
    return assessment.impactRatingProportions.ghg;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    return assessment.impactRatingProportions.landUse;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    return assessment.impactRatingProportions.waterUse;
  } else {
    assertNever(impactCategory, "Unsupported impact category");
  }
}

export function getImpactCategoryProportionsWithProductCount(
  assessment: AssessmentSummary,
  impactCategory: ImpactCategory
): ProportionsWithProductCount | null {
  const impactRatingProportions = getImpactCategoryImpactRatingProportions(
    assessment,
    impactCategory
  );
  function getProportionWithProductCount(
    proportion: ImpactRatingProportion | null
  ) {
    if (proportion === null) {
      return null;
    }
    return proportion.valueWithProportionalChange.value !== null
      ? {
          proportion: proportion.valueWithProportionalChange.value,
          productCount: proportion.itemCount,
        }
      : null;
  }
  return impactRatingProportions
    ? {
        veryLow: getProportionWithProductCount(impactRatingProportions.veryLow),
        low: getProportionWithProductCount(impactRatingProportions.low),
        medium: getProportionWithProductCount(impactRatingProportions.medium),
        high: getProportionWithProductCount(impactRatingProportions.high),
        veryHigh: getProportionWithProductCount(
          impactRatingProportions.veryHigh
        ),
      }
    : null;
}

export function getImpactRatingProportion(
  impactRatingProportions: ImpactRatingProportions,
  impactRating: ImpactRating
) {
  if (impactRating === "VLOW") {
    return impactRatingProportions.veryLow?.valueWithProportionalChange ?? null;
  } else if (impactRating === "LOW") {
    return impactRatingProportions.low?.valueWithProportionalChange ?? null;
  } else if (impactRating === "MEDIUM") {
    return impactRatingProportions.medium?.valueWithProportionalChange ?? null;
  } else if (impactRating === "HIGH") {
    return impactRatingProportions.high?.valueWithProportionalChange ?? null;
  } else if (impactRating === "VHIGH") {
    return (
      impactRatingProportions.veryHigh?.valueWithProportionalChange ?? null
    );
  } else {
    assertNever(impactRating, "Unsupported impact rating");
  }
}

export function getImpactCategoryImpactRatingProportion(
  assessment: AssessmentSummary,
  impactCategory: ImpactCategory,
  impactRating: ImpactRating
) {
  const impactRatingProportions = getImpactCategoryImpactRatingProportions(
    assessment,
    impactCategory
  );
  return impactRatingProportions
    ? getImpactRatingProportion(impactRatingProportions, impactRating)
    : null;
}

export function useImpactCategoryUrl(impactCategory: ImpactCategory) {
  const pages = usePages();
  if (impactCategory === ImpactCategory.GHG) {
    return pages.Scope3.url;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    return pages.LandUse.url;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    return pages.WaterUse.url;
  } else {
    assertNever(impactCategory, "Invalid impact category");
  }
}
