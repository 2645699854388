import { ImpactCategory } from "../../../../domain/impactCategories";
import {
  ImpactRating,
  useImpactRatingToColor,
} from "../../../../domain/impactRatings";
import { graphikSemiboldWebCssString } from "../../../../fonts/graphik-web/graphikSemiboldWebCssString";
import {
  darkTurquoise,
  foodstepsTurquoise,
  foodstepsTurquoiseCol,
  mint,
} from "../../../graphs/colors";
import { impactRatingLetterColor } from "../../impactRatingLetterColor";
import {
  impactRatingToLetter,
  impactRatingToName,
  impactCategoryTitle,
} from "./translations";

const lineHeight = 14;

interface DescriptiveRatingScaleProps {
  greyscale?: boolean;
  impactCategory: ImpactCategory;
  impactRating: ImpactRating;
  locale: string | null;
  width: number;
}

export default function DescriptiveRatingScale(
  props: DescriptiveRatingScaleProps
) {
  const {
    greyscale = false,
    impactCategory,
    impactRating,
    locale,
    width,
  } = props;

  const impactCategoryTitleOver2Lines =
    locale === "fr-FR" &&
    (impactCategory === ImpactCategory.LAND_USE ||
      impactCategory === ImpactCategory.WATER_USE);
  const setHeight = (defaultHeight: number) => {
    return impactCategoryTitleOver2Lines
      ? defaultHeight + lineHeight
      : defaultHeight;
  };

  return (
    <svg
      width={width}
      viewBox={`0 0 98 ${setHeight(154)}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>{graphikSemiboldWebCssString}</style>

      <rect
        x="1"
        y="23"
        width="96"
        height={setHeight(130)}
        rx="9"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M2 32C2 27.5817 5.58172 24 10 24H88C92.4183 24 96 27.5817 96 32V88H2V32Z"
        fill="white"
      />
      <ImpactCategoryIllustration
        greyscale={greyscale}
        impactCategory={impactCategory}
      />

      <text
        x={49}
        y={60}
        textAnchor="middle"
        fontFamily="Graphik Web"
        fontWeight={600}
        fontSize={impactRatingFontSize(impactRating, locale)}
        fill="black"
      >
        {impactRatingToName(impactRating, locale)}
      </text>
      <Title impactCategory={impactCategory} locale={locale} />

      <rect
        width="94"
        height={setHeight(40)}
        transform={`translate(2 ${setHeight(88)})`}
        fill="white"
      />

      <g
        transform={
          impactCategoryTitleOver2Lines ? `translate(0 ${lineHeight})` : ""
        }
      >
        <ImpactRatingScale greyscale={greyscale} impactRating={impactRating} />

        <path
          d="M2 128H96V144C96 148.418 92.4183 152 88 152H10C5.58172 152 2 148.418 2 144V128Z"
          fill={greyscale ? "black" : foodstepsTurquoiseCol}
        />
        <path
          d="M12.9453 143.672H15.0639V140.05H18.1372V138.454H15.0639V135.983H18.948V134.322H12.9453V143.672Z"
          fill="white"
        />
        <path
          d="M23.0543 142.378C21.9819 142.378 21.3803 141.593 21.3803 140.285V140.18C21.3803 138.873 22.0081 138.114 23.0543 138.114C24.1136 138.114 24.7282 138.899 24.7282 140.207V140.298C24.7282 141.593 24.1136 142.378 23.0543 142.378ZM23.0412 143.816C25.0944 143.816 26.6506 142.443 26.6506 140.285V140.18C26.6506 138.049 25.1075 136.676 23.0543 136.676C21.0011 136.676 19.4448 138.075 19.4448 140.22V140.324C19.4448 142.456 21.0011 143.816 23.0412 143.816Z"
          fill="white"
        />
        <path
          d="M31.1129 142.378C30.0405 142.378 29.4389 141.593 29.4389 140.285V140.18C29.4389 138.873 30.0667 138.114 31.1129 138.114C32.1722 138.114 32.7868 138.899 32.7868 140.207V140.298C32.7868 141.593 32.1722 142.378 31.1129 142.378ZM31.0998 143.816C33.153 143.816 34.7092 142.443 34.7092 140.285V140.18C34.7092 138.049 33.1661 136.676 31.1129 136.676C29.0597 136.676 27.5034 138.075 27.5034 140.22V140.324C27.5034 142.456 29.0597 143.816 31.0998 143.816Z"
          fill="white"
        />
        <path
          d="M38.5438 143.816C39.5508 143.816 40.3877 143.228 40.7408 142.534V143.672H42.624V133.733H40.7408V137.853C40.3616 137.16 39.6815 136.676 38.583 136.676C36.8698 136.676 35.5751 137.996 35.5751 140.246V140.35C35.5751 142.626 36.8829 143.816 38.5438 143.816ZM39.1061 142.338C38.1645 142.338 37.5106 141.671 37.5106 140.311V140.207C37.5106 138.847 38.0991 138.127 39.1584 138.127C40.1916 138.127 40.7931 138.82 40.7931 140.18V140.285C40.7931 141.671 40.1 142.338 39.1061 142.338Z"
          fill="white"
        />
        <path
          d="M46.7921 143.816C48.5968 143.816 49.6692 143.044 49.6692 141.567C49.6692 140.102 48.7276 139.67 47.0144 139.422C46.0205 139.278 45.6936 139.108 45.6936 138.677C45.6936 138.258 46.0467 137.97 46.6613 137.97C47.3152 137.97 47.6291 138.232 47.7337 138.794H49.473C49.303 137.225 48.1653 136.676 46.6483 136.676C45.2489 136.676 43.9412 137.382 43.9412 138.807C43.9412 140.141 44.6474 140.664 46.4652 140.939C47.446 141.083 47.8514 141.279 47.8514 141.75C47.8514 142.221 47.5114 142.495 46.779 142.495C45.9551 142.495 45.6544 142.142 45.5759 141.527H43.8104C43.8758 143.005 44.9482 143.816 46.7921 143.816Z"
          fill="white"
        />
        <path
          d="M53.3696 143.803C53.8796 143.803 54.2589 143.711 54.5204 143.62V142.155C54.2981 142.247 54.0889 142.286 53.8011 142.286C53.3173 142.286 53.0426 142.024 53.0426 141.475V138.18H54.4812V136.833H53.0426V135.355H51.1595V136.833H50.2832V138.18H51.1595V141.645C51.1595 143.071 51.931 143.803 53.3696 143.803Z"
          fill="white"
        />
        <path
          d="M58.9531 143.816C60.8232 143.816 62.0002 142.992 62.2226 141.514H60.4309C60.3132 142.09 59.8817 142.469 59.0055 142.469C57.9723 142.469 57.3577 141.815 57.3054 140.677H62.2356V140.154C62.2356 137.709 60.6663 136.676 58.9008 136.676C56.913 136.676 55.3829 138.075 55.3829 140.233V140.337C55.3829 142.521 56.8869 143.816 58.9531 143.816ZM57.3315 139.513C57.4754 138.546 58.0508 137.983 58.9008 137.983C59.8032 137.983 60.3263 138.48 60.3917 139.513H57.3315Z"
          fill="white"
        />
        <path
          d="M63.4769 146H65.3732V142.626C65.7263 143.293 66.4978 143.816 67.5571 143.816C69.2572 143.816 70.5389 142.548 70.5389 140.285V140.18C70.5389 137.918 69.2311 136.676 67.5571 136.676C66.5502 136.676 65.7524 137.251 65.3732 137.905V136.833H63.4769V146ZM66.9948 142.338C65.9617 142.338 65.3078 141.671 65.3078 140.298V140.194C65.3078 138.82 65.9878 138.127 66.9817 138.127C67.9364 138.127 68.6164 138.807 68.6164 140.194V140.298C68.6164 141.632 68.041 142.338 66.9948 142.338Z"
          fill="white"
        />
        <path
          d="M74.2885 143.816C76.0932 143.816 77.1656 143.044 77.1656 141.567C77.1656 140.102 76.224 139.67 74.5108 139.422C73.5169 139.278 73.1899 139.108 73.1899 138.677C73.1899 138.258 73.543 137.97 74.1577 137.97C74.8116 137.97 75.1254 138.232 75.2301 138.794H76.9694C76.7994 137.225 75.6616 136.676 74.1446 136.676C72.7453 136.676 71.4375 137.382 71.4375 138.807C71.4375 140.141 72.1437 140.664 73.9615 140.939C74.9424 141.083 75.3478 141.279 75.3478 141.75C75.3478 142.221 75.0077 142.495 74.2754 142.495C73.4515 142.495 73.1507 142.142 73.0722 141.527H71.3068C71.3721 143.005 72.4445 143.816 74.2885 143.816Z"
          fill="white"
        />
        <path
          d="M81.6594 136.848L81.6591 136.611C81.6592 136.475 81.7241 136.381 81.8534 136.33C82.3715 136.125 82.6021 135.528 82.3508 135.044C82.3036 134.953 82.2427 134.871 82.1695 134.799C81.9584 134.59 81.7455 134.383 81.5343 134.174C81.5114 134.152 81.4974 134.149 81.4726 134.172C81.3439 134.291 81.2137 134.408 81.0837 134.526C80.9907 134.61 80.8958 134.692 80.804 134.777C80.6159 134.952 80.5118 135.165 80.5002 135.419C80.4876 135.697 80.5844 135.936 80.7845 136.133C80.8726 136.22 80.9768 136.285 81.0939 136.33C81.2219 136.379 81.2922 136.48 81.2923 136.613C81.2924 136.778 81.2925 136.943 81.2922 137.108C81.2922 137.13 81.2975 137.156 81.2679 137.165C81.2386 137.174 81.2279 137.15 81.2139 137.132C81.0416 136.915 80.8181 136.789 80.5353 136.767C80.4535 136.76 80.4537 136.762 80.4615 136.84L80.4618 136.843C80.4793 137.018 80.5388 137.177 80.6458 137.319C80.7651 137.476 80.9221 137.584 81.1118 137.646C81.2294 137.685 81.2919 137.769 81.2923 137.891C81.2926 137.979 81.2923 138.068 81.2923 138.157C81.2923 138.415 81.1188 138.571 80.8599 138.518C80.9766 138.625 81.061 138.693 81.1715 138.72C81.4565 138.79 81.6728 138.879 82.0895 138.523C81.874 138.564 81.6815 138.371 81.6803 138.157C81.6799 138.054 81.6803 137.985 81.6803 137.882C81.6803 137.767 81.7423 137.683 81.8578 137.646C82.0279 137.591 82.1505 137.498 82.2657 137.365C82.3966 137.213 82.472 137.038 82.4862 136.839C82.4918 136.762 82.4915 136.761 82.4129 136.767C82.2011 136.782 82.0174 136.861 81.8597 136.999C81.8102 137.043 81.767 137.092 81.7272 137.143C81.715 137.159 81.7025 137.171 81.6803 137.164C81.6593 137.157 81.6593 137.14 81.6594 137.123V136.848Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M81.4875 138.58C83.221 138.58 84.6262 137.201 84.6262 135.5C84.6262 133.799 83.221 132.42 81.4875 132.42C79.7541 132.42 78.3489 133.799 78.3489 135.5C78.3489 137.201 79.7541 138.58 81.4875 138.58ZM81.4875 139C83.4573 139 85.0542 137.433 85.0542 135.5C85.0542 133.567 83.4573 132 81.4875 132C79.5177 132 77.9209 133.567 77.9209 135.5C77.9209 137.433 79.5177 139 81.4875 139Z"
          fill="white"
        />
      </g>
    </svg>
  );
}

interface ImpactRatingScaleProps {
  greyscale: boolean;
  impactRating: ImpactRating;
}

function ImpactRatingScale(props: ImpactRatingScaleProps) {
  const { greyscale, impactRating } = props;

  const impactRatingToColor = useImpactRatingToColor(greyscale);
  const ratingLetter = impactRatingToLetter(impactRating);
  const ratingLetterColor = impactRatingLetterColor(impactRating, greyscale);

  const ratingLetterY = 111;
  const ratingLetterXA = 25;
  const ratingLetterFontSize = 20;
  const distanceBetweenRatingLetters = 12;

  switch (impactRating) {
    case "VLOW":
      return (
        <>
          <rect
            x="12"
            y="89"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor("VLOW")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <path d="M42 94H51V114H42V94Z" fill={impactRatingToColor("LOW")} />
          <rect
            x="54"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("MEDIUM")}
          />
          <rect
            x="66"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("HIGH")}
          />
          <path
            d="M78 94H83C85.2091 94 87 95.7909 87 98V110C87 112.209 85.2091 114 83 114H78V94Z"
            fill={impactRatingToColor("VHIGH")}
          />
        </>
      );
    case "LOW":
      return (
        <>
          <path
            d="M11 98C11 95.7909 12.7909 94 15 94H20V114H15C12.7909 114 11 112.209 11 110V98Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="24"
            y="89"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor("LOW")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <rect
            x="54"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("MEDIUM")}
          />
          <rect
            x="66"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("HIGH")}
          />
          <path
            d="M78 94H83C85.2091 94 87 95.7909 87 98V110C87 112.209 85.2091 114 83 114H78V94Z"
            fill={impactRatingToColor("VHIGH")}
          />
        </>
      );
    case "MEDIUM":
      return (
        <>
          <path
            d="M11 98C11 95.7909 12.7909 94 15 94H20V114H15C12.7909 114 11 112.209 11 110V98Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="23"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("LOW")}
          />
          <rect
            x="36"
            y="89"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor("MEDIUM")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 2 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <rect
            x="66"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("HIGH")}
          />
          <path
            d="M78 94H83C85.2091 94 87 95.7909 87 98V110C87 112.209 85.2091 114 83 114H78V94Z"
            fill={impactRatingToColor("VHIGH")}
          />
        </>
      );
    case "HIGH":
      return (
        <>
          <path
            d="M11 98C11 95.7909 12.7909 94 15 94H20V114H15C12.7909 114 11 112.209 11 110V98Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="23"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("LOW")}
          />
          <rect
            x="35"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("MEDIUM")}
          />
          <rect
            x="48"
            y="89"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor("HIGH")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 3 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <path
            d="M78 94H83C85.2091 94 87 95.7909 87 98V110C87 112.209 85.2091 114 83 114H78V94Z"
            fill={impactRatingToColor("VHIGH")}
          />
        </>
      );
    case "VHIGH":
      return (
        <>
          <path
            d="M11 98C11 95.7909 12.7909 94 15 94H20V114H15C12.7909 114 11 112.209 11 110V98Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="23"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("LOW")}
          />
          <rect
            x="35"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("MEDIUM")}
          />
          <path d="M47 94H56V114H47V94Z" fill={impactRatingToColor("HIGH")} />
          <rect
            x="60"
            y="89"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor("VHIGH")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 4 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
        </>
      );
  }
}

interface ImpactCategoryIllustrationProps {
  greyscale: boolean;
  impactCategory: ImpactCategory;
}

function ImpactCategoryIllustration(props: ImpactCategoryIllustrationProps) {
  const { greyscale, impactCategory } = props;

  switch (impactCategory) {
    case ImpactCategory.GHG:
      return (
        <>
          <path
            d="M50.9502 40.99C61.9959 40.99 70.9502 32.0357 70.9502 20.99C70.9502 9.94429 61.9959 0.98999 50.9502 0.98999C39.9045 0.98999 30.9502 9.94429 30.9502 20.99C30.9502 32.0357 39.9045 40.99 50.9502 40.99Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M39.6499 21.3199C38.4399 18.7799 40.0899 16.7899 40.0899 16.7899C36.3699 16.7899 36.6199 13.1699 36.6199 13.1699C33.7999 15.1299 31.7199 12.7899 31.7199 12.7899C26.5899 13.2299 27.7999 8.61992 27.7999 8.61992C26.6499 3.48992 31.7799 3.54992 31.7799 3.54992C36.9099 -0.830084 40.3699 4.23992 40.3699 4.23992C47.1099 4.06992 45.4399 10.9799 45.4399 10.9799C48.4899 13.2499 45.5599 15.8899 45.5599 15.8899C45.5599 15.8899 48.1899 17.3599 45.9999 21.3099"
            fill={mint(greyscale)}
          />
          <path
            d="M39.6499 21.3199C38.4399 18.7799 40.0899 16.7899 40.0899 16.7899C36.3699 16.7899 36.6199 13.1699 36.6199 13.1699C33.7999 15.1299 31.7199 12.7899 31.7199 12.7899C26.5899 13.2299 27.7999 8.61992 27.7999 8.61992C26.6499 3.48992 31.7799 3.54992 31.7799 3.54992C36.9099 -0.830084 40.3699 4.23992 40.3699 4.23992C47.1099 4.06992 45.4399 10.9799 45.4399 10.9799C48.4899 13.2499 45.5599 15.8899 45.5599 15.8899C45.5599 15.8899 48.1899 17.3599 45.9999 21.3099"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M48.5598 22.2299C48.4798 21.6199 47.9598 21.1699 47.3398 21.1699H38.9298C38.3198 21.1699 37.7998 21.6199 37.7098 22.2299L36.0698 34.3199C39.7298 38.3999 45.0298 40.9799 50.9398 40.9799C50.9898 40.9799 51.0398 40.9799 51.0898 40.9799L48.5498 22.2299H48.5598Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M70.9502 20.9901C70.9502 15.5801 68.7902 10.6801 65.3002 7.08008C64.5002 7.46008 63.8402 8.09008 63.4302 8.88008C63.4102 8.88008 63.3902 8.88008 63.3702 8.88008C60.7202 8.88008 58.5102 10.8001 58.0602 13.3201C57.9002 13.2901 57.7302 13.2701 57.5502 13.2701C56.2202 13.2701 55.1402 14.3301 55.1002 15.6501C53.8702 16.0201 52.9702 17.1701 52.9702 18.5201C52.9702 20.1701 54.3202 21.5201 55.9702 21.5201H70.9102C70.9102 21.3501 70.9402 21.1701 70.9402 21.0001L70.9502 20.9901Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinejoin="round"
          />
        </>
      );
    case ImpactCategory.LAND_USE:
      return (
        <>
          <path
            d="M50.9502 40.99C61.9959 40.99 70.9502 32.0357 70.9502 20.99C70.9502 9.94429 61.9959 0.98999 50.9502 0.98999C39.9045 0.98999 30.9502 9.94429 30.9502 20.99C30.9502 32.0357 39.9045 40.99 50.9502 40.99Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M55.3101 31.6599H34.0601C37.6001 37.2599 43.8401 40.9899 50.9501 40.9899C54.7201 40.9899 58.2301 39.9299 61.2301 38.1199L55.3101 31.6599Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M54.48 40.6601C63.84 38.9901 70.96 30.8301 70.96 20.9901C70.96 11.1501 63.84 2.99007 54.48 1.32007V40.6601Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M54.48 1.32007V20.7701H70.95C70.84 11.0301 63.78 2.98007 54.48 1.32007Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M36.0002 25.7001V31.6701H41.1102V25.7001C41.1102 25.0601 41.5502 24.5401 42.1002 24.5401H47.2202C48.6302 24.5401 49.3102 22.8101 48.2702 21.8501L43.7102 17.6401H45.4702C46.8802 17.6401 47.5602 15.9101 46.5202 14.9501L42.4402 11.1801H43.5602C44.9702 11.1801 45.6502 9.45012 44.6102 8.49012L39.9702 4.41012C39.1602 3.69012 37.9402 3.69012 37.1202 4.41012L32.4802 8.49012C31.4402 9.45012 32.1202 11.1801 33.5302 11.1801H34.6502L30.5702 14.9501C29.5302 15.9101 30.2102 17.6401 31.6202 17.6401H33.3802L28.8202 21.8501C27.7802 22.8101 28.4602 24.5401 29.8702 24.5401H34.9902C35.5402 24.5401 35.9802 25.0601 35.9802 25.7001H36.0002Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
    case ImpactCategory.WATER_USE:
      return (
        <>
          <path
            d="M50.9502 40.99C61.9959 40.99 70.9502 32.0357 70.9502 20.99C70.9502 9.94429 61.9959 0.98999 50.9502 0.98999C39.9045 0.98999 30.9502 9.94429 30.9502 20.99C30.9502 32.0357 39.9045 40.99 50.9502 40.99Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M50.9502 40.99C58.1902 40.99 64.5102 37.13 68.0202 31.37C62.1902 37.16 57.2402 28 57.2402 28C49.7502 39.47 43.9702 27.29 43.9702 27.29C40.7202 31.44 37.6502 32.91 34.9502 32.96C38.6002 37.82 44.3902 40.99 50.9402 40.99H50.9502Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M52.3198 19.5301C52.3198 16.2901 56.0498 9.72006 56.0498 9.72006C56.4498 8.84006 57.8298 8.84006 58.2298 9.72006C58.2298 9.72006 61.9598 16.1801 61.9598 19.5301C61.9598 22.1901 59.7998 24.6801 57.1398 24.6801C54.4798 24.6801 52.3198 22.1901 52.3198 19.5301Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27.6802 24.3099C27.6802 17.4299 35.6002 3.45994 35.6002 3.45994C36.4502 1.59994 39.3802 1.59994 40.2302 3.45994C40.2302 3.45994 48.1502 17.1899 48.1502 24.3099C48.1502 29.9599 43.5702 35.2499 37.9102 35.2499C32.2502 35.2499 27.6802 29.9599 27.6802 24.3099Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
  }
}

function impactCategoryTitleFontSize(
  impactCategory: ImpactCategory,
  locale: string | null
): number {
  if (locale === "de-DE" && impactCategory === ImpactCategory.WATER_USE) {
    return 10;
  } else if (
    locale === "nl-NL" &&
    (impactCategory === ImpactCategory.LAND_USE ||
      impactCategory === ImpactCategory.WATER_USE)
  ) {
    return 12;
  }
  return 13;
}

function impactRatingFontSize(
  impactRating: ImpactRating,
  locale: string | null
): number {
  if (locale === "de-DE" && impactRating === "VLOW") {
    return 14;
  }
  return 16;
}

interface TitleProps {
  impactCategory: ImpactCategory;
  locale: string | null;
}

function Title(props: TitleProps) {
  const { impactCategory, locale } = props;

  const titleString = impactCategoryTitle(impactCategory, locale);

  const y = 78;

  const commonProps = {
    y,
    x: 49,
    textAnchor: "middle",
    fontFamily: "Graphik Web",
    fontWeight: 500,
    fontSize: impactCategoryTitleFontSize(impactCategory, locale),
    fill: "black",
  };

  if (
    locale === "fr-FR" &&
    (impactCategory === ImpactCategory.LAND_USE ||
      impactCategory === ImpactCategory.WATER_USE)
  ) {
    const words = titleString.split(" ");
    const firstWord = words.shift();
    return (
      <>
        <text {...commonProps}>{firstWord}</text>
        <text {...commonProps} y={y + lineHeight}>
          {words.join(" ")}
        </text>
      </>
    );
  } else {
    return <text {...commonProps}>{titleString}</text>;
  }
}
