import React from "react";
import { FormattedMessage } from "react-intl";

import * as GraphQLSchemaTypes from "../../__generated__/globalTypes";
import { EffectType } from "../../__generated__/globalTypes";
import useUserInfo from "../../data-store/useUserInfo";
import {
  EffectTypeAbsoluteValue,
  impactCategoryToEffectTypePerKg,
} from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import {
  allImpactRatings,
  ImpactRating,
  impactRatingToColorRgb,
  useImpactRatingToLongName,
} from "../../domain/impactRatings";
import assertNever from "../../util/assertNever";
import ImpactRatingDisplay from "../impacts/ImpactRatingDisplay";
import Label from "../labels/Label";
import { impactRatingLetterColor } from "../utils/impactRatingLetterColor";
import "./ImpactRatingProportionsChart.css";
import TooltipOverlay from "../utils/TooltipOverlay";
import { impactRatingToLetter } from "../utils/Vectors/labels/translations";

export interface ProportionWithProductCount {
  proportion: number;
  productCount: number;
}

export interface ProportionsWithProductCount {
  veryLow: ProportionWithProductCount | null;
  low: ProportionWithProductCount | null;
  medium: ProportionWithProductCount | null;
  high: ProportionWithProductCount | null;
  veryHigh: ProportionWithProductCount | null;
}

export interface ProportionsChartImpactRatingInfos {
  impactRatingInfos: Array<{
    effectType: GraphQLSchemaTypes.EffectType;
    impactRatingInfo: Array<{
      lowerBound: number | null;
      impactRating: GraphQLSchemaTypes.ImpactRating;
    }>;
  }>;
}

interface ImpactRatingProportionsChartProps {
  dashboardType: "procurement" | "product";
  impactCategory: ImpactCategory;
  impactRatingInfos: ProportionsChartImpactRatingInfos;
  proportions: ProportionsWithProductCount;
}

export default function ImpactRatingProportionsChart(
  props: ImpactRatingProportionsChartProps
) {
  const { dashboardType, impactCategory, impactRatingInfos, proportions } =
    props;

  return (
    <div className="ImpactRatingProportionsChart">
      <Legend />
      <RatingSections
        dashboardType={dashboardType}
        impactCategory={impactCategory}
        impactRatingInfos={impactRatingInfos}
        proportions={proportions}
      />
    </div>
  );
}

function Legend() {
  return (
    <div className="ImpactRatingProportionsChart_Legend">
      {[0, 25, 50, 75, 100].map((value, index) => (
        <div
          key={index}
          className="ImpactRatingProportionsChart_LabelledDivider"
          style={{ left: `${value}%` }}
        >
          <div className="ImpactRatingProportionsChart_DividerSection">
            <div className="ImpactRatingProportionsChart_Divider" />
            <div className="ImpactRatingProportionsChart_DividerBlock" />
          </div>
          <div className="small-copy">{value}%</div>
        </div>
      ))}
    </div>
  );
}

function RatingSections(props: ImpactRatingProportionsChartProps) {
  const { dashboardType, impactCategory, impactRatingInfos, proportions } =
    props;
  const [chartHover, setChartHover] = React.useState<boolean>(false);
  const [ratingSectionHover, setRatingSectionHover] = React.useState<
    string | null
  >(null);

  return (
    <div
      className="ImpactRatingProportionsChart_RatingSections"
      onMouseOver={() => setChartHover(true)}
      onMouseLeave={() => setChartHover(false)}
    >
      {allImpactRatings.map((impactRating, index) => {
        const proportion = getProportion(proportions, impactRating);
        const impactRatingLowerBound = getImpactRatingLowerBound(
          impactRatingInfos,
          impactCategory,
          impactRating
        );
        const impactRatingUpperBound = getImpactRatingUpperBound(
          impactRatingInfos,
          impactCategory,
          impactRating
        );
        return proportion !== null && proportion !== 0 ? (
          <RatingSection
            chartHover={chartHover}
            dashboardType={dashboardType}
            key={index}
            impactCategory={impactCategory}
            impactRating={impactRating}
            impactRatingLowerBound={impactRatingLowerBound}
            impactRatingUpperBound={impactRatingUpperBound}
            productCount={getProductCount(proportions, impactRating)}
            proportion={proportion}
            ratingSectionHover={ratingSectionHover}
            setRatingSectionHover={setRatingSectionHover}
          />
        ) : null;
      })}
    </div>
  );
}

export function getProportion(
  proportions: ProportionsWithProductCount,
  impactRating: ImpactRating
) {
  if (impactRating === "VLOW") {
    return proportions.veryLow?.proportion ?? null;
  } else if (impactRating === "LOW") {
    return proportions.low?.proportion ?? null;
  } else if (impactRating === "MEDIUM") {
    return proportions.medium?.proportion ?? null;
  } else if (impactRating === "HIGH") {
    return proportions.high?.proportion ?? null;
  } else if (impactRating === "VHIGH") {
    return proportions.veryHigh?.proportion ?? null;
  } else {
    assertNever(impactRating, "Unsupported impact rating");
  }
}

export function getProductCount(
  proportions: ProportionsWithProductCount,
  impactRating: ImpactRating
) {
  if (impactRating === "VLOW") {
    return proportions.veryLow?.productCount ?? null;
  } else if (impactRating === "LOW") {
    return proportions.low?.productCount ?? null;
  } else if (impactRating === "MEDIUM") {
    return proportions.medium?.productCount ?? null;
  } else if (impactRating === "HIGH") {
    return proportions.high?.productCount ?? null;
  } else if (impactRating === "VHIGH") {
    return proportions.veryHigh?.productCount ?? null;
  } else {
    assertNever(impactRating, "Unsupported impact rating");
  }
}

export function getImpactRatingLowerBound(
  impactRatingInfos: ProportionsChartImpactRatingInfos,
  impactCategory: ImpactCategory,
  impactRating: ImpactRating
) {
  let effectType: EffectType;
  if (impactCategory === ImpactCategory.GHG) {
    effectType = EffectType.GHG_PER_KG;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    effectType = EffectType.LAND_USE_PER_KG;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    effectType = EffectType.WATER_USE_PER_KG;
  } else {
    assertNever(impactCategory, "Unsupported impact category");
  }
  const impactRatingInfo = impactRatingInfos.impactRatingInfos.find(
    (info) => info.effectType === effectType
  )!;

  const info = impactRatingInfo.impactRatingInfo.find(
    (info) => info.impactRating === impactRating
  )!;

  return info.lowerBound ?? null;
}

export function getImpactRatingUpperBound(
  impactRatingInfos: ProportionsChartImpactRatingInfos,
  impactCategory: ImpactCategory,
  impactRating: ImpactRating
) {
  let effectType: EffectType;
  if (impactCategory === ImpactCategory.GHG) {
    effectType = EffectType.GHG_PER_KG;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    effectType = EffectType.LAND_USE_PER_KG;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    effectType = EffectType.WATER_USE_PER_KG;
  } else {
    assertNever(impactCategory, "Unsupported impact category");
  }
  const impactRatingInfo = impactRatingInfos.impactRatingInfos.find(
    (info) => info.effectType === effectType
  )!;

  let aboveImpactRating: ImpactRating;
  switch (impactRating) {
    case "VLOW":
      aboveImpactRating = "LOW";
      break;
    case "LOW":
      aboveImpactRating = "MEDIUM";
      break;
    case "MEDIUM":
      aboveImpactRating = "HIGH";
      break;
    case "HIGH":
      aboveImpactRating = "VHIGH";
      break;
    case "VHIGH":
      return null;
    default:
      assertNever(impactRating, "Unsupported impact rating");
  }

  const info = impactRatingInfo.impactRatingInfo.find(
    (info) => info.impactRating === aboveImpactRating
  )!;

  return info.lowerBound ?? null;
}

interface RatingSectionProps {
  chartHover: boolean;
  dashboardType: "procurement" | "product";
  impactCategory: ImpactCategory;
  impactRating: ImpactRating;
  impactRatingLowerBound: number | null;
  impactRatingUpperBound: number | null;
  productCount: number | null;
  proportion: number;
  ratingSectionHover: string | null;
  setRatingSectionHover: (hover: string | null) => void;
}

function RatingSection(props: RatingSectionProps) {
  const {
    chartHover,
    dashboardType,
    impactCategory,
    impactRating,
    impactRatingLowerBound,
    impactRatingUpperBound,
    productCount,
    proportion,
    ratingSectionHover,
    setRatingSectionHover,
  } = props;

  const backgroundColor = impactRatingToColorRgb(impactRating);
  const color = impactRatingLetterColor(impactRating);
  const percentage = proportion * 100;
  const text = `${percentage.toFixed(1)}%`;
  const textDisplayThresholdPercentage = 7;

  return (
    <TooltipOverlay
      id={`components/scope-3/ImpactRatingProportionsChart:RatingSection/${impactRating}`}
      overlay={
        <RatingSectionTooltip
          dashboardType={dashboardType}
          impactCategory={impactCategory}
          impactRating={impactRating}
          impactRatingLowerBound={impactRatingLowerBound}
          impactRatingUpperBound={impactRatingUpperBound}
          percentageString={text}
          productCount={productCount ?? 0}
        />
      }
      placement="top"
      spanStyle={{ width: `${percentage}%` }}
      style={{ fontFamily: "inherit" }}
    >
      <div
        className="ImpactRatingProportionsChart_RatingSection small-copy-medium"
        style={{
          backgroundColor,
          color,
          opacity:
            chartHover &&
            ratingSectionHover !== getRatingSectionKey(impactRating)
              ? 0.3
              : 1,
          transition: "opacity 0.5s",
          transitionDelay:
            chartHover &&
            ratingSectionHover !== getRatingSectionKey(impactRating) &&
            ratingSectionHover !== null
              ? "0.8s"
              : "0s",
        }}
        onMouseOver={() =>
          setRatingSectionHover(getRatingSectionKey(impactRating))
        }
        onMouseLeave={() => setRatingSectionHover(null)}
      >
        {percentage > textDisplayThresholdPercentage ? text : ""}
      </div>
    </TooltipOverlay>
  );
}

function getRatingSectionKey(impactRating: ImpactRating) {
  return `sectionImpactRating-${impactRating}`;
}

interface ImpactRatingBoundariesProps {
  effectType: EffectTypeAbsoluteValue;
  impactRatingLowerBound: number | null;
  impactRatingUpperBound: number | null;
}

function ImpactRatingBoundaries(props: ImpactRatingBoundariesProps) {
  const { effectType, impactRatingLowerBound, impactRatingUpperBound } = props;

  if (impactRatingLowerBound && impactRatingUpperBound) {
    return (
      <div>
        <span className="small-copy-medium">{`${impactRatingLowerBound.toFixed(
          2
        )} - ${impactRatingUpperBound.toFixed(2)}`}</span>{" "}
        {effectType.unit}
      </div>
    );
  } else if (
    impactRatingLowerBound === null &&
    impactRatingUpperBound !== null
  ) {
    return (
      <div>
        <span className="small-copy-medium">{`< ${impactRatingUpperBound.toFixed(
          2
        )}`}</span>{" "}
        {effectType.unit}
      </div>
    );
  } else if (
    impactRatingUpperBound === null &&
    impactRatingLowerBound !== null
  ) {
    return (
      <div>
        <span className="small-copy-medium">{`> ${impactRatingLowerBound.toFixed(
          2
        )}`}</span>{" "}
        {effectType.unit}
      </div>
    );
  } else {
    return null;
  }
}

interface RatingSectionTooltipProps {
  dashboardType: "procurement" | "product";
  impactCategory: ImpactCategory;
  impactRating: ImpactRating;
  impactRatingLowerBound: number | null;
  impactRatingUpperBound: number | null;
  percentageString: string;
  productCount: number;
}

function RatingSectionTooltip(props: RatingSectionTooltipProps) {
  const {
    dashboardType,
    impactCategory,
    impactRating,
    impactRatingLowerBound,
    impactRatingUpperBound,
    percentageString,
    productCount,
  } = props;

  const effectType = impactCategoryToEffectTypePerKg(impactCategory);
  const impactRatingToLongName = useImpactRatingToLongName;
  const [{ locale }] = useUserInfo();

  return (
    <div className="ImpactRatingProportionsChart_RatingSection_Tooltip">
      <div>
        {dashboardType === "product" ? (
          <Label
            colourSetting="colour"
            impactCategory={impactCategory}
            impactRating={impactRating}
            type="letterRating"
            width={46}
            locale={locale}
          />
        ) : (
          <ImpactRatingDisplay
            text={impactRatingToLongName(impactRating)}
            value={impactRating}
          />
        )}
      </div>
      <div className="ImpactRatingProportionsChart_RatingSection_Tooltip_ImpactRating">
        {impactRatingToLetter(impactRating)} -{" "}
        {impactRatingToLongName(impactRating)}
      </div>
      <div>
        <span>
          <span className="ImpactRatingProportionsChart_RatingSection_Tooltip_Percentage">
            {percentageString}
          </span>{" "}
          {dashboardType === "product" ? (
            <FormattedMessage
              id={`components/scope-3/ImpactRatingProportionsChart:RatingSection/tooltipPercentageProduct`}
              defaultMessage={"of product range"}
            />
          ) : (
            <FormattedMessage
              id={`components/scope-3/ImpactRatingProportionsChart:RatingSection/tooltipPercentageProcurement`}
              defaultMessage={"of procurement"}
            />
          )}
        </span>
        <div>
          <span className="small-copy-medium">{productCount}</span>{" "}
          <SalesUnitString
            dashboardType={dashboardType}
            productCount={productCount}
          />
        </div>
        <ImpactRatingBoundaries
          effectType={effectType}
          impactRatingLowerBound={impactRatingLowerBound}
          impactRatingUpperBound={impactRatingUpperBound}
        />
      </div>
    </div>
  );
}

interface SalesUnitStringProps {
  dashboardType: "procurement" | "product";
  productCount: number;
}

function SalesUnitString(props: SalesUnitStringProps) {
  const { dashboardType, productCount } = props;

  if (dashboardType === "product") {
    return productCount === 1 ? (
      <FormattedMessage
        id={`components/scope-3/ImpactRatingProportionsChart:RatingSection/tooltipProductCountIsOne`}
        defaultMessage="product"
      />
    ) : (
      <FormattedMessage
        id={`components/scope-3/ImpactRatingProportionsChart:RatingSection/tooltipProductCount`}
        defaultMessage="products"
      />
    );
  } else {
    return productCount === 1 ? (
      <FormattedMessage
        id={`components/scope-3/ImpactRatingProportionsChart:RatingSection/tooltipItemCountIsOne`}
        defaultMessage="procured item"
      />
    ) : (
      <FormattedMessage
        id={`components/scope-3/ImpactRatingProportionsChart:RatingSection/tooltipItemCount`}
        defaultMessage="procured items"
      />
    );
  }
}
