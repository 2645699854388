import gql from "graphql-tag";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import {
  useFoodManufacturerOrganization,
  useTags,
  useViewSharedProducts,
} from "../../services/useOrganizationFeatures";
import { useTracking } from "../../tracking";
import assertNever from "../../util/assertNever";
import UserVisibleError from "../../util/UserVisibleError";
import useQuery from "../graphql/useQuery";
import { useOrganizationId } from "../organizations/OrganizationProvider";
import Page from "../Page";
import { usePages } from "../pages";
import StatusDisplay from "../StatusDisplay";
import BackButton from "../utils/BackButton";
import { BreadcrumbPage } from "../utils/Breadcrumb";
import DeleteRecipeModal from "./DeleteRecipeModal";
import RecipeBreakdown from "./RecipeBreakdown";
import {
  RecipePageContent_Recipe as Recipe,
  RecipePageContent_RecipeQuery,
  RecipePageContent_RecipeQueryVariables,
} from "./RecipePageContent.graphql";
import * as RecipePageControls from "./RecipePageControls";
import "./RecipePageContent.css";

interface RecipePageContentProps {
  breadcrumb: Array<BreadcrumbPage>;
  recipe: Recipe | null;
  recipePageControlsState: ReturnType<typeof RecipePageControls.useState>;
  refreshRecipe: () => Promise<void>;
}

export default function RecipePageContent(props: RecipePageContentProps) {
  const { breadcrumb, recipe, recipePageControlsState, refreshRecipe } = props;

  const [organizationId] = useOrganizationId();
  const canViewSharedProducts = useViewSharedProducts();
  const [controlsState] = recipePageControlsState;
  const [includePackaging, setIncludePackaging] = useState(
    (recipe?.packagingComponentsV2.length ?? 0) > 0
  );

  const isSharedRecipe =
    canViewSharedProducts && recipe?.ownerOrganizationId !== organizationId;

  return (
    <>
      {controlsState === "edit" ? null : (
        <RecipePageHeader
          breadcrumb={breadcrumb}
          includePackaging={includePackaging}
          isSharedRecipe={isSharedRecipe}
          recipe={recipe}
          recipePageControlsState={recipePageControlsState}
          setIncludePackaging={setIncludePackaging}
        />
      )}
      {recipe === null ? (
        <p className="m-4">
          <FormattedMessage
            id="components/recipes/RecipePageContent:recipeNotFoundMessage"
            defaultMessage="Recipe not found."
          />
        </p>
      ) : (
        <NonNullRecipePageContent
          breadcrumb={breadcrumb}
          includePackaging={includePackaging}
          recipe={recipe}
          recipePageControlsState={recipePageControlsState}
          refreshRecipe={refreshRecipe}
          setIncludePackaging={setIncludePackaging}
          showExportButton={!isSharedRecipe}
        />
      )}
    </>
  );
}

interface NonNullRecipePageContentProps {
  breadcrumb: Array<BreadcrumbPage>;
  includePackaging: boolean;
  recipe: Recipe;
  recipePageControlsState: ReturnType<typeof RecipePageControls.useState>;
  refreshRecipe: () => Promise<void>;
  setIncludePackaging: (includePackaging: boolean) => void;
  showExportButton: boolean;
}

function NonNullRecipePageContent(props: NonNullRecipePageContentProps) {
  const {
    breadcrumb,
    includePackaging,
    recipe,
    recipePageControlsState,
    refreshRecipe,
    setIncludePackaging,
    showExportButton,
  } = props;

  const [controlsState, setControlsState] = recipePageControlsState;
  const [recalculatingImpact, setRecalculatingImpact] = useState(true);

  const { trackRecipeViewed } = useTracking();
  const history = useHistory();
  const pages = usePages();
  const hasFeatureTags = useTags();
  const foodManufacturerOrganization = useFoodManufacturerOrganization();

  const parentCrumb = breadcrumb[breadcrumb.length - 2]?.url ?? "";

  useEffect(() => {
    setIncludePackaging((recipe.packagingComponentsV2.length ?? 0) > 0);

    trackRecipeViewed({
      pageParent: parentCrumb,
      recipeId: recipe.id,
      recipeName: recipe.name,
    });
  }, [trackRecipeViewed, recipe, setIncludePackaging, parentCrumb]);

  const { refresh: refreshRecipeWithImpact, status: queryStatus } = useQuery<
    RecipePageContent_RecipeQuery,
    RecipePageContent_RecipeQueryVariables
  >(recipeImpactGraphqlQuery(), {
    recipeId: recipe.id,
    fetchStaleImpacts: recalculatingImpact,
  });

  async function refresh() {
    await refreshRecipe();
    await refreshRecipeWithImpact();
  }

  const parentPageUrl =
    (breadcrumb.length >= 2 ? breadcrumb[breadcrumb.length - 2].url : null) ??
    pages.Recipes(hasFeatureTags).url;

  const handleEdit = () => {
    if (foodManufacturerOrganization) {
      history.push({ pathname: pages.RecipeEdit(hasFeatureTags).url(recipe) });
    } else {
      history.push({
        pathname: pages.Recipe(hasFeatureTags).url({
          id: recipe.id,
        }),
        search: "state=edit",
      });
    }
  };

  return (
    <StatusDisplay status={queryStatus}>
      {({ recipe: recipeWithImpact }) => {
        if (recipeWithImpact === null) {
          throw new UserVisibleError("Recipe could not be loaded");
        } else {
          const recipeImpact = includePackaging
            ? recipeWithImpact.impactWithPackaging
            : recipeWithImpact.impactWithoutPackaging;
          return (
            <RecipePageBody
              controlsState={controlsState}
              deleteModal={
                <DeleteRecipeModal
                  onDeleted={async () => history.push(parentPageUrl)}
                  onHide={() => {
                    history.push({
                      pathname: pages.Recipe(hasFeatureTags).url({
                        id: recipe.id,
                      }),
                      search: "state=view",
                    });
                  }}
                  recipe={recipe}
                />
              }
              editContent={
                <RecipePageControls.EditRecipeContent
                  onStateChange={setControlsState}
                  recipe={recipe}
                  refresh={refresh}
                  state={controlsState}
                />
              }
              viewContent={
                <RecipeBreakdown
                  impact={recipeImpact}
                  includePackaging={includePackaging}
                  onEdit={handleEdit}
                  recipe={recipe}
                  setRecalculatingImpacts={setRecalculatingImpact}
                  showExportButton={showExportButton}
                />
              }
            />
          );
        }
      }}
    </StatusDisplay>
  );
}

interface RecipePageBodyProps {
  controlsState: RecipePageControls.State;
  viewContent: JSX.Element;
  editContent: JSX.Element;
  deleteModal: JSX.Element;
}

function RecipePageBody(props: RecipePageBodyProps) {
  const { controlsState, deleteModal, editContent, viewContent } = props;

  if (controlsState === "view") {
    return viewContent;
  } else if (controlsState === "edit") {
    return editContent;
  } else if (controlsState === "delete") {
    return (
      <>
        {viewContent}
        {deleteModal}
      </>
    );
  } else {
    return assertNever(
      controlsState,
      "unexpected controlsState: " + controlsState
    );
  }
}

RecipePageContent.fragments = {
  recipe: gql`
    fragment RecipePageContent_Recipe on Recipe {
      id
      name
      ownerOrganizationId
      ownerOrganizationName
      isHotDrink
      ...DeleteRecipeModal_Recipe
      ...RecipeBreakdown_Recipe
      ...RecipePageControls_Recipe
    }

    ${DeleteRecipeModal.fragments.recipe}
    ${RecipeBreakdown.fragments.recipe}
    ${RecipePageControls.fragments.recipe}
  `,
};

export const recipeImpactGraphqlQuery = () => gql`
  query RecipePageContent_RecipeQuery(
    $recipeId: Int!
    $fetchStaleImpacts: Boolean!
  ) {
    recipe(filter: { ids: [$recipeId] }) {
      ...RecipePageContent_RecipeQuery_Recipe
    }
  }

  fragment RecipePageContent_RecipeQuery_Recipe on Recipe {
    impactWithPackaging: impact(
      excludePackaging: false
      fetchStaleImpacts: $fetchStaleImpacts
    ) {
      ...RecipeBreakdown_RecipeImpact
    }
    impactWithoutPackaging: impact(
      excludePackaging: true
      fetchStaleImpacts: $fetchStaleImpacts
    ) {
      ...RecipeBreakdown_RecipeImpact
    }
  }
  ${RecipeBreakdown.fragments.impact}
`;

interface RecipePageHeaderProps {
  breadcrumb: Array<BreadcrumbPage>;
  includePackaging: boolean;
  isSharedRecipe: boolean;
  recipe: Recipe | null;
  recipePageControlsState: ReturnType<typeof RecipePageControls.useState>;
  setIncludePackaging: (includePackaging: boolean) => void;
}

function RecipePageHeader(props: RecipePageHeaderProps) {
  const {
    breadcrumb,
    includePackaging,
    isSharedRecipe,
    recipe,
    recipePageControlsState,
    setIncludePackaging,
  } = props;

  const history = useHistory();
  const pages = usePages();
  const hasFeatureTags = useTags();

  const [controlsState] = recipePageControlsState;

  const ownerOrganizationLozenge =
    isSharedRecipe && recipe?.ownerOrganizationName ? (
      <div className="recipe-owner-lozenge small ml-3">
        {recipe.ownerOrganizationName}
      </div>
    ) : null;

  return (
    <>
      <div className="RecipePageContent_RecipePageHeader">
        <div className="RecipePageContent_RecipePageHeader_PageTitleContainer">
          <Page.Title
            breadcrumb={breadcrumb}
            inlineComponentEnd={ownerOrganizationLozenge}
            inlineComponentStart={
              <BackButton
                back={() => history.push(pages.Recipes(hasFeatureTags).url)}
              />
            }
            titleTextClassName="RecipePageContent_RecipePageHeader_PageTitle"
          />
        </div>
        {recipe !== null && (
          <div className="RecipePageContent_RecipePageHeader_Controls">
            <RecipePageControls.Buttons
              includePackaging={includePackaging}
              includePackagingChange={setIncludePackaging}
              recipe={recipe}
              state={controlsState}
            />
          </div>
        )}
      </div>
    </>
  );
}
