import { FormattedMessage } from "react-intl";

import {
  AssessmentSummary,
  ValueWithProportionalChange,
} from "../../data-store";
import {
  impactCategoryToEffectType,
  impactCategoryToEffectTypePerLargeUnit,
} from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import {
  getImpactCategoryRating,
  useImpactRatingToLongName,
} from "../../domain/impactRatings";
import assertNever from "../../util/assertNever";
import ImpactRatingDisplay from "../impacts/ImpactRatingDisplay";
import { impactMagnitudeAndProportionChange } from "../scope-3/helperFunctions";
import ReportSummaryCard from "../scope-3/ReportSummaryCard";
import {
  GhgEmissions,
  LandUse,
  Spend,
  WaterUse,
} from "../utils/Vectors/illustrations";
import "./AssessmentOverviewSummary.css";
import { getImpactCategoryImpactRatingProportion } from "./helperFunctions";

interface AssessmentOverviewSummaryProps {
  assessmentSummary: AssessmentSummary;
  impactCategory: ImpactCategory;
}

export function AssessmentOverviewSummary(
  props: AssessmentOverviewSummaryProps
) {
  const { assessmentSummary, impactCategory } = props;
  const effectType = impactCategoryToEffectType(impactCategory);
  const largeUnitEffectType =
    impactCategoryToEffectTypePerLargeUnit(impactCategory);
  const impactRatingToLongName = useImpactRatingToLongName;

  const totalImpactAndProportionalChange = impactMagnitudeAndProportionChange(
    assessmentSummary,
    impactCategory
  );
  const totalImpactInLargeUnits =
    totalImpactAndProportionalChange.value === null
      ? null
      : totalImpactAndProportionalChange.value /
        effectType.largeUnitConversionFactor;
  const averageImpactIntensityAndProportionChange =
    impactIntensityAndProportionChange(assessmentSummary, impactCategory);
  const averageImpactIntensityImpactRating = getImpactCategoryRating(
    assessmentSummary,
    impactCategory
  );
  const proportionVeryLowRatedItemsAndProportionChange =
    getImpactCategoryImpactRatingProportion(
      assessmentSummary,
      impactCategory,
      "VLOW"
    );
  const totalImpactPerSpendAndProportionalChange =
    getTotalImpactPerSpendAndProportionalChange(
      assessmentSummary,
      impactCategory
    );

  return (
    <div className="AssessmentOverviewSummary">
      {totalImpactInLargeUnits !== null && (
        <ReportSummaryCard
          icon={impactCategoryIcon(impactCategory)}
          title={
            <FormattedMessage
              id="components/corporate-reporting-dashboards/AssessmentOverviewSummary:totalImpact"
              defaultMessage="Total Impact"
            />
          }
          value={totalImpactInLargeUnits.toFixed(2)}
          comparisonAssessmentName={assessmentSummary.comparisonAssessmentName}
          proportionChange={totalImpactAndProportionalChange.proportionalChange}
          unit={effectType.largeUnit}
        />
      )}
      {averageImpactIntensityAndProportionChange.value !== null && (
        <ReportSummaryCard
          icon={
            averageImpactIntensityImpactRating !== null ? (
              <ImpactRatingDisplay
                value={averageImpactIntensityImpactRating}
                text={impactRatingToLongName(
                  averageImpactIntensityImpactRating
                )}
              />
            ) : (
              impactCategoryIcon(impactCategory)
            )
          }
          title={
            <FormattedMessage
              id="components/corporate-reporting-dashboards/AssessmentOverviewSummary:averageImpactIntensity"
              defaultMessage="Impact Intensity"
            />
          }
          value={averageImpactIntensityAndProportionChange.value.toFixed(2)}
          comparisonAssessmentName={assessmentSummary.comparisonAssessmentName}
          proportionChange={
            averageImpactIntensityAndProportionChange.proportionalChange
          }
          unit={largeUnitEffectType.unit}
        />
      )}
      {totalImpactPerSpendAndProportionalChange.value !== null &&
        assessmentSummary.spend !== null &&
        assessmentSummary.spendCurrency !== null && (
          <ReportSummaryCard
            icon={<Spend width={32} />}
            title={
              <FormattedMessage
                id="components/corporate-reporting-dashboards/AssessmentOverviewSummary:impactPerSpend"
                defaultMessage="Impact per Spend"
              />
            }
            value={totalImpactPerSpendAndProportionalChange.value.toFixed(2)}
            unit={
              <div>
                {effectType.unit} /{" "}
                {getCurrencySymbol(assessmentSummary.spendCurrency)}
              </div>
            }
            comparisonAssessmentName={
              assessmentSummary.comparisonAssessmentName
            }
            proportionChange={
              totalImpactPerSpendAndProportionalChange.proportionalChange
            }
          />
        )}
      {proportionVeryLowRatedItemsAndProportionChange !== null &&
        proportionVeryLowRatedItemsAndProportionChange.value !== null && (
          <ReportSummaryCard
            icon={
              <ImpactRatingDisplay
                value="VLOW"
                text={impactRatingToLongName("VLOW")}
              />
            }
            title={
              <FormattedMessage
                id="components/corporate-reporting-dashboards/AssessmentOverviewSummary:veryLowRatedItems"
                defaultMessage="Very Low Rated Items"
              />
            }
            value={`${(
              proportionVeryLowRatedItemsAndProportionChange.value * 100
            ).toFixed(0)}%`}
            unit={null}
            comparisonAssessmentName={
              assessmentSummary.comparisonAssessmentName
            }
            proportionChange={
              proportionVeryLowRatedItemsAndProportionChange.proportionalChange
            }
          />
        )}
    </div>
  );
}

export const impactCategoryIcon = (impactCategory: ImpactCategory) => {
  let Icon;
  if (impactCategory === ImpactCategory.GHG) {
    Icon = GhgEmissions;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    Icon = LandUse;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    Icon = WaterUse;
  } else {
    assertNever(impactCategory, "Unsupported ImpactCategory");
  }
  return <Icon width={32} />;
};

function impactIntensityAndProportionChange(
  assessment: {
    ghgIntensity: ValueWithProportionalChange<number | null>;
    landUseIntensity: ValueWithProportionalChange<number | null>;
    waterUseIntensity: ValueWithProportionalChange<number | null>;
  },
  impactCategory: ImpactCategory
) {
  if (impactCategory === ImpactCategory.GHG) {
    return assessment.ghgIntensity;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    return assessment.landUseIntensity;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    return assessment.waterUseIntensity;
  } else {
    assertNever(impactCategory, "Unsupported impact category");
  }
}

function getTotalImpactPerSpendAndProportionalChange(
  assessment: {
    ghgPerSpend: ValueWithProportionalChange<number | null>;
    landUsePerSpend: ValueWithProportionalChange<number | null>;
    waterUsePerSpend: ValueWithProportionalChange<number | null>;
  },
  impactCategory: ImpactCategory
) {
  if (impactCategory === ImpactCategory.GHG) {
    return assessment.ghgPerSpend;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    return assessment.landUsePerSpend;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    return assessment.waterUsePerSpend;
  } else {
    assertNever(impactCategory, "Unsupported impact category");
  }
}

function getCurrencySymbol(currency: string): string {
  if (currency === "GBP") {
    return "£";
  } else if (currency === "USD") {
    return "$";
  } else if (currency === "EUR") {
    return "€";
  }
  throw new Error("Unexpected currency");
}
