import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import useUserInfo from "../../data-store/useUserInfo";
import { impactCategoryToEffectTypePerKg } from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import { ImpactRating } from "../../domain/impactRatings";
import { useIsHeadOffice } from "../../services/useOrganizationFeatures";
import assertNever from "../../util/assertNever";
import { impactCategoryIcon } from "../corporate-reporting-dashboards/AssessmentOverviewSummary";
import Label from "../labels/Label";
import { useOrganization } from "../organizations/OrganizationProvider";
import {
  getProportion,
  ProportionsWithProductCount,
} from "../scope-3/ImpactRatingProportionsChart";
import LargeNumber from "../typography/LargeNumber";
import { House, Products } from "../utils/Vectors/illustrations";
import { useDashboardOrganization } from "./DashboardOrganizationProvider";
import { averageIntensity } from "./helperFunctions";
import "./ProductsDashboardSummary.css";
import { getProportions } from "./ProductsByImpactRating";

interface ProductsDashboardSummaryProps {
  impacts: {
    averageIntensityGhg: number | null;
    averageIntensityImpactRatingGhg: ImpactRating | null;
    averageIntensityLandUse: number | null;
    averageIntensityImpactRatingLandUse: ImpactRating | null;
    averageIntensityWaterUse: number | null;
    averageIntensityImpactRatingWaterUse: ImpactRating | null;
  };
  impactCategory: ImpactCategory;
  impactRatingProportions: {
    ghg: ProportionsWithProductCount;
    landUse: ProportionsWithProductCount;
    waterUse: ProportionsWithProductCount;
  };
  productsCount: number;
  numChildOrganizations: number;
}

export default function ProductsDashboardSummary(
  props: ProductsDashboardSummaryProps
) {
  const {
    impacts,
    impactCategory,
    impactRatingProportions,
    productsCount,
    numChildOrganizations,
  } = props;

  const intl = useIntl();
  const isHeadOffice = useIsHeadOffice();
  const [dashboardOrganization] = useDashboardOrganization();
  const [organization] = useOrganization();
  const [{ locale }] = useUserInfo();

  const effectType = impactCategoryToEffectTypePerKg(impactCategory);
  const proportions = getProportions(impactRatingProportions, impactCategory);
  const proportionVeryLowRatings =
    proportions !== null ? getProportion(proportions, "VLOW") : null;
  const averageImpactIntensity = averageIntensity(impacts, impactCategory);
  const averageImpactRating = averageIntensityImpactRating(
    impacts,
    impactCategory
  );

  const icon =
    averageImpactRating !== null ? (
      <Label
        colourSetting={"colour"}
        impactCategory={impactCategory}
        impactRating={averageImpactRating}
        type="letterRating"
        width={46}
        locale={locale}
      />
    ) : (
      impactCategoryIcon(impactCategory)
    );

  const numOrganizations =
    isHeadOffice && dashboardOrganization.id === organization.id
      ? numChildOrganizations
      : 1 + numChildOrganizations;

  return (
    <div className="ProductsDashboardSummary">
      {averageImpactIntensity !== null && (
        <DashboardSummaryCard
          icon={icon}
          title={
            <FormattedMessage
              id="components/dashboard/ProductsDashboardSummary:averageIntensity"
              defaultMessage="Average {effectType}"
              values={{ effectType: effectType.title(intl) }}
            />
          }
          value={averageImpactIntensity.toFixed(2)}
          unit={effectType.unit}
        />
      )}
      {proportionVeryLowRatings !== null && (
        <DashboardSummaryCard
          icon={
            <Label
              colourSetting={"colour"}
              impactCategory={impactCategory}
              impactRating={"VLOW"}
              type="letterRating"
              width={46}
              locale={locale}
            />
          }
          title={
            <FormattedMessage
              id="components/dashboard/ProductsDashboardSummary:veryLowRatings"
              defaultMessage="Very Low Ratings"
            />
          }
          value={`${(proportionVeryLowRatings * 100).toFixed(0)}%`}
        />
      )}
      {isHeadOffice && (
        <DashboardSummaryCard
          icon={<House width={32} />}
          title={
            <FormattedMessage
              id="components/dashboard/ProductsDashboardSummary:organisations"
              defaultMessage="Organisations"
            />
          }
          value={numOrganizations}
        />
      )}
      <DashboardSummaryCard
        icon={<Products width={32} />}
        title={
          <FormattedMessage
            id="components/dashboard/ProductsDashboardSummary:numberOfProducts"
            defaultMessage="Products"
          />
        }
        value={productsCount}
      />
    </div>
  );
}

interface DashboardSummaryCardProps {
  icon: React.ReactNode;
  title: React.ReactNode;
  value: string | number;
  unit?: React.ReactNode;
}

function DashboardSummaryCard(props: DashboardSummaryCardProps) {
  const { icon, title, value, unit } = props;
  return (
    <div className="DashboardSummaryCard">
      <div className="DashboardSummaryCard__Icon">{icon}</div>
      <div>
        <div className="medium-font">{title}</div>
        <div className="DashboardSummaryCard__ValueAndUnit">
          <LargeNumber>{value}</LargeNumber>
          <small>{unit}</small>
        </div>
      </div>
    </div>
  );
}

function averageIntensityImpactRating(
  impact: {
    averageIntensityImpactRatingGhg: ImpactRating | null;
    averageIntensityImpactRatingLandUse: ImpactRating | null;
    averageIntensityImpactRatingWaterUse: ImpactRating | null;
  },
  impactCategory: ImpactCategory
) {
  if (impactCategory === ImpactCategory.GHG) {
    return impact.averageIntensityImpactRatingGhg;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    return impact.averageIntensityImpactRatingLandUse;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    return impact.averageIntensityImpactRatingWaterUse;
  } else {
    assertNever(impactCategory, "Unexpected impact category");
  }
}
