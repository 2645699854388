import { ImpactCategory } from "../../../../domain/impactCategories";
import { ImpactRating } from "../../../../domain/impactRatings";

// We haven't found a way to download label SVGs while still using intl yet.
// Until we work out how to do that, we will have to translate the strings we need for labels here.
export function impactRatingToLetter(impactRating: ImpactRating) {
  switch (impactRating) {
    case "VLOW":
      return "A";
    case "LOW":
      return "B";
    case "MEDIUM":
      return "C";
    case "HIGH":
      return "D";
    case "VHIGH":
      return "E";
  }
}

export function impactRatingToName(
  impactRating: ImpactRating,
  locale: string | null
) {
  switch (impactRating) {
    case "VLOW":
      return veryLow(locale);
    case "LOW":
      return low(locale);
    case "MEDIUM":
      return medium(locale);
    case "HIGH":
      return high(locale);
    case "VHIGH":
      return veryHigh(locale);
  }
}

function veryLow(locale: string | null) {
  if (locale === "es-419") {
    return "Muy Bajo";
  } else if (locale === "es-ES") {
    return "Más Bajo";
  } else if (locale === "de-DE") {
    return "Sehr Niedrig";
  } else if (locale === "fr-FR") {
    return "Très Faible";
  } else if (locale === "nl-NL") {
    return "Zeer Laag";
  } else if (locale === "zh-CN") {
    return "非常低";
  } else {
    return "Very Low";
  }
}

function low(locale: string | null) {
  if (locale === "es-419" || locale === "es-ES") {
    return "Bajo";
  } else if (locale === "de-DE") {
    return "Niedrig";
  } else if (locale === "fr-FR") {
    return "Faible";
  } else if (locale === "nl-NL") {
    return "Laag";
  } else if (locale === "zh-CN") {
    return "低";
  } else {
    return "Low";
  }
}

function medium(locale: string | null) {
  if (locale === "es-419" || locale === "es-ES") {
    return "Mediano";
  } else if (locale === "de-DE") {
    return "Mittel";
  } else if (locale === "fr-FR") {
    return "Moyen";
  } else if (locale === "nl-NL") {
    return "Midden";
  } else if (locale === "zh-CN") {
    return "中";
  } else {
    return "Medium";
  }
}

function high(locale: string | null) {
  if (locale === "es-419" || locale === "es-ES") {
    return "Alto";
  } else if (locale === "de-DE") {
    return "Hoch";
  } else if (locale === "fr-FR") {
    return "Élevé";
  } else if (locale === "nl-NL") {
    return "Hoog";
  } else if (locale === "zh-CN") {
    return "高";
  } else {
    return "High";
  }
}

function veryHigh(locale: string | null) {
  if (locale === "es-419") {
    return "Muy Alto";
  } else if (locale === "es-ES") {
    return "Más Alto";
  } else if (locale === "de-DE") {
    return "Sehr Hoch";
  } else if (locale === "fr-FR") {
    return "Très Elevé";
  } else if (locale === "nl-NL") {
    return "Zeer Hoog";
  } else if (locale === "zh-CN") {
    return "非常高";
  } else {
    return "Very High";
  }
}

export function impactCategoryTitle(
  impactCategory: ImpactCategory,
  locale: string | null,
  long?: boolean
) {
  switch (impactCategory) {
    case ImpactCategory.GHG:
      return ghgTitle(locale, long);
    case ImpactCategory.LAND_USE:
      return landUseTitle(locale, long);
    case ImpactCategory.WATER_USE:
      return waterUseTitle(locale, long);
  }
}

export function ghgTitle(locale: string | null, long?: boolean) {
  if (locale === "es-419") {
    return "Kohlenstoff";
  } else if (locale === "es-ES") {
    return "Carbono";
  } else if (locale === "de-DE") {
    return "Carbono";
  } else if (locale === "fr-FR") {
    return "Carbone";
  } else if (locale === "nl-NL") {
    return "Koolstof";
  } else if (locale === "zh-CN") {
    return "碳";
  } else {
    return long ? "Carbon rating" : "Carbon";
  }
}

export function landUseTitle(locale: string | null, long?: boolean) {
  if (locale === "es-419") {
    return "Uso de tierra";
  } else if (locale === "es-ES") {
    return "Uso de la tierra";
  } else if (locale === "de-DE") {
    return "Landnutzung";
  } else if (locale === "fr-FR") {
    return "Utilisation des terres";
  } else if (locale === "nl-NL") {
    return "Grondgebruik";
  } else if (locale === "zh-CN") {
    return "土地使用";
  } else {
    return long ? "Land use rating" : "Land use";
  }
}

export function waterUseTitle(locale: string | null, long?: boolean) {
  if (locale === "es-419") {
    return "Uso de agua";
  } else if (locale === "es-ES") {
    return "Uso del agua";
  } else if (locale === "de-DE") {
    return "Wasserverbrauch";
  } else if (locale === "fr-FR") {
    return "Utilisation de l'eau";
  } else if (locale === "nl-NL") {
    return "Watergebruik";
  } else if (locale === "zh-CN") {
    return "水使用";
  } else {
    return long ? "Water use rating" : "Water use";
  }
}

export function impactCategoryUnit(
  impactCategory: ImpactCategory,
  locale: string | null
) {
  switch (impactCategory) {
    case ImpactCategory.GHG:
      return ghgUnit(locale);
    case ImpactCategory.LAND_USE:
      return landUseUnit(locale);
    case ImpactCategory.WATER_USE:
      return waterUseUnit(locale);
  }
}

export function ghgUnit(locale: string | null) {
  return "kg CO₂e";
}

export function landUseUnit(locale: string | null) {
  if (locale === "es-419" || locale === "es-ES") {
    return "m\u00B2año";
  } else if (locale === "de-DE") {
    return "m\u00B2jahr";
  } else if (locale === "fr-FR") {
    return "m\u00B2année";
  } else if (locale === "nl-NL") {
    return "m\u00B2jaar";
  } else if (locale === "zh-CN") {
    return "m\u00B2年";
  } else {
    return "m\u00B2year";
  }
}

export function waterUseUnit(locale: string | null) {
  if (locale === "zh-CN") {
    return "升";
  } else {
    return "L";
  }
}

export function impactCategoryUnitPerKg(
  impactCategory: ImpactCategory,
  locale: string | null
) {
  if (locale === "zh-CN") {
    return `${impactCategoryUnit(impactCategory, locale)} / 公斤`;
  }
  return `${impactCategoryUnit(impactCategory, locale)} / kg`;
}

export function serving(locale: string | null) {
  if (locale === "es-419") {
    return "porcion";
  } else if (locale === "es-ES") {
    return "porción";
  } else if (locale === "de-DE" || locale === "fr-FR") {
    return "portion";
  } else if (locale === "nl-NL") {
    return "portie";
  } else if (locale === "zh-CN") {
    return "份";
  } else {
    return "serving";
  }
}
