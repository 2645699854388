import { ImpactCategory, ImpactRating } from "../../__generated__/globalTypes";
import { AverageImpactIntensityByTag } from "./AverageImpactIntensityByTag";

const stories = {
  name: "AverageImpactIntensityByTag",
  render: () => <Story />,
};

function Story() {
  const tags = [
    {
      name: "Summer Menu",
      count: 12,
      averageIntensityGhg: 10.61,
      impactRatingGhg: ImpactRating.HIGH,
      averageIntensityLandUse: null,
      impactRatingLandUse: null,
      averageIntensityWaterUse: null,
      impactRatingWaterUse: null,
    },
    {
      name: "Winter Menu",
      count: 19,
      averageIntensityGhg: 1.61,
      impactRatingGhg: ImpactRating.LOW,
      averageIntensityLandUse: null,
      impactRatingLandUse: null,
      averageIntensityWaterUse: null,
      impactRatingWaterUse: null,
    },
    {
      name: "Autumn Menu",
      count: 1,
      averageIntensityGhg: 100.61,
      impactRatingGhg: ImpactRating.VHIGH,
      averageIntensityLandUse: null,
      impactRatingLandUse: null,
      averageIntensityWaterUse: null,
      impactRatingWaterUse: null,
    },
    {
      name: "A Chair (too tall to be trusted)",
      count: 145,
      averageIntensityGhg: 0.1234,
      impactRatingGhg: ImpactRating.VLOW,
      averageIntensityLandUse: 2.58,
      impactRatingLandUse: ImpactRating.MEDIUM,
      averageIntensityWaterUse: 34.827359,
      impactRatingWaterUse: ImpactRating.HIGH,
    },
    {
      name: "Spring Menu",
      count: 54,
      averageIntensityGhg: 23.45,
      impactRatingGhg: ImpactRating.VHIGH,
      averageIntensityLandUse: 109,
      impactRatingLandUse: ImpactRating.VHIGH,
      averageIntensityWaterUse: 2,
      impactRatingWaterUse: ImpactRating.LOW,
    },
  ];

  return (
    <AverageImpactIntensityByTag
      tags={tags}
      impactCategory={ImpactCategory.GHG}
    />
  );
}

export default stories;
