import { ImpactCategory } from "../../../../domain/impactCategories";
import {
  ImpactRating,
  useImpactRatingToColor,
} from "../../../../domain/impactRatings";
import { graphikSemiboldWebCssString } from "../../../../fonts/graphik-web/graphikSemiboldWebCssString";
import {
  darkTurquoise,
  foodstepsTurquoise,
  mint,
} from "../../../graphs/colors";
import { impactRatingLetterColor } from "../../impactRatingLetterColor";
import { impactRatingToLetter } from "./translations";

interface LetterRatingProps {
  greyscale?: boolean;
  impactCategory: ImpactCategory;
  impactRating: ImpactRating;
  width: number;
}

export default function LetterRating(props: LetterRatingProps) {
  const { greyscale = false, impactCategory, impactRating, width } = props;

  const impactRatingToColor = useImpactRatingToColor(greyscale);

  return (
    <svg
      width={width}
      viewBox="0 0 50 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>{graphikSemiboldWebCssString}</style>
      <ImpactCategoryIllustration
        greyscale={greyscale}
        impactCategory={impactCategory}
      />
      <rect
        x="23"
        y="1"
        width="24"
        height="30"
        rx="5"
        fill={impactRatingToColor(impactRating)}
        stroke="black"
        strokeWidth="2"
      />
      <text
        x={35}
        y={23}
        textAnchor="middle"
        fontFamily="Graphik Web"
        fontWeight={600}
        fontSize={20}
        fill={impactRatingLetterColor(impactRating, greyscale)}
      >
        {impactRatingToLetter(impactRating)}
      </text>
    </svg>
  );
}

interface ImpactCategoryIllustrationProps {
  greyscale: boolean;
  impactCategory: ImpactCategory;
}

function ImpactCategoryIllustration(props: ImpactCategoryIllustrationProps) {
  const { greyscale, impactCategory } = props;

  switch (impactCategory) {
    case ImpactCategory.GHG:
      return (
        <>
          <path
            d="M17.9299 31C26.2142 31 32.9299 24.2843 32.9299 16C32.9299 7.71573 26.2142 1 17.9299 1C9.64566 1 2.92993 7.71573 2.92993 16C2.92993 24.2843 9.64566 31 17.9299 31Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M10.5101 17.1401C9.54007 15.1201 10.8601 13.5301 10.8601 13.5301C7.89007 13.5301 8.09007 10.6401 8.09007 10.6401C5.84007 12.2001 4.18007 10.3401 4.18007 10.3401C0.100067 10.6901 1.06007 7.01012 1.06007 7.01012C0.140067 2.92012 4.24007 2.96012 4.24007 2.96012C8.33007 -0.529882 11.0901 3.51012 11.0901 3.51012C16.4701 3.37012 15.1401 8.89012 15.1401 8.89012C17.5701 10.7001 15.2401 12.8101 15.2401 12.8101C15.2401 12.8101 17.3401 13.9801 15.5901 17.1401"
            fill={mint(greyscale)}
          />
          <path
            d="M10.5101 17.1401C9.54007 15.1201 10.8601 13.5301 10.8601 13.5301C7.89007 13.5301 8.09007 10.6401 8.09007 10.6401C5.84007 12.2001 4.18007 10.3401 4.18007 10.3401C0.100067 10.6901 1.06007 7.01012 1.06007 7.01012C0.140067 2.92012 4.24007 2.96012 4.24007 2.96012C8.33007 -0.529882 11.0901 3.51012 11.0901 3.51012C16.4701 3.37012 15.1401 8.89012 15.1401 8.89012C17.5701 10.7001 15.2401 12.8101 15.2401 12.8101C15.2401 12.8101 17.3401 13.9801 15.5901 17.1401"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.93 31C18.42 31 18.91 30.97 19.39 30.93L17.62 17.87C17.55 17.38 17.14 17.02 16.65 17.02H9.93998C9.44998 17.02 9.03998 17.38 8.96998 17.87L7.72998 26.99C10.41 29.47 13.98 31 17.92 31H17.93Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.2227 23L32.9227 8.7H37.1427L41.7827 23H38.4027L37.4227 19.82H32.2427L31.2627 23H28.2227ZM34.8427 11.34L32.9427 17.52H36.7227L34.8427 11.34Z"
            fill="white"
          />
        </>
      );
    case ImpactCategory.LAND_USE:
      return (
        <>
          <path
            d="M17.9299 31C26.2142 31 32.9299 24.2843 32.9299 16C32.9299 7.71573 26.2142 1 17.9299 1C9.64566 1 2.92993 7.71573 2.92993 16C2.92993 24.2843 9.64566 31 17.9299 31Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M6.83008 26.0701C9.57008 29.0901 13.5201 31.0001 17.9301 31.0001C22.3401 31.0001 26.2801 29.0901 29.0301 26.0701H6.83008Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.4899 23.32H7.5199C8.0599 23.32 8.4899 23.83 8.4899 24.46V27.63C9.9599 28.83 11.6599 29.76 13.5099 30.33V24.46C13.5099 23.83 13.9499 23.32 14.4799 23.32H19.5199C20.9099 23.32 21.5799 21.62 20.5599 20.67L16.0799 16.53H17.8099C19.1999 16.53 19.8699 14.83 18.8499 13.88L14.8399 10.17H15.9399C17.3299 10.17 17.9999 8.47003 16.9799 7.52003L12.4199 3.50003C11.6199 2.79003 10.4199 2.79003 9.6199 3.50003L5.0599 7.52003C4.0399 8.46003 4.7099 10.17 6.0999 10.17H7.1999L3.1899 13.88C2.1699 14.82 2.8399 16.53 4.2299 16.53H5.9599L1.4799 20.67C0.459898 21.61 1.1299 23.32 2.5199 23.32H2.4899Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.2227 23L32.9227 8.7H37.1427L41.7827 23H38.4027L37.4227 19.82H32.2427L31.2627 23H28.2227ZM34.8427 11.34L32.9427 17.52H36.7227L34.8427 11.34Z"
            fill="white"
          />
        </>
      );
    case ImpactCategory.WATER_USE:
      return (
        <>
          <path
            d="M17.9299 31C26.2142 31 32.9299 24.2843 32.9299 16C32.9299 7.71573 26.2142 1 17.9299 1C9.64566 1 2.92993 7.71573 2.92993 16C2.92993 24.2843 9.64566 31 17.9299 31Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M17.93 31C23.34 31 28.07 28.13 30.71 23.83C29.43 22.65 28.6 21.07 28.6 21.07C23.59 29.8 18.88 21.07 18.88 21.07C13.4 29.47 9.16 20.55 9.16 20.55C7.81 22.28 6.5 23.36 5.25 23.99C7.91 28.19 12.58 30.99 17.93 30.99V31Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M0.959961 19.07C0.959961 14.13 6.64996 4.08996 6.64996 4.08996C7.25996 2.74996 9.36996 2.74996 9.97996 4.08996C9.97996 4.08996 15.67 13.95 15.67 19.07C15.67 23.13 12.38 26.93 8.31996 26.93C4.25996 26.93 0.959961 23.13 0.959961 19.07Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.2227 23L32.9227 8.7H37.1427L41.7827 23H38.4027L37.4227 19.82H32.2427L31.2627 23H28.2227ZM34.8427 11.34L32.9427 17.52H36.7227L34.8427 11.34Z"
            fill="white"
          />
        </>
      );
  }
}
