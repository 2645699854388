import { ImpactCategory } from "../../../../domain/impactCategories";
import {
  ImpactRating,
  useImpactRatingToColor,
} from "../../../../domain/impactRatings";
import { graphikSemiboldWebCssString } from "../../../../fonts/graphik-web/graphikSemiboldWebCssString";
import {
  darkTurquoise,
  foodstepsTurquoise,
  mint,
} from "../../../graphs/colors";
import { impactRatingLetterColor } from "../../impactRatingLetterColor";
import { impactRatingToLetter } from "./translations";

interface RatingScaleProps {
  greyscale?: boolean;
  impactCategory: ImpactCategory;
  impactRating: ImpactRating;
  width: number;
}

export default function RatingScale(props: RatingScaleProps) {
  const { greyscale = false, impactCategory, impactRating, width } = props;

  return (
    <svg
      width={width}
      viewBox="0 0 108 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>{graphikSemiboldWebCssString}</style>
      <ImpactCategoryIllustration
        greyscale={greyscale}
        impactCategory={impactCategory}
      />
      <rect x="29.6201" y="1" width="76" height="40" rx="7" fill="white" />
      <rect
        x="29.6201"
        y="1"
        width="76"
        height="40"
        rx="7"
        stroke="black"
        strokeWidth="2"
      />
      <ImpactRatingScale greyscale={greyscale} impactRating={impactRating} />
      <defs>
        <clipPath id="clip0_148_1613">
          <rect width="46" height="42" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

interface ImpactRatingScaleProps {
  greyscale: boolean;
  impactRating: ImpactRating;
}

function ImpactRatingScale(props: ImpactRatingScaleProps) {
  const { greyscale, impactRating } = props;

  const impactRatingToColor = useImpactRatingToColor(greyscale);

  const ratingLetterColor = impactRatingLetterColor(impactRating, greyscale);
  const ratingLetter = impactRatingToLetter(impactRating);

  const ratingLetterY = 26.7;
  const ratingLetterXA = 49;
  const ratingLetterFontSize = 16;
  const distanceBetweenRatingLetters = 10;

  switch (impactRating) {
    case "VLOW":
      return (
        <>
          <rect
            x="39"
            y="9"
            width="20"
            height="24"
            rx="5"
            fill={impactRatingToColor("VLOW")}
            stroke="black"
            strokeWidth="2"
          />
          <path d="M62 13H70V29H62V13Z" fill={impactRatingToColor("LOW")} />
          <rect
            x="72"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor("MEDIUM")}
          />
          <rect
            x="82"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor("HIGH")}
          />
          <path
            d="M92 13H96C98.2091 13 100 14.7909 100 17V25C100 27.2091 98.2091 29 96 29H92V13Z"
            fill={impactRatingToColor("VHIGH")}
          />
          <text
            x={ratingLetterXA}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
        </>
      );
    case "LOW":
      return (
        <>
          <path
            d="M38 17C38 14.7909 39.7909 13 42 13H46V29H42C39.7909 29 38 27.2091 38 25V17Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="49"
            y="9"
            width="20"
            height="24"
            rx="5"
            fill={impactRatingToColor("LOW")}
            stroke="black"
            strokeWidth="2"
          />
          <rect
            x="72"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor("MEDIUM")}
          />
          <rect
            x="82"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor("HIGH")}
          />
          <path
            d="M92 13H96C98.2091 13 100 14.7909 100 17V25C100 27.2091 98.2091 29 96 29H92V13Z"
            fill={impactRatingToColor("VHIGH")}
          />
          <text
            x={ratingLetterXA + distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
        </>
      );
    case "MEDIUM":
      return (
        <>
          <path
            d="M38 17C38 14.7909 39.7909 13 42 13H46V29H42C39.7909 29 38 27.2091 38 25V17Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="48"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor("LOW")}
          />
          <rect
            x="59"
            y="9"
            width="20"
            height="24"
            rx="5"
            fill={impactRatingToColor("MEDIUM")}
            stroke="black"
            strokeWidth="2"
          />
          <rect
            x="82"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor("HIGH")}
          />
          <path
            d="M92 13H96C98.2091 13 100 14.7909 100 17V25C100 27.2091 98.2091 29 96 29H92V13Z"
            fill={impactRatingToColor("VHIGH")}
          />
          <text
            x={ratingLetterXA + 2 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
        </>
      );
    case "HIGH":
      return (
        <>
          <path
            d="M38 17C38 14.7909 39.7909 13 42 13H46V29H42C39.7909 29 38 27.2091 38 25V17Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="48"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor("LOW")}
          />
          <rect
            x="58"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor("MEDIUM")}
          />
          <rect
            x="69"
            y="9"
            width="20"
            height="24"
            rx="5"
            fill={impactRatingToColor("HIGH")}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M92 13H96C98.2091 13 100 14.7909 100 17V25C100 27.2091 98.2091 29 96 29H92V13Z"
            fill={impactRatingToColor("VHIGH")}
          />
          <text
            x={ratingLetterXA + 3 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
        </>
      );
    case "VHIGH":
      return (
        <>
          <path
            d="M38 17C38 14.7909 39.7909 13 42 13H46V29H42C39.7909 29 38 27.2091 38 25V17Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="48"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor("LOW")}
          />
          <rect
            x="58"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor("MEDIUM")}
          />
          <path d="M68 13H76V29H68V13Z" fill={impactRatingToColor("HIGH")} />
          <rect
            x="79"
            y="9"
            width="20"
            height="24"
            rx="5"
            fill={impactRatingToColor("VHIGH")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 4 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
        </>
      );
  }
}

interface ImpactCategoryIllustrationProps {
  greyscale: boolean;
  impactCategory: ImpactCategory;
}

function ImpactCategoryIllustration(props: ImpactCategoryIllustrationProps) {
  const { greyscale, impactCategory } = props;

  switch (impactCategory) {
    case ImpactCategory.GHG:
      return (
        <>
          <path
            d="M23.5599 40.8699C34.5338 40.8699 43.4299 31.9738 43.4299 20.9999C43.4299 10.026 34.5338 1.12988 23.5599 1.12988C12.586 1.12988 3.68994 10.026 3.68994 20.9999C3.68994 31.9738 12.586 40.8699 23.5599 40.8699Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M13.7399 22.5099C12.4599 19.8299 14.2099 17.7199 14.2099 17.7199C10.2699 17.7199 10.5399 13.8999 10.5399 13.8999C7.5599 15.9699 5.3599 13.4999 5.3599 13.4999C-0.0601025 13.9599 1.2199 9.0899 1.2199 9.0899C-0.00010252 3.6699 5.4199 3.7299 5.4199 3.7299C10.8399 -0.900104 14.4999 4.4599 14.4999 4.4599C21.6299 4.2799 19.8599 11.5899 19.8599 11.5899C23.0799 13.9899 19.9899 16.7799 19.9899 16.7799C19.9899 16.7799 22.7699 18.3299 20.4499 22.5099"
            fill={mint(greyscale)}
          />
          <path
            d="M13.7399 22.5099C12.4599 19.8299 14.2099 17.7199 14.2099 17.7199C10.2699 17.7199 10.5399 13.8999 10.5399 13.8999C7.5599 15.9699 5.3599 13.4999 5.3599 13.4999C-0.0601025 13.9599 1.2199 9.0899 1.2199 9.0899C-0.00010246 3.6699 5.4199 3.7299 5.4199 3.7299C10.8399 -0.900104 14.4999 4.4599 14.4999 4.4599C21.6299 4.2799 19.8599 11.5899 19.8599 11.5899C23.0799 13.9899 19.9899 16.7799 19.9899 16.7799C19.9899 16.7799 22.7699 18.3299 20.4499 22.5099"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.5598 40.8801C24.2198 40.8801 24.8598 40.8401 25.4998 40.7801L23.1598 23.4801C23.0698 22.8401 22.5198 22.3601 21.8698 22.3601H12.9798C12.3298 22.3601 11.7798 22.8401 11.6898 23.4801L10.0498 35.5601C13.5998 38.8501 18.3298 40.8801 23.5498 40.8801H23.5598Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
    case ImpactCategory.LAND_USE:
      return (
        <>
          <path
            d="M23.7299 40.8699C34.7038 40.8699 43.5999 31.9738 43.5999 20.9999C43.5999 10.026 34.7038 1.12988 23.7299 1.12988C12.756 1.12988 3.85986 10.026 3.85986 20.9999C3.85986 31.9738 12.756 40.8699 23.7299 40.8699Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M9.02002 34.3401C12.66 38.3501 17.89 40.8801 23.72 40.8801C29.55 40.8801 34.79 38.3501 38.42 34.3401H9.02002Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.28004 30.6999H9.95004C10.66 30.6999 11.24 31.3799 11.24 32.2099V36.3999C13.19 37.9899 15.43 39.2199 17.89 39.9799V32.2099C17.89 31.3799 18.47 30.6999 19.18 30.6999H25.85C27.69 30.6999 28.57 28.4399 27.22 27.1899L21.28 21.6999H23.57C25.41 21.6999 26.29 19.4399 24.94 18.1899L19.62 13.2699H21.08C22.92 13.2699 23.8 11.0099 22.45 9.75993L16.41 4.43993C15.35 3.50993 13.76 3.50993 12.7 4.43993L6.66004 9.75993C5.31004 11.0099 6.19004 13.2699 8.03004 13.2699H9.49004L4.17004 18.1899C2.82004 19.4399 3.70004 21.6999 5.54004 21.6999H7.83004L1.89004 27.1899C0.54004 28.4399 1.42004 30.6999 3.26004 30.6999H3.28004Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
    case ImpactCategory.WATER_USE:
      return (
        <>
          <path
            d="M23.6102 40.8699C34.5841 40.8699 43.4802 31.9738 43.4802 20.9999C43.4802 10.026 34.5841 1.12988 23.6102 1.12988C12.6363 1.12988 3.74023 10.026 3.74023 20.9999C3.74023 31.9738 12.6363 40.8699 23.6102 40.8699Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M23.6098 40.88C30.7798 40.88 37.0398 37.07 40.5398 31.38C38.8498 29.82 37.7398 27.73 37.7398 27.73C31.0998 39.3 24.8698 27.73 24.8698 27.73C17.6098 38.86 11.9998 27.04 11.9998 27.04C10.2098 29.33 8.46982 30.76 6.81982 31.6C10.3398 37.17 16.5398 40.88 23.6198 40.88H23.6098Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.14014 25.0701C1.14014 18.5201 8.68014 5.23009 8.68014 5.23009C9.49014 3.46009 12.2801 3.46009 13.0901 5.23009C13.0901 5.23009 20.6301 18.3001 20.6301 25.0701C20.6301 30.4501 16.2701 35.4801 10.8901 35.4801C5.51014 35.4801 1.14014 30.4501 1.14014 25.0701Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
  }
}
