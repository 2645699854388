import { ImpactCategory } from "../../domain/impactCategories";
import assertNever from "../../util/assertNever";

export function averageIntensity(
  impact: {
    averageIntensityGhg: number | null;
    averageIntensityLandUse: number | null;
    averageIntensityWaterUse: number | null;
  },
  impactCategory: ImpactCategory
) {
  if (impactCategory === ImpactCategory.GHG) {
    return impact.averageIntensityGhg;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    return impact.averageIntensityLandUse;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    return impact.averageIntensityWaterUse;
  } else {
    assertNever(impactCategory, "Unexpected impact category");
  }
}

export function getImpactPerKg(
  recipe: {
    impactCached: {
      ghgPerKg: number | null;
      landUsePerKg: number | null;
      waterUsePerKg: number | null;
    };
  },
  impactCategory: ImpactCategory
): number | null {
  if (impactCategory === ImpactCategory.GHG) {
    return recipe.impactCached?.ghgPerKg ?? null;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    return recipe.impactCached?.landUsePerKg ?? null;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    return recipe.impactCached?.waterUsePerKg ?? null;
  } else {
    assertNever(impactCategory, "invalid ImpactCategory");
  }
}
