import { FormattedMessage, IntlShape, useIntl } from "react-intl";

import { DashboardDietaryCategory } from "../../data-store";
import BarChart2, { Bar } from "../graphs/BarChart2";
import "./ProductsByDietChart.css";

interface ProductsByDietChartProps {
  dietaryCategories: Array<DashboardDietaryCategory>;
}

export default function ProductsByDietChart(props: ProductsByDietChartProps) {
  const { dietaryCategories } = props;

  const intl = useIntl();

  const productCountSum = dietaryCategories.reduce(
    (sum, dietaryCategory) => sum + dietaryCategory.productCount,
    0
  );

  const bars: Array<Bar> = dietaryCategories.map((dietaryCategory) => {
    return {
      label: dietaryCategory.name,
      value: [dietaryCategory.productCount],
      percentage: `${(
        (dietaryCategory.productCount / productCountSum) *
        100
      ).toFixed(1)}%`,
    };
  });
  const dependentAxisLabel = intl.formatMessage({
    id: "components/dashboard/ProductsByDietChart:dependentAxisLabel",
    defaultMessage: "Number of Products",
  });

  return (
    <div className="ProductsByDietChart">
      <h4 className="m-0">
        <FormattedMessage
          id="components/dashboard/ProductsByDietChart:title"
          defaultMessage="Products by Diet"
        />
      </h4>
      <BarChart2
        bars={bars}
        dependentAxisLabel={dependentAxisLabel}
        grace="50%"
        height={300}
        horizontal
        sorted
        maintainAspectRatio={false}
        customTooltip={(tooltip: { dataPoints: Array<DataPoint> }) => (
          <Tooltip dataPoints={tooltip.dataPoints} intl={intl} />
        )}
      />
    </div>
  );
}

interface DataPoint {
  raw: number;
  label: string;
  dataIndex: number;
  dataset: {
    formattedBars: Array<{
      percentage: string;
    }>;
  };
}

interface TooltipProps {
  dataPoints: Array<DataPoint>;
  intl: IntlShape;
}

function Tooltip(props: TooltipProps) {
  const { dataPoints, intl } = props;

  const percentage =
    dataPoints[0].dataset.formattedBars[dataPoints[0].dataIndex].percentage;

  const itemCount = dataPoints[0].raw;
  const itemCountString =
    itemCount === 1
      ? intl.formatMessage({
          id: "components/ProductsByDietChart:itemCountSingle",
          defaultMessage: "product",
        })
      : intl.formatMessage({
          id: "components/ProductsByDietChart:itemCountPlural",
          defaultMessage: "products",
        });
  return (
    <div className="ProductsByDietChart_Tooltip">
      <div className="ProductsByDietChart_Tooltip_Label">
        {dataPoints[0].label}
      </div>
      <div className="ProductsByDietChart_Tooltip_ItemCount">
        <div className="ProductsByDietChart_Tooltip_ItemCountValue">
          {itemCount}
        </div>
        <div className="small-copy">{itemCountString}</div>
      </div>
      <div className="ProductsByDietChart_Tooltip_PercentageProductRange">
        <div className="small-copy-medium">{percentage}</div>
        <div className="small-copy">
          {intl.formatMessage({
            id: "components/ProductsByDietChart:percentageProductRange",
            defaultMessage: "of product range",
          })}
        </div>
      </div>
    </div>
  );
}
