import React from "react";
import { FormattedMessage } from "react-intl";

import { Tier } from "../../__generated__/globalTypes";
import assertNever from "../../util/assertNever";

export const sellingPoints = [
  "quickStart",
  "farmToForkInsights",
  "addMoreRecipes",
  "foodstepsBenchmark",
  "labels",
  "decarbonisationSupport",
  "bespokeDataScienceSupport",
  "bespokeLifecycleAnalysis",
  "netZeroDelivery",
] as const;

export type SellingPoint = (typeof sellingPoints)[number];

export interface SellingPointText {
  name: React.ReactNode;
  description: React.ReactNode;
}

export const sellingPointTexts: {
  [P in SellingPoint]: SellingPointText;
} = {
  quickStart: {
    name: (
      <FormattedMessage
        id="components/subscriptions/sellingPoints:quickStart/name"
        defaultMessage="Quick start"
      />
    ),
    description: (
      <FormattedMessage
        id="components/subscriptions/sellingPoints:quickStart/description"
        defaultMessage="Create your own account and see your impact data insights immediately."
      />
    ),
  },
  farmToForkInsights: {
    name: (
      <FormattedMessage
        id="components/subscriptions/sellingPoints:farmToForkInsights/name"
        defaultMessage="Farm to fork insights"
      />
    ),
    description: (
      <FormattedMessage
        id="components/subscriptions/sellingPoints:farmToForkInsights/description"
        defaultMessage="See the impact across the entire life cycle of your food products."
      />
    ),
  },
  addMoreRecipes: {
    name: (
      <FormattedMessage
        id="components/subscriptions/sellingPoints:addMoreProducts/name"
        defaultMessage="Add more products"
      />
    ),
    description: (
      <FormattedMessage
        id="components/subscriptions/sellingPoints:addMoreRecipes/description"
        defaultMessage="Measure more from your menu by adding more products."
      />
    ),
  },
  foodstepsBenchmark: {
    name: (
      <FormattedMessage
        id="components/subscriptions/sellingPoints:foodstepsBenchmark/name"
        defaultMessage="The Foodsteps benchmark"
      />
    ),
    description: (
      <FormattedMessage
        id="components/subscriptions/sellingPoints:foodstepsBenchmark/description"
        defaultMessage="Access to a welcome call with the Foodsteps team, benchmark and industry reports, our community forum and tools to help drive impact."
      />
    ),
  },
  decarbonisationSupport: {
    name: (
      <FormattedMessage
        id="components/subscriptions/sellingPoints:decarbonizationSupport/name"
        defaultMessage="Decarbonisation support"
      />
    ),
    description: (
      <FormattedMessage
        id="components/subscriptions/sellingPoints:decarbonizationSupport/description"
        defaultMessage="Connect with our in-house carbon experts for support and resources."
      />
    ),
  },
  bespokeDataScienceSupport: {
    name: (
      <FormattedMessage
        id="components/subscriptions/sellingPoints:bespokeDataScienceSupport/name"
        defaultMessage="Bespoke data-science support"
      />
    ),
    description: (
      <FormattedMessage
        id="components/subscriptions/sellingPoints:bespokeDataScienceSupport/description"
        defaultMessage="Build product impact data by adding new ingredients to be measured by our analytics team. We can also help accurately map the impact of your operations, including preparation and storage."
      />
    ),
  },
  bespokeLifecycleAnalysis: {
    name: (
      <FormattedMessage
        id="components/subscriptions/sellingPoints:bespokeLifeCycleAnalysis/name"
        defaultMessage="Bespoke life cycle analysis"
      />
    ),
    description: (
      <FormattedMessage
        id="components/subscriptions/sellingPoints:bespokeLifeCycleAnalysis/description"
        defaultMessage="Analyse data from across your supply chain including packaging, processing, transport and farming."
      />
    ),
  },
  netZeroDelivery: {
    name: (
      <FormattedMessage
        id="components/subscriptions/sellingPoints:netZeroDelivery/name"
        defaultMessage="Net zero delivery"
      />
    ),
    description: (
      <FormattedMessage
        id="components/subscriptions/sellingPoints:netZeroDelivery/description"
        defaultMessage="Tackle your net zero goals head on with sales data integration to scope out your total emissions."
      />
    ),
  },
  labels: {
    name: (
      <FormattedMessage
        id="components/subscriptions/sellingPoints:labels/name"
        defaultMessage="Labels"
      />
    ),
    description: (
      <FormattedMessage
        id="components/subscriptions/sellingPoints:labels/description"
        defaultMessage="Download your unique labels for each product, perfect for adding to packaging."
      />
    ),
  },
};

export function getSellingPoints(
  tier: Tier,
  labels: boolean
): Array<SellingPoint> {
  let sellingPoints: Array<SellingPoint> = [];

  if (tier === Tier.FOOD_SERVICE_FREE) {
    sellingPoints = ["quickStart", "farmToForkInsights"];
  } else if (tier === Tier.FOOD_SERVICE_BASIC) {
    sellingPoints = [
      "quickStart",
      "farmToForkInsights",
      "addMoreRecipes",
      "foodstepsBenchmark",
    ];
  } else if (tier === Tier.FOOD_SERVICE_PREMIUM) {
    sellingPoints = [
      "quickStart",
      "farmToForkInsights",
      "addMoreRecipes",
      "foodstepsBenchmark",
      "decarbonisationSupport",
      "bespokeDataScienceSupport",
    ];
  } else if (tier === Tier.FOOD_SERVICE_ENTERPRISE) {
    sellingPoints = [
      "farmToForkInsights",
      "addMoreRecipes",
      "foodstepsBenchmark",
      "decarbonisationSupport",
      "bespokeDataScienceSupport",
      "bespokeLifecycleAnalysis",
      "netZeroDelivery",
    ];
  } else if (
    tier === Tier.FOOD_SERVICE_LEGACY ||
    tier === Tier.MANUFACTURER_FREE ||
    tier === Tier.MANUFACTURER_PREMIUM ||
    tier === Tier.MANUFACTURER_ENTERPRISE
  ) {
    throw new Error(
      `${tier} does not have selling points because it is not visible on the Plans page.`
    );
  } else {
    assertNever(tier, "Invalid Tier");
  }

  if (labels) {
    sellingPoints.push("labels");
  }

  return sellingPoints;
}
