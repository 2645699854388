import {
  EffectType,
  ImpactCategory,
  ImpactRating,
} from "../../__generated__/globalTypes";
import DomainEffectType, { effectTypes } from "../../domain/EffectType";
import ImpactRatingExplanation from "./ImpactRatingExplanation";

const impactRatingInfos = [
  {
    effectType: EffectType.GHG_PER_KG,
    impactRatingInfo: [
      { impactRating: ImpactRating.LOW, lowerBound: 1.848, label: "Low" },
      {
        impactRating: ImpactRating.MEDIUM,
        lowerBound: 3.235,
        label: "Medium",
      },
      { impactRating: ImpactRating.HIGH, lowerBound: 5.545, label: "High" },
      {
        impactRating: ImpactRating.VHIGH,
        lowerBound: 9.242,
        label: "Very High",
      },
      {
        impactRating: ImpactRating.VLOW,
        lowerBound: null,
        label: "Very Low",
      },
    ],
    label: "GHG",
  },
];

const stories = {
  name: "recipes/ImpactRatingExplanation",
  children: [
    {
      name: "ghgPerKg",
      render: () => (
        <div className="d-flex flex-row flex-wrap">
          <Example
            effectType={effectTypes.ghgPerKg}
            name="Negative"
            ghgPerKg={-1.2235256}
            ghgPerServing={-1.01}
          />
          <Example
            effectType={effectTypes.ghgPerKg}
            name="Very low"
            ghgPerKg={1.2235256}
            ghgPerServing={1.23}
          />
          <Example
            effectType={effectTypes.ghgPerKg}
            name="Low"
            ghgPerKg={1.9422551}
            ghgPerServing={2.33}
          />
          <Example
            effectType={effectTypes.ghgPerKg}
            name="Medium"
            ghgPerKg={4.8275945}
            ghgPerServing={3.89}
          />
          <Example
            effectType={effectTypes.ghgPerKg}
            name="High"
            ghgPerKg={7.24585}
            ghgPerServing={4.72}
          />
          <Example
            effectType={effectTypes.ghgPerKg}
            name="Very high"
            ghgPerKg={11.5696}
            ghgPerServing={10.101}
          />
        </div>
      ),
    },
    {
      name: "ghgPerServing",
      render: () => (
        <div className="d-flex flex-row flex-wrap">
          <Example
            effectType={effectTypes.ghgPerServing}
            name="Medium"
            ghgPerKg={4.8275945}
            ghgPerServing={3.89}
          />
        </div>
      ),
    },
  ],
};

interface ExampleProps {
  effectType: DomainEffectType;
  ghgPerKg: number;
  ghgPerServing: number;
  name: string;
}

function Example(props: ExampleProps) {
  const { ghgPerKg, ghgPerServing, name } = props;

  return (
    <div className="m-4 p-4" style={{ border: "1px solid #999" }}>
      <h6 className="mb-4 text-center">{name}</h6>
      <ImpactRatingExplanation
        impactCategory={ImpactCategory.GHG}
        impactRatingInfos={impactRatingInfos}
        impact={{
          effects: {
            ghgPerKg,
            ghgPerRootRecipeServing: ghgPerServing,
            landUsePerKg: null,
            landUsePerRootRecipeServing: null,
            waterUsePerKg: null,
            waterUsePerRootRecipeServing: null,
          },
        }}
        isHotDrink={false}
      />
    </div>
  );
}

export default stories;
