/**
 * ===== Colors also defined in custom.scss =====
 * These colors are also defined in custom.scss. If you change the colours
 * here, don't forget to change them in custom.scss as well, and vice
 * versa
 */
export const foodstepsTurquoiseCol = "rgba(0, 166, 143, 1)";
export const darkTurquoiseCol = "rgba(16, 103, 91, 1)";
export const mintCol = "rgba(211, 255, 204, 1)";
export const lightCol = "#fbfbfb";
export const whiteCol = "#ffffff";
export const interfaceGrey = "rgba(222, 226, 230, 1)";
export const infoCol = "rgba(0,0,0,1)";
export const greyCol = "rgba(222, 226, 230, 1)";
export const gridLineGreyCol = "rgba(222, 226, 230, 0.4)";
export const midGreyCol = "rgba(174, 181, 188, 1)";
export const darkGreyCol = "rgba(102, 102, 101, 1)";
export const boneCol = "rgba(244, 244, 240, 1)";
export const accentYellow = "rgba(240, 229, 43, 1)";
// State
export const successCol = "rgba(66, 190, 115, 0.99)";
export const dangerCol = "rgba(244, 67, 54, 0.99)";
// Qualitative data
export const brine = "rgba(217, 219, 120, 1)";
export const mintPastel = "rgba(207, 246, 199, 1)";
export const wasabi = "rgba(122, 153, 43, 1)";
export const brightBlue = "rgba(51, 209, 232, 1)";
export const forestGreen = "rgba(19, 95, 36, 1)";
export const marine = "rgba(41, 89, 204, 1)";
export const navy = "rgba(36, 50, 158, 1)";
export const racingGreen = "rgba(0, 56, 19, 1)";
export const riviera = "rgba(51, 169, 192, 1)";
export const leaf = "rgba(69, 107, 41, 1)";
export const aqua = "rgba(148, 240, 232, 1)";
export const olive = "rgba(158, 161, 77, 1)";
// Sequential data
export const lavenderRose = "rgba(239, 152, 225, 1)";
export const mauvelous = "rgba(240, 135, 198, 1)";
export const deepBlush = "rgba(239, 117, 172, 1)";
export const barbie = "rgba(237, 99, 147, 1)";
export const mandy = "rgba(234, 79, 122, 1)";
export const cerise = "rgba(229, 56, 98, 1)";
export const maroonFlush = "rgba(200, 26, 81, 1)";
export const marooned = "rgba(177, 28, 86, 1)";
export const disco = "rgba(154, 30, 91, 1)";
export const port = "rgba(130, 31, 95, 1)";
export const plum = "rgba(105, 31, 100, 1)";
export const grape = "rgba(77, 31, 104, 1)";
// Impact ratings
export const veryLowA = "rgba(52, 128, 65, 1)";
export const lowB = "rgba(187, 214, 78, 1)";
export const mediumC = "rgba(249, 203, 39, 1)";
export const highD = "rgba(239, 97, 37, 1)";
export const veryHighE = "rgba(209, 23, 7, 1)";
export const noRating = "rgb(244, 244, 240)";
/**
 * ===== End of colors also defined in custom.scss =====
 */

// Greyscale
export const greyscaleVeryLowA = "#D8D8D8";
export const greyscaleLowB = "#AFAFAF";
export const greyscaleMediumC = "#686868";
export const greyScaleHighD = "#414141";
export const greyScaleVeryHighE = "#000";
export const greyScaleFoodstepsTurquoiseCol = "rgba(217, 217, 217, 1)";
export const greyScaleDarkTurquoiseCol = "rgba(151, 151, 151, 1)";
export const greyScaleMintCol = "rgba(255, 255, 255, 1)";

export interface ColorLists {
  colours: Array<string>;
  hover: Array<string>;
}

function createColorList(colours: Array<string>): ColorLists {
  return {
    colours,
    hover: generateHoverColours(colours),
  };
}

export const changeOpacity = (colour: string, dec: string) => {
  return colour.replace(/[^,]+(?=\))/, dec);
};

function generateHoverColours(colours: Array<string>) {
  return colours.map((x) => changeOpacity(x, "0.7"));
}

export const qualitativeDataColors = [
  brine,
  mintPastel,
  wasabi,
  brightBlue,
  forestGreen,
  marine,
  navy,
  racingGreen,
  riviera,
  leaf,
  aqua,
  olive,
];

export const sequentialDataColors = [
  lavenderRose,
  mauvelous,
  deepBlush,
  barbie,
  mandy,
  cerise,
  maroonFlush,
  marooned,
  disco,
  port,
  plum,
  grape,
];

export const dietColourLists = createColorList(qualitativeDataColors);

export const collectionColourLists = createColorList(qualitativeDataColors);

export const recipeColourLists = collectionColourLists;

export function foodstepsTurquoise(greyscale: boolean) {
  return greyscale ? greyScaleFoodstepsTurquoiseCol : foodstepsTurquoiseCol;
}

export function darkTurquoise(greyscale: boolean) {
  return greyscale ? greyScaleDarkTurquoiseCol : darkTurquoiseCol;
}

export function mint(greyscale: boolean) {
  return greyscale ? greyScaleMintCol : mintCol;
}
