import { ImpactRating } from "../../__generated__/globalTypes";
import FoodServiceImpactSummary from "./FoodServiceImpactSummary";

const stories = {
  name: "FoodServiceImpactSummary",
  render: () => (
    <FoodServiceImpactSummary
      impact={{
        impactRatingGhg: ImpactRating.HIGH,
        impactRatingLandUse: ImpactRating.VLOW,
        impactRatingWaterUse: ImpactRating.LOW,
        effects: {
          ghgPerKg: 10,
          ghgPerRootRecipeServing: 4,
          landUsePerRootRecipeServing: 1,
          landUsePerKg: 2,
          waterUsePerRootRecipeServing: 3.5,
          waterUsePerKg: 2.1,
        },
        errors: [],
      }}
      recipe={{ isHotDrink: true, id: 1 }}
      showExportButton={true}
    />
  ),
};

export default stories;
