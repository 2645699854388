import compact from "lodash/compact";
import { FormattedMessage } from "react-intl";

import { useLabels } from "../../services/useOrganizationFeatures";
import { RecipePageName } from "../../tracking";
import { RecipeFilterWithExclude } from "../labels/DownloadLabelsModal";
import { Generate } from "../utils/Vectors";
import RecipesDropdown from "./RecipesDropdown";
import useExportLabelsDropdownItem from "./useExportLabelsDropdownItem";
import useExportRecipeImpactsCsvDropdownItem from "./useExportRecipeImpactsCsvDropdownItem";

interface ExportRecipesDropdownProps {
  disabled: boolean;
  pageName: RecipePageName;
  recipeFilter: RecipeFilterWithExclude;
}

export default function ExportRecipesDropdown(
  props: ExportRecipesDropdownProps
) {
  const { disabled, pageName, recipeFilter } = props;
  const hasFeatureLabels = useLabels();

  const exportCarbonLabelsDropdownItem = useExportLabelsDropdownItem({
    recipeFilter,
    pageName,
  });

  const exportRecipeImpactsCsvDropdownItem =
    useExportRecipeImpactsCsvDropdownItem({
      pageName,
      recipeFilter,
    });

  return (
    <RecipesDropdown
      disabled={disabled}
      Icon={Generate}
      items={compact([
        hasFeatureLabels && exportCarbonLabelsDropdownItem,
        exportRecipeImpactsCsvDropdownItem,
      ])}
      title={
        <FormattedMessage
          id="components/recipes/ExportRecipesDropdown:export"
          defaultMessage="Export"
        />
      }
      variant="secondary"
    />
  );
}
