import { EffectType, ImpactRating } from "../../__generated__/globalTypes";
import { Category, ProcuredItem, Supplier } from "../../data-store";
import { ImpactCategory } from "../../domain/impactCategories";
import CategoriesTable from "./CategoriesTable";
import ImpactRatingProportionsChart from "./ImpactRatingProportionsChart";
import ProcuredItemsTable from "./ProcuredItemsTable";
import ReportContent from "./ReportContent";

const categoriesDummyData: Array<Category> = [...Array(100).keys()].map((i) => {
  return {
    name: `Category ${i}`,
    spend: "101.99",
    massKg: 2,
    ghg: 1,
    landUse: 2,
    waterUse: 3,
  };
});

const itemsDummyData: Array<ProcuredItem> = [...Array(100).keys()].map((i) => {
  return {
    categoryName: "Category",
    ghg: 1,
    impactRatingGhg: "VLOW",
    impactRatingLandUse: "MEDIUM",
    impactRatingWaterUse: "HIGH",
    landUse: 2,
    internalId: i.toString(),
    name: `Item ${i}`,
    massKg: 2,
    quantity: 3,
    spend: "4.50",
    unit: "kg",
    waterUse: 3,
  };
});

const suppliersDummyData: Array<Supplier> = [...Array(100).keys()].map((i) => {
  return {
    name: `Supplier ${i}`,
    spend: "101.99",
    massKg: 2,
    ghg: 1,
    landUse: 2,
    waterUse: 3,
  };
});

const proportionsDummyData = {
  veryLow: { proportion: 0.1, productCount: 1 },
  low: { proportion: 0.2, productCount: 2 },
  medium: { proportion: 0.3, productCount: 3 },
  high: { proportion: 0.3, productCount: 3 },
  veryHigh: { proportion: 0.1, productCount: 1 },
};

const impactRatingInfos = {
  impactRatingInfos: [
    {
      effectType: EffectType.GHG_PER_KG,
      impactRatingInfo: [
        {
          lowerBound: 0.8,
          impactRating: ImpactRating.VLOW,
        },
        {
          lowerBound: 1.2,
          impactRating: ImpactRating.LOW,
        },
        {
          lowerBound: 1.6,
          impactRating: ImpactRating.MEDIUM,
        },
        {
          lowerBound: 2.0,
          impactRating: ImpactRating.HIGH,
        },
        {
          lowerBound: 2.4,
          impactRating: ImpactRating.VHIGH,
        },
      ],
    },
    {
      effectType: EffectType.LAND_USE_PER_KG,
      impactRatingInfo: [
        {
          lowerBound: 0.8,
          impactRating: ImpactRating.VLOW,
        },
        {
          lowerBound: 1.2,
          impactRating: ImpactRating.LOW,
        },
        {
          lowerBound: 1.6,
          impactRating: ImpactRating.MEDIUM,
        },
        {
          lowerBound: 2.0,
          impactRating: ImpactRating.HIGH,
        },
        {
          lowerBound: 2.4,
          impactRating: ImpactRating.VHIGH,
        },
      ],
    },
    {
      effectType: EffectType.WATER_USE_PER_KG,
      impactRatingInfo: [
        {
          lowerBound: 8,
          impactRating: ImpactRating.VLOW,
        },
        {
          lowerBound: 12,
          impactRating: ImpactRating.LOW,
        },
        {
          lowerBound: 16,
          impactRating: ImpactRating.MEDIUM,
        },
        {
          lowerBound: 20,
          impactRating: ImpactRating.HIGH,
        },
        {
          lowerBound: 24,
          impactRating: ImpactRating.VHIGH,
        },
      ],
    },
  ],
};

const stories = {
  name: "Reports",
  children: [
    {
      name: "ReportContent",
      render: () => (
        <ReportContent
          impactCategory={ImpactCategory.GHG}
          categories={categoriesDummyData}
          foodstepsCategories={categoriesDummyData}
          procuredItems={itemsDummyData}
          suppliers={suppliersDummyData}
          totalImpact={2000}
          totalSpend={"1000"}
        />
      ),
    },
    {
      name: "CategoriesTable",
      render: () => (
        <CategoriesTable
          data={categoriesDummyData}
          categoryColumnLabel={"Category"}
          impactCategory={ImpactCategory.GHG}
          unspecifiedCategoryLabel="Unspecified"
        />
      ),
    },
    {
      name: "ProcuredItemsTable",
      render: () => (
        <ProcuredItemsTable
          data={itemsDummyData}
          impactCategory={ImpactCategory.GHG}
        />
      ),
    },
    {
      name: "ImpactRatingProportionsChart - Products Dashboard",
      render: () => (
        <div className="m-4">
          <ImpactRatingProportionsChart
            dashboardType="product"
            impactCategory={ImpactCategory.GHG}
            impactRatingInfos={impactRatingInfos}
            proportions={proportionsDummyData}
          />
        </div>
      ),
    },
    {
      name: "ImpactRatingProportionsChart - Procurement Dashboard",
      render: () => (
        <div className="m-4">
          <ImpactRatingProportionsChart
            dashboardType="procurement"
            impactCategory={ImpactCategory.GHG}
            impactRatingInfos={impactRatingInfos}
            proportions={proportionsDummyData}
          />
        </div>
      ),
    },
  ],
};

export default stories;
