import { FormattedMessage } from "react-intl";

import {
  ImpactCategory,
  useImpactCategoryLabel,
} from "../../domain/impactCategories";
import assertNever from "../../util/assertNever";
import ImpactRatingProportionsChart, {
  ProportionsChartImpactRatingInfos,
  ProportionsWithProductCount,
} from "../scope-3/ImpactRatingProportionsChart";
import "./ProductsByImpactRating.css";

interface ProductsByImpactRatingProps {
  impactCategory: ImpactCategory;
  impactRatingInfos: ProportionsChartImpactRatingInfos;
  impactRatingProportions: {
    ghg: ProportionsWithProductCount;
    landUse: ProportionsWithProductCount;
    waterUse: ProportionsWithProductCount;
  };
}

export function ProductsByImpactRating(props: ProductsByImpactRatingProps) {
  const { impactCategory, impactRatingInfos, impactRatingProportions } = props;
  const selectedImpactCategory = useImpactCategoryLabel(impactCategory);

  return (
    <div className="ProductsByImpactRating_DashboardCard">
      <h4 className="m-0">
        <FormattedMessage
          id="components/dashboard/ProductsByGHGRating:title"
          defaultMessage="Products by {impactCategory} Rating"
          values={{
            impactCategory: selectedImpactCategory,
          }}
        />
      </h4>
      <ImpactRatingProportionsChart
        dashboardType="product"
        impactCategory={impactCategory}
        impactRatingInfos={impactRatingInfos}
        proportions={getProportions(impactRatingProportions, impactCategory)}
      />
    </div>
  );
}

export function getProportions(
  impactRatingProportions: {
    ghg: ProportionsWithProductCount;
    landUse: ProportionsWithProductCount;
    waterUse: ProportionsWithProductCount;
  },
  impactCategory: ImpactCategory
) {
  if (impactCategory === ImpactCategory.GHG) {
    return impactRatingProportions.ghg;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    return impactRatingProportions.landUse;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    return impactRatingProportions.waterUse;
  } else {
    assertNever(impactCategory, "Invalid impact category");
  }
}
