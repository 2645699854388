import classNames from "classnames";
import gql from "graphql-tag";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import {
  BillingInterval,
  Currency,
  Tier,
} from "../../__generated__/globalTypes";
import assertNever from "../../util/assertNever";
import { PricePerBillingInterval } from "../../util/currencyFormatting";
import useMutation from "../graphql/useMutation";
import { useOrganization } from "../organizations/OrganizationProvider";
import ContactUsButton from "../recipes/ContactUsButton";
import ActionModal from "../utils/ActionModal";
import { PrimaryButton, SecondaryButton } from "../utils/Button";
import QuestionMarkTooltipOverlay from "../utils/QuestionMarkTooltipOverlay";
import RadioToggleButtonGroup from "../utils/RadioToggleButtonGroup";
import useLoading from "../utils/useLoading";
import { FilledCheckmark } from "../utils/Vectors";
import BillingIntervalToggleLabel from "./BillingIntervalToggleLabel";
import { CurrencySelect } from "./CurrencySelection";
import {
  Plans_OrganizationProduct as OrganizationProduct,
  Plans_RecipeAllowanceProduct as RecipeAllowanceProduct,
  Plans_SendUserMessage as SendUserMessage,
  Plans_SendUserMessageVariables as SendUserMessageVariables,
} from "./Plans.graphql";
import { PlansPage_TierProduct as TierProduct } from "./PlansPage.graphql";
import {
  AwaitingPaymentConfirmationBanner,
  ChangePlanPrompt,
} from "./PlansPageBanner";
import { RecipeAllowanceProductSelect } from "./RecipeAllowanceProductSelect";
import {
  getSellingPoints,
  SellingPoint,
  sellingPoints,
  sellingPointTexts,
} from "./sellingPoints";
import { priceForBillingInterval } from "./utils";
import "./Plans.css";

type Plan = {
  active: boolean;
  callToAction: React.ReactNode;
  name: React.ReactNode;
  price: React.ReactNode;
  recipeSelect: React.ReactNode;
  sellingPoints: Array<SellingPoint>;
  tagline: React.ReactNode;
  tier: Tier;
};

interface PlansProps {
  billingInterval: BillingInterval;
  currency: Currency;
  onRecipeAllowanceProductSelect: (
    tierProduct: TierProduct,
    recipeAllowanceProduct: RecipeAllowanceProduct
  ) => void;
  onUpgradePlan: (selectedTierProduct: TierProduct) => void;
  organizationProducts: Array<OrganizationProduct>;
  selectedRecipeAllowanceProducts: Map<TierProduct, RecipeAllowanceProduct>;
  setBillingInterval: (billingInterval: BillingInterval) => void;
  setCurrency: (currency: Currency) => void;
  showAwaitingPaymentConfirmationBanner: boolean;
  tierProductsByTier: Map<Tier, TierProduct>;
}

export default function Plans(props: PlansProps) {
  const {
    billingInterval,
    currency,
    onRecipeAllowanceProductSelect,
    onUpgradePlan,
    organizationProducts,
    selectedRecipeAllowanceProducts,
    setBillingInterval,
    setCurrency,
    showAwaitingPaymentConfirmationBanner,
    tierProductsByTier,
  } = props;

  const [{ productTier: activeTier }] = useOrganization();

  const organizationProductsByTier = new Map(
    organizationProducts.map((organizationProduct) => [
      organizationProduct.tier,
      organizationProduct,
    ])
  );

  const planPrice = (tier: Tier): number => {
    const tierProduct = tierProductsByTier.get(tier)!;
    return priceForBillingInterval({
      billingInterval,
      currency,
      prices: tierProduct.price,
    });
  };

  const [showLabellingCriteriaModal, setShowLabellingCriteriaModal] =
    useState<boolean>(false);

  const plans: Array<Plan> = [
    {
      tier: Tier.FOOD_SERVICE_FREE,
      callToAction: null,
      name: organizationProductsByTier.get(Tier.FOOD_SERVICE_FREE)!.name,
      price: (
        <FormattedMessage
          id="components/subscriptions/Plans:freeTierPrice"
          defaultMessage="Free"
        />
      ),
      recipeSelect: (
        <p>
          <FormattedMessage
            id="components/subscriptions/Plans:freeTierRecipeAllowance"
            defaultMessage="5 Products"
          />
        </p>
      ),
      tagline: organizationProductsByTier.get(Tier.FOOD_SERVICE_FREE)!.tagline,
      sellingPoints: getSellingPoints(
        Tier.FOOD_SERVICE_FREE,
        organizationProductsByTier.get(Tier.FOOD_SERVICE_FREE)!.labels
      ),
      active: activeTier === Tier.FOOD_SERVICE_FREE,
    },
    {
      tier: Tier.FOOD_SERVICE_BASIC,
      callToAction: (
        <SelectPlanButton
          onSelectPlan={() =>
            onUpgradePlan(tierProductsByTier.get(Tier.FOOD_SERVICE_BASIC)!)
          }
        />
      ),
      name: organizationProductsByTier.get(Tier.FOOD_SERVICE_BASIC)!.name,
      price: planPrice(Tier.FOOD_SERVICE_BASIC),
      recipeSelect: (
        <RecipeAllowanceProductSelect
          billingInterval={billingInterval}
          currency={currency}
          onChange={(recipeAllowanceProduct: RecipeAllowanceProduct) =>
            onRecipeAllowanceProductSelect(
              tierProductsByTier.get(Tier.FOOD_SERVICE_BASIC)!,
              recipeAllowanceProduct
            )
          }
          value={
            selectedRecipeAllowanceProducts.get(
              tierProductsByTier.get(Tier.FOOD_SERVICE_BASIC)!
            )!
          }
          recipeAllowanceProducts={
            tierProductsByTier.get(Tier.FOOD_SERVICE_BASIC)!
              .recipeAllowanceProducts
          }
        />
      ),
      tagline: organizationProductsByTier.get(Tier.FOOD_SERVICE_BASIC)!.tagline,
      sellingPoints: getSellingPoints(
        Tier.FOOD_SERVICE_BASIC,
        organizationProductsByTier.get(Tier.FOOD_SERVICE_BASIC)!.labels
      ),
      active: activeTier === Tier.FOOD_SERVICE_BASIC,
    },
    {
      tier: Tier.FOOD_SERVICE_PREMIUM,
      callToAction: (
        <SelectPlanButton
          onSelectPlan={() => setShowLabellingCriteriaModal(true)}
        />
      ),
      name: organizationProductsByTier.get(Tier.FOOD_SERVICE_PREMIUM)!.name,
      price: planPrice(Tier.FOOD_SERVICE_PREMIUM),
      recipeSelect: (
        <RecipeAllowanceProductSelect
          billingInterval={billingInterval}
          currency={currency}
          onChange={(recipeAllowanceProduct: RecipeAllowanceProduct) =>
            onRecipeAllowanceProductSelect(
              tierProductsByTier.get(Tier.FOOD_SERVICE_PREMIUM)!,
              recipeAllowanceProduct
            )
          }
          value={
            selectedRecipeAllowanceProducts.get(
              tierProductsByTier.get(Tier.FOOD_SERVICE_PREMIUM)!
            )!
          }
          recipeAllowanceProducts={
            tierProductsByTier.get(Tier.FOOD_SERVICE_PREMIUM)!
              .recipeAllowanceProducts
          }
        />
      ),
      tagline: organizationProductsByTier.get(Tier.FOOD_SERVICE_PREMIUM)!
        .tagline,
      sellingPoints: getSellingPoints(
        Tier.FOOD_SERVICE_PREMIUM,
        organizationProductsByTier.get(Tier.FOOD_SERVICE_PREMIUM)!.labels
      ),
      active: activeTier === Tier.FOOD_SERVICE_PREMIUM,
    },
    {
      tier: Tier.FOOD_SERVICE_ENTERPRISE,
      callToAction: (
        <ContactUsButton
          buttonType="secondary"
          className={callToActionClassName}
          messageSubject="Enterprise tier enquiry"
          modalSubject={
            <FormattedMessage
              id="components/subscriptions/Plans:contactUsModalSubject"
              defaultMessage="Ask about a plan"
            />
          }
        >
          <FormattedMessage
            id="components/subscriptions/Plans:contactUsButton"
            defaultMessage="Contact us"
          />
        </ContactUsButton>
      ),
      name: organizationProductsByTier.get(Tier.FOOD_SERVICE_ENTERPRISE)!.name,
      price: (
        <FormattedMessage
          id="components/subscriptions/Plans:enterpriseTierPrice"
          defaultMessage="Let's talk"
        />
      ),
      recipeSelect: null,
      tagline: organizationProductsByTier.get(Tier.FOOD_SERVICE_ENTERPRISE)!
        .tagline,
      sellingPoints: getSellingPoints(Tier.FOOD_SERVICE_ENTERPRISE, true),
      active: activeTier === Tier.FOOD_SERVICE_ENTERPRISE,
    },
  ];

  return (
    <>
      <div className="plans pb-5">
        <div className="w-100 d-flex justify-content-end mb-4 flex-row">
          <h6 className="medium-font mx-4 my-auto">
            <FormattedMessage
              id="components/subscriptions/PlansPage:payments"
              defaultMessage="Payments"
            />
          </h6>
          <BillingIntervalToggle
            billingInterval={billingInterval}
            setBillingInterval={setBillingInterval}
          />
          <h6 className="medium-font mx-4 my-auto">
            <FormattedMessage
              id="components/subscriptions/PlansPage:pricesIn"
              defaultMessage="Prices in"
            />
          </h6>
          <div className="currency-select-container">
            <CurrencySelect
              onChange={setCurrency}
              value={currency}
            ></CurrencySelect>
          </div>
        </div>
        {showAwaitingPaymentConfirmationBanner && (
          <PlansTableRow
            left={null}
            right={<AwaitingPaymentConfirmationBanner className="w-100 mb-4" />}
            rowType={{ type: "header" }}
          />
        )}
        <PlansTableRow
          left={
            <div className="our-plans-section">
              <h2>
                <FormattedMessage
                  id="components/subscriptions/Plans:plansTableHeaderTitle"
                  defaultMessage="Our plans"
                />
              </h2>
              <p>
                <FormattedMessage
                  id="components/subscriptions/Plans:plansTableHeaderTagline"
                  defaultMessage="Put our robust database and platform to work at a price that works
              for you."
                />
              </p>
              {alreadySubscribed(plans) && (
                <ChangePlanPrompt className="w-100 mb-4 py-1 px-1" />
              )}
            </div>
          }
          right={
            <PlansTableHeader
              plans={plans}
              PricePerBillingInterval={({ pence }: { pence: number }) => (
                <PricePerBillingInterval
                  billingInterval={billingInterval}
                  currency={currency}
                  hasAsterisk
                  pence={pence}
                />
              )}
            />
          }
          rowType={{ type: "header" }}
        />
        <PlansTable plans={plans} />
        <div className="small mt-3 pt-4">
          <FormattedMessage
            id="components/subscriptions/Plans:plansTableFootnote"
            defaultMessage="*Price is exclusive of any applicable taxes, which will be finalised at the time of payment."
          />
        </div>
      </div>
      <LabellingCriteriaModal
        onChangePlan={() => setShowLabellingCriteriaModal(false)}
        onHide={() => setShowLabellingCriteriaModal(false)}
        onProceed={() =>
          onUpgradePlan(tierProductsByTier.get(Tier.FOOD_SERVICE_PREMIUM)!)
        }
        show={showLabellingCriteriaModal}
      />
    </>
  );
}

interface PlansTableProps {
  plans: Array<Plan>;
}

function PlansTable(props: PlansTableProps) {
  const { plans } = props;

  return (
    <>
      {sellingPoints.map((sellingPoint, sellingPointIndex: number) => (
        <PlansTableRow
          key={sellingPointIndex}
          left={<PlansTableSellingPoint sellingPoint={sellingPoint} />}
          right={
            <div className={rowRightHandContainerClassName}>
              <div className="row h-100 align-items-center">
                {plans.map((plan: Plan, planIndex: number) => (
                  <div
                    className={classNames("col h-100 p-3", {
                      "active-plan-middle": plan.active,
                      "active-plan-bottom":
                        plan.active &&
                        sellingPointIndex === sellingPoints.length - 1,
                    })}
                    key={planIndex}
                  >
                    {plan.sellingPoints.includes(sellingPoint) && (
                      <FilledCheckmark width="24px" />
                    )}
                  </div>
                ))}
              </div>
            </div>
          }
          rowType={{ type: "body", index: sellingPointIndex }}
        />
      ))}
    </>
  );
}

interface PlansTableHeaderRowProps {
  cellClassName?: string;
  cells: Array<{ node: React.ReactNode; active: boolean }>;
  renderCell: (cell: React.ReactNode) => React.ReactNode;
  rowClassName?: string;
  position?: "top" | "bottom" | "middle";
}

function PlansTableHeaderRow(props: PlansTableHeaderRowProps) {
  const {
    cellClassName,
    cells,
    renderCell,
    rowClassName,
    position = "middle",
  } = props;

  const rowClasses = "row justify-content-around";
  const cellClasses = "col";

  return (
    <div className={classNames(rowClasses, rowClassName)}>
      {cells.map(
        (cell: { node: React.ReactNode; active: boolean }, index: number) => (
          <div
            className={classNames(cellClasses, cellClassName, {
              "active-plan-middle": cell.active,
              "active-plan-top": cell.active && position === "top",
            })}
            key={index}
          >
            {renderCell(cell.node)}
          </div>
        )
      )}
    </div>
  );
}

interface PlansTableHeaderProps {
  plans: Array<Plan>;
  PricePerBillingInterval: ({ pence }: { pence: number }) => JSX.Element;
}

function PlansTableHeader(props: PlansTableHeaderProps) {
  const { plans, PricePerBillingInterval } = props;

  function extractPlanAttributeArray(
    key: keyof Plan
  ): Array<{ node: React.ReactNode; active: boolean }> {
    return plans.map((plan) => {
      return { node: plan[key], active: plan["active"] };
    });
  }

  return (
    <div className={classNames("h-100", rowRightHandContainerClassName)}>
      <PlansTableHeaderRow
        cells={extractPlanAttributeArray("active")}
        renderCell={(active) => (
          <div className="my-plan-header-row">{active ? <MyPlan /> : null}</div>
        )}
        position="top"
      />
      <PlansTableHeaderRow
        cells={extractPlanAttributeArray("name")}
        renderCell={(name) => <h6 className="semi-bold-font">{name}</h6>}
      />
      <PlansTableHeaderRow
        cells={extractPlanAttributeArray("tagline")}
        renderCell={(tagline) => (
          <small>
            <p>{tagline}</p>
          </small>
        )}
      />
      <PlansTableHeaderRow
        cells={extractPlanAttributeArray("price")}
        renderCell={(price) =>
          typeof price === "number" ? (
            <h4>
              <PricePerBillingInterval pence={price} />
            </h4>
          ) : (
            <h4 className="mb-3">{price}</h4>
          )
        }
      />
      <PlansTableHeaderRow
        cells={extractPlanAttributeArray("recipeSelect")}
        renderCell={(recipeSelect) => {
          const marginBottom = alreadySubscribed(plans) ? "mb-4" : "mb-2";
          return <div className={marginBottom}>{recipeSelect}</div>;
        }}
      />
      {!alreadySubscribed(plans) && (
        <PlansTableHeaderRow
          cells={extractPlanAttributeArray("callToAction")}
          renderCell={(callToAction) => (
            <div className="mb-4">{callToAction}</div>
          )}
        />
      )}
    </div>
  );
}

interface PlansTableSellingPointProps {
  sellingPoint: SellingPoint;
}

function PlansTableSellingPoint(props: PlansTableSellingPointProps) {
  const { sellingPoint } = props;

  const sellingPointText = sellingPointTexts[sellingPoint];

  return (
    <div className="py-3">
      <small className="mr-2">{sellingPointText.name}</small>
      <QuestionMarkTooltipOverlay>
        {sellingPointText.description}
      </QuestionMarkTooltipOverlay>
    </div>
  );
}

interface SelectPlanButtonProps {
  onSelectPlan: () => void;
}

function SelectPlanButton(props: SelectPlanButtonProps) {
  const { onSelectPlan } = props;

  return (
    <PrimaryButton className={callToActionClassName} onClick={onSelectPlan}>
      <FormattedMessage
        id="components/subscriptions/Plans:selectPlanButton"
        defaultMessage="Choose plan"
      />
    </PrimaryButton>
  );
}

interface PlansTableRowProps {
  left: React.ReactNode;
  right: React.ReactNode;
  rowType: { type: "header" } | { type: "body"; index: number };
}

function PlansTableRow(props: PlansTableRowProps) {
  const { left, right, rowType } = props;

  const rowClassName =
    rowType.type === "body"
      ? classNames({
          "rounded-top": rowType.index === 0,
          "rounded-bottom": rowType.index === sellingPoints.length - 1,
        })
      : "";

  const rowStyle =
    rowType.type === "body"
      ? { backgroundColor: rowType.index % 2 === 0 ? "#E9ECEF" : "white" }
      : {};

  return (
    <div
      className={classNames("d-flex flex-row", rowClassName)}
      style={rowStyle}
    >
      <div
        className={classNames("col-3", { "pl-0": rowType.type === "header" })}
      >
        {left}
      </div>
      <div className="d-flex w-100">{right}</div>
    </div>
  );
}

function MyPlan() {
  return (
    <div className="my-plan Plans__pill">
      <small className="semi-bold-font">
        <FormattedMessage
          id="components/subscriptions/Plans:myPlan"
          defaultMessage="My plan"
        />
      </small>
    </div>
  );
}

type LabellingCriteriaModalState =
  | "whoCanGenerateLabels"
  | "enquireAboutSubscription";

interface LabellingCriteriaModalProps {
  onChangePlan: () => void;
  onHide: () => void;
  onProceed: () => void;
  show: boolean;
}

function LabellingCriteriaModal(props: LabellingCriteriaModalProps) {
  const { onChangePlan, onHide, onProceed, show } = props;

  const [state, setState] = useState<LabellingCriteriaModalState>(
    "whoCanGenerateLabels"
  );

  const [sendUserMessage] = useMutation<
    SendUserMessage,
    SendUserMessageVariables
  >(sendUserMessageMutation);

  const [handleEnquireAboutSubscription, enquiringAboutSubscription] =
    useLoading(async function () {
      await sendUserMessage({
        variables: {
          input: {
            subject: "Carbon Labelling Subscription Inquiry",
            body: "I want a premium subscription but my products use packaging.",
          },
        },
      });

      setState("enquireAboutSubscription");
    });

  function handleModalClose() {
    if (state === "enquireAboutSubscription") {
      setState("whoCanGenerateLabels");
    }
  }

  function BackToPlansButton() {
    return (
      <SecondaryButton onClick={onChangePlan}>
        <FormattedMessage
          id="components/subscriptions/Plans:labellingCriteriaModalBackToPlans"
          defaultMessage="Back to plans"
        />
      </SecondaryButton>
    );
  }

  function ModalTitle() {
    if (state === "whoCanGenerateLabels") {
      return (
        <FormattedMessage
          id="components/subscriptions/Plans:labellingCriteriaModalTitleWhoCanGenerate"
          defaultMessage="Are you either:"
        />
      );
    } else if (state === "enquireAboutSubscription") {
      return (
        <FormattedMessage
          id="components/subscriptions/Plans:labellingCriteriaModalTitleWeWillBeInTouch"
          defaultMessage="We will be in touch shortly via email to start tailoring our carbon labels to your food specifications!"
        />
      );
    } else {
      assertNever(state, "unexpected modal state");
    }
  }

  function ModalBody() {
    if (state === "whoCanGenerateLabels") {
      return (
        <div className="mt-n3">
          <FormattedMessage
            id="components/subscriptions/Plans:labellingCriteriaModalBody"
            defaultMessage="<ul>
                <li>A food service organisation (e.g. restaurant, caterer, or cafe), or</li>
                <li>An organisation that makes packaged meals (such as sandwiches) that are served in food service environments</li>
              </ul>"
            values={{
              ul: (chunks: React.ReactNode) => <ul>{chunks}</ul>,
              li: (chunks: React.ReactNode) => <li>{chunks}</li>,
            }}
          />
        </div>
      );
    } else if (state === "enquireAboutSubscription") {
      return (
        <div>
          <p className="medium-font">
            <FormattedMessage
              id="components/subscriptions/Plans:labellingCriteriaModalInquirySuccessfulFAQTitle"
              defaultMessage="Why do I need to talk to your team?"
            />
          </p>
          <p>
            <FormattedMessage
              id="components/subscriptions/Plans:labellingCriteriaModalInquirySuccessfulFAQBody"
              defaultMessage="We need to work with you to understand more about your processes to ensure our carbon labels are accurate for your food."
            />
          </p>
        </div>
      );
    } else {
      assertNever(state, "unexpected modal state");
    }
  }

  function ModalControls() {
    if (state === "whoCanGenerateLabels") {
      return (
        <>
          <BackToPlansButton />
          <div>
            <PrimaryButton onClick={onProceed}>
              <FormattedMessage
                id="components/subscriptions/Plans:labellingCriteriaModalProceedWithPayment"
                defaultMessage="Yes"
              />
            </PrimaryButton>
            <PrimaryButton
              className="ml-2"
              onClick={handleEnquireAboutSubscription}
              loading={enquiringAboutSubscription}
            >
              <FormattedMessage
                id="components/subscriptions/Plans:labellingCriteriaModalEnquireAboutSubscribing"
                defaultMessage="No"
              />
            </PrimaryButton>
          </div>
        </>
      );
    } else if (state === "enquireAboutSubscription") {
      return (
        <>
          <div></div>
          <PrimaryButton onClick={onHide}>
            <FormattedMessage
              id="components/subscriptions/Plans:labellingCriteriaModalCompleteInquiry"
              defaultMessage="Great!"
            />
          </PrimaryButton>
        </>
      );
    } else {
      assertNever(state, "unexpected modal state");
    }
  }

  return (
    <ActionModal onExited={handleModalClose} show={show} title={<ModalTitle />}>
      <ActionModal.Body>
        <ModalBody />
      </ActionModal.Body>

      <ActionModal.Footer>
        <div className="d-flex w-100 justify-content-between">
          <ModalControls />
        </div>
      </ActionModal.Footer>
    </ActionModal>
  );
}

interface BillingIntervalToggleProps {
  billingInterval: BillingInterval;
  setBillingInterval: (billingInterval: BillingInterval) => void;
}

function BillingIntervalToggle(props: BillingIntervalToggleProps) {
  const { billingInterval, setBillingInterval } = props;

  return (
    <RadioToggleButtonGroup
      buttonClassName="billing-interval-toggle-button d-flex align-items-center justify-content-center"
      className="billing-interval-toggle"
      onChange={setBillingInterval}
      options={[
        {
          key: BillingInterval.MONTH.toString(),
          label: (
            <BillingIntervalToggleLabel
              billingInterval={BillingInterval.MONTH}
            />
          ),
          value: BillingInterval.MONTH,
        },
        {
          key: BillingInterval.YEAR.toString(),
          label: (
            <BillingIntervalToggleLabel
              billingInterval={BillingInterval.YEAR}
            />
          ),
          value: BillingInterval.YEAR,
        },
      ]}
      size="sm"
      value={billingInterval}
    />
  );
}

function alreadySubscribed(plans: Array<Plan>): boolean {
  return (
    plans.find((plan: Plan) => plan.active)!.tier !== Tier.FOOD_SERVICE_FREE
  );
}

const callToActionClassName = "w-100 h-100";
const rowRightHandContainerClassName = "container mx-0 px-0 w-100 text-center";

Plans.fragments = {
  organizationProduct: gql`
    fragment Plans_OrganizationProduct on OrganizationProduct {
      labels
      name
      tagline
      tier
    }
  `,

  recipeAllowanceProduct: gql`
    fragment Plans_RecipeAllowanceProduct on RecipeAllowanceProduct {
      ...RecipeAllowanceProductSelect_RecipeAllowanceProduct
    }

    ${RecipeAllowanceProductSelect.fragments.recipeAllowanceProduct}
  `,
};

const sendUserMessageMutation = gql`
  mutation Plans_SendUserMessage($input: SendUserMessageInput!) {
    sendUserMessage(input: $input) {
      success
    }
  }
`;
