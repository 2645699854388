import { Chart as Chartjs, TooltipModel } from "chart.js";
import classNames from "classnames";
import gql from "graphql-tag";
import ReactDOM from "react-dom";
import { useIntl } from "react-intl";

import { ImpactRating } from "../../__generated__/globalTypes";
import {
  EffectTypeAbsoluteValue,
  useEffectTypeForImpactCategoryPerFunctionalUnit,
} from "../../domain/EffectType";
import { FunctionalUnit } from "../../domain/functionalUnits";
import { ImpactCategory } from "../../domain/impactCategories";
import { useImpactRatingToLongName } from "../../domain/impactRatings";
import { useDataQualityScore } from "../../services/useOrganizationFeatures";
import assertNever from "../../util/assertNever";
import * as comparators from "../../util/comparators";
import { Comparator } from "../../util/comparators";
import sort from "../../util/sort";
import DoughnutChart from "../graphs/DoughnutChart";
import ImpactRatingDisplay from "../impacts/ImpactRatingDisplay";
import Number from "../typography/Number";
import impactCategoryDataQualityScore from "../utils/DQS/impactCategoryDataQualityScore";
import useDataQualityScoreToRatingString from "../utils/DQS/useDataQualityScoreToRatingString";
import { IngredientImpactChart_RecipeIngredientImpact as IngredientImpact } from "./IngredientImpactChart.graphql";

interface IngredientImpactChartProps {
  functionalUnit: FunctionalUnit;
  impactCategory: ImpactCategory;
  ingredients: Array<IngredientImpact>;
  ingredientIdsToColor: Map<number, string>;
  perPortionEffectType: EffectTypeAbsoluteValue;
  productWeightKgPerPortion: number;
  totalIngredientImpactMagnitude: number;
}

export default function IngredientImpactChart(
  props: IngredientImpactChartProps
) {
  const {
    functionalUnit,
    impactCategory,
    ingredients,
    ingredientIdsToColor,
    perPortionEffectType,
    productWeightKgPerPortion,
    totalIngredientImpactMagnitude,
  } = props;

  const dataQualityScoreToRatingString = useDataQualityScoreToRatingString();
  const intl = useIntl();
  const hasFeatureDataQualityScore = useDataQualityScore();
  const impactRatingToLongName = useImpactRatingToLongName;

  const comparator: Comparator<IngredientImpact> = comparators.map(
    (ingredientImpact: IngredientImpact) =>
      perPortionEffectType.get(ingredientImpact) ?? 0,
    comparators.reversed(comparators.number)
  );

  const sortedIngredients = sort(ingredients, comparator);

  const functionalUnitEffectType =
    useEffectTypeForImpactCategoryPerFunctionalUnit(
      impactCategory,
      functionalUnit
    );

  const chartData = {
    dataset: {
      data: sortedIngredients.map(
        (ingredient) => perPortionEffectType.get(ingredient) ?? 0
      ),
      backgroundColor: sortedIngredients.map((ingredient) =>
        ingredientIdsToColor.get(ingredient.ingredientId)
      ),
      borderWidth: 2,
    },
    labels: sortedIngredients.map((ingredient) => ingredient.name),
  };

  const impactPerFunctionalUnit = (ingredientWithImpact: IngredientImpact) => {
    const impactPerPortion =
      perPortionEffectType.get(ingredientWithImpact) ?? 0;
    if (functionalUnit === FunctionalUnit.KG) {
      return impactPerPortion / productWeightKgPerPortion;
    } else if (functionalUnit === FunctionalUnit.PORTION) {
      return impactPerPortion;
    } else {
      assertNever(functionalUnit, "Unsupported FunctionalUnit");
    }
  };

  const otherData = sortedIngredients.map((ingredientWithImpact) => {
    const dqs = ingredientWithImpact.dataQualityScore
      ? impactCategoryDataQualityScore(
          ingredientWithImpact.dataQualityScore,
          impactCategory
        )
      : null;
    const dqsString = intl.formatMessage({
      id: "components/recipes/IngredientImpactChart:dqs",
      defaultMessage: "DQS",
    });
    const unavailableString = intl.formatMessage({
      id: "components/recipes/IngredientImpactChart:unavailable",
      defaultMessage: "Unavailable",
    });

    const formatRating = (impactRating: ImpactRating | null) =>
      impactRating === null
        ? null
        : {
            value: impactRating,
            text: impactRatingToLongName(impactRating),
          };

    const rating = () => {
      if (impactCategory === ImpactCategory.GHG) {
        return formatRating(ingredientWithImpact.impactRatingGhg);
      } else if (impactCategory === ImpactCategory.LAND_USE) {
        return formatRating(ingredientWithImpact.impactRatingLandUse);
      } else if (impactCategory === ImpactCategory.WATER_USE) {
        return formatRating(ingredientWithImpact.impactRatingWaterUse);
      } else {
        assertNever(impactCategory, "Unsupported impact category");
      }
    };

    return {
      rating: rating(),
      percent: (
        ((perPortionEffectType.get(ingredientWithImpact) ?? 0) /
          totalIngredientImpactMagnitude) *
        100
      ).toFixed(0),
      value: impactPerFunctionalUnit(ingredientWithImpact),
      dataQualityScore: {
        ratingText: dqs
          ? dataQualityScoreToRatingString(dqs) + " " + dqsString
          : dqsString + " " + unavailableString,
        magnitudeText: dqs ? dqs.toFixed(1) : "",
      },
      showDataQualityScore: hasFeatureDataQualityScore,
    };
  });

  return (
    <DoughnutChart
      chartData={chartData}
      effectType={functionalUnitEffectType}
      externalTooltipHandler={externalTooltipHandler}
      formatValue={() => ""}
      otherData={otherData}
      pie={true}
    />
  );
}

IngredientImpactChart.fragments = {
  recipeIngredientImpact: gql`
    fragment IngredientImpactChart_RecipeIngredientImpact on RecipeIngredientImpact {
      dataQualityScore {
        ghg
        land_use
        water_use
      }
      effects {
        ghgPerKg
        ghgPerRootRecipeServing
        landUsePerKg
        landUsePerRootRecipeServing
        waterUsePerKg
        waterUsePerRootRecipeServing
      }
      impactRatingGhg
      impactRatingLandUse
      impactRatingWaterUse
      ingredientId
      name
    }
  `,
};

function ImpactByIngredientToolTip(props: {
  dataQualityScore: { magnitudeText: string; ratingText: string };
  percent: string;
  rating: string | null;
  ratingString: string | null;
  name: string;
  impact: string;
  showDataQualityScore: boolean;
  unit: string;
}) {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      {props.rating && props.ratingString && (
        <ImpactRatingDisplay
          value={ImpactRating[props.rating.toUpperCase() as ImpactRating]}
          text={props.ratingString}
        />
      )}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Number>
          {props.percent.toString()}
          {"%"}
        </Number>
        <div>{props.name}</div>
      </div>
      <div>
        <div>
          <span className="medium-font">{props.impact} </span>
          <span className="small">{props.unit}</span>
        </div>
        {props.showDataQualityScore && (
          <div className="small">
            <span
              className={classNames({
                "medium-font": props.dataQualityScore.magnitudeText,
              })}
            >
              {props.dataQualityScore.ratingText}
            </span>
            {props.dataQualityScore.magnitudeText && (
              <span> | {props.dataQualityScore.magnitudeText}</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function externalTooltipHandler(context: {
  chart: Chartjs;
  tooltip: TooltipModel<any>;
}): void {
  const { chart, tooltip } = context;
  let tooltipElement = chart.canvas.parentNode!.querySelector("div");
  if (!tooltipElement) {
    tooltipElement = document.createElement("div");
    tooltipElement.classList.add("custom-tooltip");
    chart.canvas.parentNode!.appendChild(tooltipElement);
  }

  if (tooltip.opacity === 0) {
    tooltipElement.style.opacity = "0";
    return;
  } else {
    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    tooltipElement.style.opacity = "1";
    tooltipElement.style.left = positionX + tooltip.caretX + "px";
    tooltipElement.style.top =
      positionY + tooltip.caretY - tooltip.height + "px";
  }

  const rating =
    tooltip.beforeBody.length > 0 ? tooltip.beforeBody[0].toUpperCase() : null;

  const ratingString =
    tooltip.beforeBody.length > 0 ? tooltip.beforeBody[1] : null;

  ReactDOM.render(
    <ImpactByIngredientToolTip
      rating={rating}
      ratingString={ratingString}
      percent={tooltip.afterBody[0]}
      name={tooltip.body[0].lines[0]}
      impact={tooltip.footer[0]}
      unit={tooltip.footer[1]}
      dataQualityScore={{
        ratingText: tooltip.footer[2],
        magnitudeText: tooltip.footer[3],
      }}
      showDataQualityScore={tooltip.footer[4] === "true"}
    />,
    tooltipElement
  );
}
