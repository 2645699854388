import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useCallbackOne } from "use-memo-one";

import { DataStore, useDataStore } from "../../data-store";
import { DataQualityScore } from "../../domain/DataQualityScore";
import Effects from "../../domain/Effects";
import { Status } from "../../util/statuses";
import { usePages } from "../pages";
import StatusDisplay from "../StatusDisplay";
import Checkbox from "../utils/Checkbox";
import usePromise from "../utils/usePromise";
import "./CalculationTable.css";
import useToggle from "../utils/useToggle";

export interface CalculationTableRow {
  amount_kg: number;
  consumption_location_str: string;
  data_quality_score: DataQualityScore;
  geographic_data_quality_score: DataQualityScore;
  technology_data_quality_score: DataQualityScore;
  temporal_data_quality_score: DataQualityScore;
  economic_allocation_str: string;
  effects: Effects;
  item_type: string;
  link?: string;
  loss_str: string;
  method: string;
  multiplier_str: string;
  name: string;
  notes: Array<string>;
  errors: Array<string>;
  num_servings: number;
  processing_location_str: string;
  source_location_str: string;
  system_boundary_str: string;
  primary_protein_str: string;
  is_vegan_str: string;
  is_vegetarian_str: string;
  dietary_categories: Array<string>;
  child_rows: Array<CalculationTableRow>;
}

export default function CalculationTablePage() {
  const pages = usePages();

  const { subjectId, subjectType } = pages.CalculationTable.useQueryParams();

  const calculationTableStatus = useCalculationTable(subjectId, subjectType);

  const [showEffectsPerAmount, setShowEffectsPerAmount] =
    useState<boolean>(true);
  const [showEffectsPerKg, setShowEffectsPerKg] = useState<boolean>(false);
  const [showEffectsPerServing, setShowEffectsPerServing] =
    useState<boolean>(false);

  // Hack: increment this each time "Expand all" is clicked to trigger the useEffect function in a Row.
  const [expandAll, setExpandAll] = useState<number>(0);

  return (
    <StatusDisplay status={calculationTableStatus}>
      {(calculationTableRow) => {
        return (
          <div className="small p-2">
            <div className="d-flex CalculationTable__Controls flex-row pb-2">
              <span
                className="action-link mr-4"
                onClick={() => setExpandAll(expandAll + 1)}
              >
                Expand all
              </span>
              <Checkbox
                containerClassName="mr-4"
                checked={showEffectsPerAmount}
                onChange={() => setShowEffectsPerAmount(!showEffectsPerAmount)}
                label={"Effects per actual amount"}
              />
              <Checkbox
                containerClassName="mr-4"
                checked={showEffectsPerKg}
                onChange={() => setShowEffectsPerKg(!showEffectsPerKg)}
                label={"Effects per 1kg"}
              />
              <Checkbox
                disabled={calculationTableRow.num_servings === 1}
                checked={showEffectsPerServing}
                onChange={() =>
                  setShowEffectsPerServing(!showEffectsPerServing)
                }
                label={"Effects per serving"}
              />
            </div>
            <CalculationTable
              expandAll={expandAll}
              calculationTableRow={calculationTableRow}
              showEffectsPerAmount={showEffectsPerAmount}
              showEffectsPerKg={showEffectsPerKg}
              showEffectsPerServing={showEffectsPerServing}
            />
          </div>
        );
      }}
    </StatusDisplay>
  );
}

function useCalculationTable(
  subjectId: string,
  subjectType: string
): Status<Awaited<ReturnType<DataStore["fetchCalculationTable"]>>> {
  const { fetchCalculationTable } = useDataStore();
  const queryParametersString = useLocation().search;

  const promiseCallback = useCallbackOne(() => {
    const params = new URLSearchParams(queryParametersString);
    return fetchCalculationTable(subjectId, subjectType, params);
  }, [subjectId, subjectType, queryParametersString, fetchCalculationTable]);

  const [status] = usePromise(promiseCallback);

  return status;
}

interface CalculationTableProps {
  calculationTableRow: CalculationTableRow;
  expandAll: number;
  showEffectsPerAmount: boolean;
  showEffectsPerKg: boolean;
  showEffectsPerServing: boolean;
}

function CalculationTable(props: CalculationTableProps) {
  const {
    calculationTableRow,
    expandAll,
    showEffectsPerAmount,
    showEffectsPerKg,
    showEffectsPerServing,
  } = props;

  const [ghgPerAmountExpanded, setGhgPerAmountExpanded] =
    useState<boolean>(false);
  const [landUsePerAmountExpanded, setLandUsePerAmountExpanded] =
    useState<boolean>(false);
  const [waterUsePerAmountExpanded, setWaterUsePerAmountExpanded] =
    useState<boolean>(false);
  const [ghgPerKgExpanded, setGhgPerKgExpanded] = useState<boolean>(false);
  const [landUsePerKgExpanded, setLandUsePerKgExpanded] =
    useState<boolean>(false);
  const [waterUsePerKgExpanded, setWaterUsePerKgExpanded] =
    useState<boolean>(false);
  const [ghgPerServingExpanded, setGhgPerServingExpanded] =
    useState<boolean>(false);
  const [dataQualityScoreGhgExpanded, toggleDataQualityScoreGhgExpanded] =
    useToggle(false);
  const [dataQualityScoreLandExpanded, toggleDataQualityScoreLandExpanded] =
    useToggle(false);
  const [dataQualityScoreWaterExpanded, toggleDataQualityScoreWaterExpanded] =
    useToggle(false);

  return (
    <Table className="CalculationTable">
      <thead>
        <tr>
          <th>Name</th>
          <th>[+/-]</th>
          <th>Type</th>
          <th>Method</th>
          <th>Sourcing</th>
          <th>Processing</th>
          <th>Consumption</th>
          <th>System boundary</th>
          <th>Multiplier</th>
          {showEffectsPerAmount && (
            <>
              <th>Amount (kg)</th>
              <th>
                GHG{" "}
                <span
                  onClick={() => setGhgPerAmountExpanded(!ghgPerAmountExpanded)}
                  style={{ cursor: "pointer" }}
                >
                  {ghgPerAmountExpanded ? "[-]" : "[+]"}
                </span>
              </th>
              {ghgPerAmountExpanded && (
                <>
                  <th>LUC Burn</th>
                  <th>LUC C Stock</th>
                  <th>Feed</th>
                  <th>Farm</th>
                  <th>Processing</th>
                  <th>Tran & Str</th>
                  <th>Packaging</th>
                  <th>Retail</th>
                  <th>End mile</th>
                  <th>End of life</th>
                  <th>Cooking</th>
                  <th>Loss</th>
                </>
              )}
            </>
          )}
          {showEffectsPerKg && (
            <>
              <th>Amount (kg)</th>
              <th>
                GHG/kg{" "}
                <span
                  onClick={() => setGhgPerKgExpanded(!ghgPerKgExpanded)}
                  style={{ cursor: "pointer" }}
                >
                  {ghgPerKgExpanded ? "[-]" : "[+]"}
                </span>
              </th>
              {ghgPerKgExpanded && (
                <>
                  <th>LUC Burn</th>
                  <th>LUC C Stock</th>
                  <th>Feed</th>
                  <th>Farm</th>
                  <th>Processing</th>
                  <th>Tran & Str</th>
                  <th>Packaging</th>
                  <th>Retail</th>
                  <th>End mile</th>
                  <th>End of life</th>
                  <th>Cooking</th>
                  <th>Loss</th>
                </>
              )}
            </>
          )}
          {showEffectsPerAmount && (
            <>
              <th>Amount (kg)</th>
              <th>
                Land Use{" "}
                <span
                  onClick={() =>
                    setLandUsePerAmountExpanded(!landUsePerAmountExpanded)
                  }
                  style={{ cursor: "pointer" }}
                >
                  {landUsePerAmountExpanded ? "[-]" : "[+]"}
                </span>
              </th>
              {landUsePerAmountExpanded && (
                <>
                  <th>Seed</th>
                  <th>Arable</th>
                  <th>Temp. Pasture</th>
                  <th>Fallow</th>
                  <th>Perm. Pasture</th>
                  <th>Loss</th>
                </>
              )}
            </>
          )}
          {showEffectsPerKg && (
            <>
              <th>Amount (kg)</th>
              <th>
                Land Use/kg{" "}
                <span
                  onClick={() => setLandUsePerKgExpanded(!landUsePerKgExpanded)}
                  style={{ cursor: "pointer" }}
                >
                  {landUsePerKgExpanded ? "[-]" : "[+]"}
                </span>
              </th>
              {landUsePerKgExpanded && (
                <>
                  <th>Seed</th>
                  <th>Arable</th>
                  <th>Temp. Pasture</th>
                  <th>Fallow</th>
                  <th>Perm. Pasture</th>
                  <th>Loss</th>
                </>
              )}
            </>
          )}
          {showEffectsPerAmount && (
            <>
              <th>Amount (kg)</th>
              <th>
                Water Use{" "}
                <span
                  onClick={() =>
                    setWaterUsePerAmountExpanded(!waterUsePerAmountExpanded)
                  }
                  style={{ cursor: "pointer" }}
                >
                  {waterUsePerAmountExpanded ? "[-]" : "[+]"}
                </span>
              </th>
              {waterUsePerAmountExpanded && (
                <>
                  <th>Feed</th>
                  <th>Farm</th>
                  <th>Processing</th>
                  <th>Loss</th>
                </>
              )}
            </>
          )}
          {showEffectsPerKg && (
            <>
              <th>Amount (kg)</th>
              <th>
                Water Use/kg{" "}
                <span
                  onClick={() =>
                    setWaterUsePerKgExpanded(!waterUsePerKgExpanded)
                  }
                  style={{ cursor: "pointer" }}
                >
                  {waterUsePerKgExpanded ? "[-]" : "[+]"}
                </span>
              </th>
              {waterUsePerKgExpanded && (
                <>
                  <th>Feed</th>
                  <th>Farm</th>
                  <th>Processing</th>
                  <th>Loss</th>
                </>
              )}
            </>
          )}
          {showEffectsPerServing && (
            <>
              <th>Amount (serving)</th>
              <th>
                GHG/serving{" "}
                <span
                  onClick={() =>
                    setGhgPerServingExpanded(!ghgPerServingExpanded)
                  }
                  style={{ cursor: "pointer" }}
                >
                  {ghgPerServingExpanded ? "[-]" : "[+]"}
                </span>
              </th>
              {ghgPerServingExpanded && (
                <>
                  <th>LUC Burn</th>
                  <th>LUC C Stock</th>
                  <th>Feed</th>
                  <th>Farm</th>
                  <th>Processing</th>
                  <th>Tran & Str</th>
                  <th>Packaging</th>
                  <th>Retail</th>
                  <th>End mile</th>
                  <th>End of life</th>
                  <th>Cooking</th>
                  <th>Loss</th>
                </>
              )}
            </>
          )}
          <th>
            DQS (ghg){" "}
            <span
              onClick={toggleDataQualityScoreGhgExpanded}
              style={{ cursor: "pointer" }}
            >
              {dataQualityScoreGhgExpanded ? "[-]" : "[+]"}
            </span>
          </th>
          {dataQualityScoreGhgExpanded && (
            <>
              <th>Geographic DQS</th>
              <th>Technology DQS</th>
              <th>Temporal DQS</th>
            </>
          )}
          <th>
            DQS (land){" "}
            <span
              onClick={toggleDataQualityScoreLandExpanded}
              style={{ cursor: "pointer" }}
            >
              {dataQualityScoreLandExpanded ? "[-]" : "[+]"}
            </span>
          </th>
          {dataQualityScoreLandExpanded && (
            <>
              <th>Geographic DQS</th>
              <th>Technology DQS</th>
              <th>Temporal DQS</th>
            </>
          )}
          <th>
            DQS (water){" "}
            <span
              onClick={toggleDataQualityScoreWaterExpanded}
              style={{ cursor: "pointer" }}
            >
              {dataQualityScoreWaterExpanded ? "[-]" : "[+]"}
            </span>
          </th>
          {dataQualityScoreWaterExpanded && (
            <>
              <th>Geographic DQS</th>
              <th>Technology DQS</th>
              <th>Temporal DQS</th>
            </>
          )}
          <th>% Loss</th>
          <th>% Economic allocation</th>
          <th>Primary Protein</th>
          <th>Is Vegan</th>
          <th>Is Vegetarian</th>
          <th>Dietary Categories</th>
          <th>Errors</th>
          <th>Notes</th>
        </tr>
      </thead>
      <tbody>
        <Row
          expandAll={expandAll}
          rootRow={calculationTableRow}
          indentLevel={0}
          ghgPerAmountExpanded={ghgPerAmountExpanded}
          landUsePerAmountExpanded={landUsePerAmountExpanded}
          waterUsePerAmountExpanded={waterUsePerAmountExpanded}
          dataQualityScoreGhgExpanded={dataQualityScoreGhgExpanded}
          dataQualityScoreLandExpanded={dataQualityScoreLandExpanded}
          dataQualityScoreWaterExpanded={dataQualityScoreWaterExpanded}
          showEffectsPerAmount={showEffectsPerAmount}
          ghgPerKgExpanded={ghgPerKgExpanded}
          landUsePerKgExpanded={landUsePerKgExpanded}
          waterUsePerKgExpanded={waterUsePerKgExpanded}
          showEffectsPerKg={showEffectsPerKg}
          ghgPerServingExpanded={ghgPerServingExpanded}
          showEffectsPerServing={showEffectsPerServing}
        />
      </tbody>
    </Table>
  );
}

interface CalculationTableRowProps {
  expandAll: number;
  indentLevel: number;
  rootRow: CalculationTableRow;
  ghgPerAmountExpanded: boolean;
  landUsePerAmountExpanded: boolean;
  waterUsePerAmountExpanded: boolean;
  dataQualityScoreGhgExpanded: boolean;
  dataQualityScoreLandExpanded: boolean;
  dataQualityScoreWaterExpanded: boolean;
  showEffectsPerAmount: boolean;
  ghgPerKgExpanded: boolean;
  landUsePerKgExpanded: boolean;
  waterUsePerKgExpanded: boolean;
  showEffectsPerKg: boolean;
  ghgPerServingExpanded: boolean;
  showEffectsPerServing: boolean;
}

interface NameLinkProps {
  indentLevel: number;
  name: string;
  link?: string;
}

function Row(props: CalculationTableRowProps) {
  const {
    expandAll,
    ghgPerAmountExpanded,
    landUsePerAmountExpanded,
    waterUsePerAmountExpanded,
    ghgPerKgExpanded,
    landUsePerKgExpanded,
    waterUsePerKgExpanded,
    dataQualityScoreGhgExpanded,
    dataQualityScoreLandExpanded,
    dataQualityScoreWaterExpanded,
    indentLevel,
    rootRow,
    showEffectsPerAmount,
    showEffectsPerKg,
    ghgPerServingExpanded,
    showEffectsPerServing,
  } = props;

  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    expandAll > 0 && setExpanded(true);
  }, [expandAll]);

  const expandable = rootRow.child_rows.length > 0;

  const renderDataQualityScore = (dataQualityScore: number | null) =>
    dataQualityScore === null ? "-" : dataQualityScore.toFixed(2);

  const renderRootRow = () => (
    <tr>
      <NameCell
        indentLevel={indentLevel}
        name={rootRow.name}
        link={rootRow.link}
      />
      <td
        onClick={() => expandable && setExpanded(!expanded)}
        style={{ cursor: "pointer" }}
      >
        {expandable ? (expanded ? "[-]" : "[+]") : null}
      </td>
      <td>{rootRow.item_type}</td>
      <td>{rootRow.method}</td>
      <td>{rootRow.source_location_str}</td>
      <td>{rootRow.processing_location_str}</td>
      <td>{rootRow.consumption_location_str}</td>
      <td>{rootRow.system_boundary_str}</td>
      <td>{rootRow.multiplier_str}</td>
      {showEffectsPerAmount && (
        <GhgTableData
          cellClassName="GhgTableData_per_amount"
          amountKg={rootRow.amount_kg}
          expanded={ghgPerAmountExpanded}
          divisor={1}
          effects={rootRow.effects}
        />
      )}
      {showEffectsPerKg && (
        <GhgTableData
          cellClassName="GhgTableData_per_kg"
          amountKg={rootRow.amount_kg}
          expanded={ghgPerKgExpanded}
          divisor={rootRow.amount_kg}
          effects={rootRow.effects}
        />
      )}
      {showEffectsPerAmount && (
        <LandUseTableData
          cellClassName="LandUseTableData_per_amount"
          amountKg={rootRow.amount_kg}
          expanded={landUsePerAmountExpanded}
          divisor={1}
          effects={rootRow.effects}
        />
      )}
      {showEffectsPerKg && (
        <LandUseTableData
          cellClassName="LandUseTableData_per_kg"
          amountKg={rootRow.amount_kg}
          expanded={landUsePerKgExpanded}
          divisor={rootRow.amount_kg}
          effects={rootRow.effects}
        />
      )}
      {showEffectsPerAmount && (
        <WaterUseTableData
          cellClassName="WaterUseTableData_per_amount"
          amountKg={rootRow.amount_kg}
          expanded={waterUsePerAmountExpanded}
          divisor={1}
          effects={rootRow.effects}
        />
      )}
      {showEffectsPerKg && (
        <WaterUseTableData
          cellClassName="WaterUseTableData_per_kg"
          amountKg={rootRow.amount_kg}
          expanded={waterUsePerKgExpanded}
          divisor={rootRow.amount_kg}
          effects={rootRow.effects}
        />
      )}
      {showEffectsPerServing && (
        <GhgTableData
          cellClassName="GhgTableData_per_serving"
          amountKg={rootRow.amount_kg}
          expanded={ghgPerServingExpanded}
          divisor={rootRow.num_servings}
          effects={rootRow.effects}
        />
      )}
      <td>{renderDataQualityScore(rootRow.data_quality_score.ghg)}</td>
      {dataQualityScoreGhgExpanded && (
        <>
          <td>
            {renderDataQualityScore(rootRow.geographic_data_quality_score.ghg)}
          </td>
          <td>
            {renderDataQualityScore(rootRow.technology_data_quality_score.ghg)}
          </td>
          <td>
            {renderDataQualityScore(rootRow.temporal_data_quality_score.ghg)}
          </td>
        </>
      )}
      <td>{renderDataQualityScore(rootRow.data_quality_score.land_use)}</td>
      {dataQualityScoreLandExpanded && (
        <>
          <td>
            {renderDataQualityScore(
              rootRow.geographic_data_quality_score.land_use
            )}
          </td>
          <td>
            {renderDataQualityScore(
              rootRow.technology_data_quality_score.land_use
            )}
          </td>
          <td>
            {renderDataQualityScore(
              rootRow.temporal_data_quality_score.land_use
            )}
          </td>
        </>
      )}
      <td>{renderDataQualityScore(rootRow.data_quality_score.water_use)}</td>
      {dataQualityScoreWaterExpanded && (
        <>
          <td>
            {renderDataQualityScore(
              rootRow.geographic_data_quality_score.water_use
            )}
          </td>
          <td>
            {renderDataQualityScore(
              rootRow.technology_data_quality_score.water_use
            )}
          </td>
          <td>
            {renderDataQualityScore(
              rootRow.temporal_data_quality_score.water_use
            )}
          </td>
        </>
      )}
      <td>{rootRow.loss_str}</td>
      <td>{rootRow.economic_allocation_str}</td>
      <td>{rootRow.primary_protein_str}</td>
      <td>{rootRow.is_vegan_str}</td>
      <td>{rootRow.is_vegetarian_str}</td>
      <td>{rootRow.dietary_categories.join(", ")}</td>
      <td>{rootRow.errors.join("\n")}</td>
      <td>{rootRow.notes.join("\n")}</td>
    </tr>
  );

  return (
    <>
      {renderRootRow()}
      {expanded &&
        rootRow.child_rows.map((row) => (
          <Row
            expandAll={expandAll}
            rootRow={row}
            indentLevel={indentLevel + 1}
            ghgPerAmountExpanded={ghgPerAmountExpanded}
            landUsePerAmountExpanded={landUsePerAmountExpanded}
            waterUsePerAmountExpanded={waterUsePerAmountExpanded}
            dataQualityScoreGhgExpanded={dataQualityScoreGhgExpanded}
            dataQualityScoreLandExpanded={dataQualityScoreLandExpanded}
            dataQualityScoreWaterExpanded={dataQualityScoreWaterExpanded}
            showEffectsPerAmount={showEffectsPerAmount}
            ghgPerKgExpanded={ghgPerKgExpanded}
            landUsePerKgExpanded={landUsePerKgExpanded}
            waterUsePerKgExpanded={waterUsePerKgExpanded}
            showEffectsPerKg={showEffectsPerKg}
            ghgPerServingExpanded={ghgPerServingExpanded}
            showEffectsPerServing={showEffectsPerServing}
          />
        ))}
    </>
  );
}

interface GhgTableDataProps {
  cellClassName: string;
  amountKg: number;
  expanded: boolean;
  effects: any;
  divisor: number;
}

function GhgTableData(props: GhgTableDataProps) {
  const { amountKg, cellClassName, expanded, effects, divisor } = props;

  const formatGhgPerUnit = (
    ghg: number | null | undefined,
    divisor: number
  ): string => {
    return ghg === null || ghg === undefined ? "-" : (ghg / divisor).toFixed(5);
  };

  return (
    <>
      <td className={cellClassName}>{formatGhgPerUnit(amountKg, divisor)}</td>
      <td className={cellClassName}>
        {formatGhgPerUnit(effects.ghg, divisor)}
      </td>
      {expanded && (
        <>
          <td className={cellClassName}>
            {formatGhgPerUnit(effects.ghg_luc_burn, divisor)}
          </td>
          <td className={cellClassName}>
            {formatGhgPerUnit(effects.ghg_luc_c_stock, divisor)}
          </td>
          <td className={cellClassName}>
            {formatGhgPerUnit(effects.ghg_feed, divisor)}
          </td>
          <td className={cellClassName}>
            {formatGhgPerUnit(effects.ghg_farm, divisor)}
          </td>
          <td className={cellClassName}>
            {formatGhgPerUnit(effects.ghg_processing, divisor)}
          </td>
          <td className={cellClassName}>
            {formatGhgPerUnit(effects.ghg_tran_str, divisor)}
          </td>
          <td className={cellClassName}>
            {formatGhgPerUnit(effects.ghg_packaging, divisor)}
          </td>
          <td className={cellClassName}>
            {formatGhgPerUnit(effects.ghg_retail, divisor)}
          </td>
          <td className={cellClassName}>
            {formatGhgPerUnit(effects.ghg_end_mile, divisor)}
          </td>
          <td className={cellClassName}>
            {formatGhgPerUnit(effects.ghg_end_of_life, divisor)}
          </td>
          <td className={cellClassName}>
            {formatGhgPerUnit(effects.ghg_cooking, divisor)}
          </td>
          <td className={cellClassName}>
            {formatGhgPerUnit(effects.ghg_loss, divisor)}
          </td>
        </>
      )}
    </>
  );
}

interface LandUseTableDataProps {
  cellClassName: string;
  amountKg: number;
  expanded: boolean;
  effects: any;
  divisor: number;
}

function LandUseTableData(props: LandUseTableDataProps) {
  const { amountKg, cellClassName, expanded, effects, divisor } = props;

  const formatLandUsePerUnit = (
    landUse: number | null | undefined,
    divisor: number
  ): string => {
    return landUse === null || landUse === undefined
      ? "-"
      : (landUse / divisor).toFixed(5);
  };

  return (
    <>
      <td className={cellClassName}>
        {formatLandUsePerUnit(amountKg, divisor)}
      </td>
      <td className={cellClassName}>
        {formatLandUsePerUnit(effects.land, divisor)}
      </td>
      {expanded && (
        <>
          <td className={cellClassName}>
            {formatLandUsePerUnit(effects.land_seed, divisor)}
          </td>
          <td className={cellClassName}>
            {formatLandUsePerUnit(effects.land_arable, divisor)}
          </td>
          <td className={cellClassName}>
            {formatLandUsePerUnit(effects.land_temporary_pasture, divisor)}
          </td>
          <td className={cellClassName}>
            {formatLandUsePerUnit(effects.land_fallow, divisor)}
          </td>
          <td className={cellClassName}>
            {formatLandUsePerUnit(effects.land_permanent_pasture, divisor)}
          </td>
          <td className={cellClassName}>
            {formatLandUsePerUnit(effects.land_loss, divisor)}
          </td>
        </>
      )}
    </>
  );
}

interface WaterUseTableDataProps {
  cellClassName: string;
  amountKg: number;
  expanded: boolean;
  effects: any;
  divisor: number;
}

function WaterUseTableData(props: WaterUseTableDataProps) {
  const { amountKg, cellClassName, expanded, effects, divisor } = props;

  const formatWaterUsePerUnit = (
    waterUse: number | null | undefined,
    divisor: number
  ): string => {
    return waterUse === null || waterUse === undefined
      ? "-"
      : (waterUse / divisor).toFixed(5);
  };

  return (
    <>
      <td className={cellClassName}>
        {formatWaterUsePerUnit(amountKg, divisor)}
      </td>
      <td className={cellClassName}>
        {formatWaterUsePerUnit(effects.water, divisor)}
      </td>
      {expanded && (
        <>
          <td className={cellClassName}>
            {formatWaterUsePerUnit(effects.water_feed, divisor)}
          </td>
          <td className={cellClassName}>
            {formatWaterUsePerUnit(effects.water_farm, divisor)}
          </td>
          <td className={cellClassName}>
            {formatWaterUsePerUnit(effects.water_processing, divisor)}
          </td>
          <td className={cellClassName}>
            {formatWaterUsePerUnit(effects.water_loss, divisor)}
          </td>
        </>
      )}
    </>
  );
}

function NameCell(props: NameLinkProps) {
  const { indentLevel, link, name } = props;
  return (
    <td className="text-nowrap">
      {
        <span className="CalculationTable__Tabs">
          {"|  ".repeat(indentLevel)}
        </span>
      }
      {link !== null && link !== undefined ? (
        <a
          href={`${(window as any).FOODSTEPS_PLATFORM_URI}${link.substring(1)}`} // substring(1) to strip the extra '/'
          target="_blank"
          rel="noreferrer"
        >
          {name}
        </a>
      ) : (
        name
      )}
    </td>
  );
}
