import Alert from "react-bootstrap/Alert";

import "./QuarterlyDataUpdateBanner.css";
import { useFoodManufacturerOrganization } from "../services/useOrganizationFeatures";

const QUARTERLY_DATA_UPDATE_FOOD_MANUFACTURER_CIRCLE_LINK =
  "https://foodsteps.circle.so/c/product-updates-food-manufacturers/database-update-december-2024-food-manufacturers";
const QUARTERLY_DATA_UPDATE_FOOD_SERVICE_CIRCLE_LINK =
  "https://foodsteps.circle.so/c/product-updates-food-service/database-update-december-2024-food-service";

interface QuarterlyDataUpdateBannerProps {
  onClose: () => void;
}

export default function QuarterlyDataUpdateBanner(
  props: QuarterlyDataUpdateBannerProps
) {
  const { onClose } = props;

  const isFoodManufacturer = useFoodManufacturerOrganization();

  function quarterlyDataUpdateCircleLink() {
    return isFoodManufacturer
      ? QUARTERLY_DATA_UPDATE_FOOD_MANUFACTURER_CIRCLE_LINK
      : QUARTERLY_DATA_UPDATE_FOOD_SERVICE_CIRCLE_LINK;
  }

  const now = new Date();

  const estimatedEndOfQ4Release = new Date("2024-12-17T06:00:00");

  return (
    <Alert
      className="QuarterlyDataUpdateBanner__Alert"
      onClose={onClose}
      dismissible
    >
      Foodsteps has been updated with new impact numbers. Click{" "}
      <a
        href={quarterlyDataUpdateCircleLink()}
        target="_blank"
        rel="noreferrer"
      >
        here
      </a>{" "}
      to learn more.
      {now < estimatedEndOfQ4Release && (
        <span>
          The update is currently in progress, and the platform is expected to
          be slow for several hours. If you are experiencing any issues, please
          try again tomorrow.
        </span>
      )}
    </Alert>
  );
}
