import { FormattedMessage } from "react-intl";

import { SystemBoundary } from "../../__generated__/globalTypes";
import assertNever from "../../util/assertNever";

interface SystemBoundaryTextProps {
  systemBoundary: SystemBoundary;
}

export default function SystemBoundaryText(props: SystemBoundaryTextProps) {
  const { systemBoundary } = props;

  if (systemBoundary === SystemBoundary.CRADLE_TO_PROCESSING_GATE) {
    return (
      <FormattedMessage
        id="components/recipes/SystemBoundaryText:cradleToProcessingGate"
        defaultMessage="Cradle To Processing Gate"
      />
    );
  } else if (systemBoundary === SystemBoundary.CRADLE_TO_GRAVE) {
    return (
      <FormattedMessage
        id="components/recipes/SystemBoundaryText:cradleToGrave"
        defaultMessage="Cradle To Grave"
      />
    );
  } else if (systemBoundary === SystemBoundary.CRADLE_TO_RETAIL_DOORSTEP) {
    return (
      <FormattedMessage
        id="components/recipes/SystemBoundaryText:cradleToRetailDoorstep"
        defaultMessage="Cradle To Retail Doorstep"
      />
    );
  } else {
    assertNever(systemBoundary, "Unsupported system boundary");
  }
}
