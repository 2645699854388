import { EffectType } from "../../__generated__/globalTypes";
import { FunctionalUnit } from "../../domain/functionalUnits";
import assertNever from "../../util/assertNever";
import * as labelConstants from "../utils/Vectors/carbon-labels/constants";
import {
  LabelSizeOption,
  SizeDependentLabelType,
  SizeIndependentLabelType,
} from "./labelTypes";

interface LabelDimensions {
  height: number;
  strokeWidth?: number;
  width: number;
}

export function labelSizeToDimensions(
  labelType: SizeDependentLabelType,
  size: LabelSizeOption
): LabelDimensions {
  let baseWidth: number;
  let baseHeight: number;
  if (labelType === "descriptiveRating") {
    baseWidth = labelConstants.DESCRIPTIVE_RATING_BASE_SVG_WIDTH_PIXELS;
    baseHeight =
      labelConstants.DESCRIPTIVE_RATING_HEIGHT_TO_WIDTH_RATIO * baseWidth;
  } else if (labelType === "letterRating") {
    baseWidth = labelConstants.LETTER_RATING_BASE_SVG_WIDTH_PIXELS;
    baseHeight = labelConstants.LETTER_RATING_HEIGHT_TO_WIDTH_RATIO * baseWidth;
  } else if (labelType === "ratingScale") {
    baseWidth = labelConstants.RATING_SCALE_BASE_SVG_WIDTH_PIXELS;
    baseHeight = labelConstants.RATING_SCALE_HEIGHT_TO_WIDTH_RATIO * baseWidth;
  } else {
    assertNever(labelType, "Invalid label type");
  }
  switch (size) {
    case "small":
      return { height: baseHeight, strokeWidth: 2, width: baseWidth };
    case "medium":
      return {
        height: labelConstants.MEDIUM_SCALE_FACTOR * baseHeight,
        strokeWidth: 1,
        width: labelConstants.MEDIUM_SCALE_FACTOR * baseWidth,
      };
    case "large":
      return {
        height: labelConstants.LARGE_SCALE_FACTOR * baseHeight,
        strokeWidth: 1,
        width: labelConstants.LARGE_SCALE_FACTOR * baseWidth,
      };
    case "x-large":
      return {
        height: labelConstants.X_LARGE_SCALE_FACTOR * baseHeight,
        strokeWidth: 0.75,
        width: labelConstants.X_LARGE_SCALE_FACTOR * baseWidth,
      };
  }
}

export function labelTypeToDimensions(
  labelType: SizeIndependentLabelType
): LabelDimensions {
  if (labelType === "descriptiveRatingScale") {
    return {
      height:
        labelConstants.DESCRIPTIVE_RATING_SCALE_HEIGHT_TO_WIDTH_RATIO *
        labelConstants.DESCRIPTIVE_RATING_SCALE_BASE_SVG_WIDTH_PIXELS,
      width: labelConstants.DESCRIPTIVE_RATING_SCALE_BASE_SVG_WIDTH_PIXELS,
    };
  } else if (labelType === "titledRatingScale") {
    return {
      height:
        labelConstants.TITLED_RATING_SCALE_HEIGHT_TO_WIDTH_RATIO *
        labelConstants.TITLED_RATING_SCALE_BASE_SVG_WIDTH_PIXELS,
      width: labelConstants.TITLED_RATING_SCALE_BASE_SVG_WIDTH_PIXELS,
    };
  } else {
    assertNever(labelType, "Invalid label type");
  }
}

export function labelEffectTypeToDimensions(
  effectType: EffectType
): LabelDimensions {
  if (
    effectType === EffectType.GHG_PER_KG ||
    effectType === EffectType.LAND_USE_PER_KG ||
    effectType === EffectType.WATER_USE_PER_KG
  ) {
    return {
      height:
        labelConstants.DESCRIPTIVE_IMPACT_AND_SCALE_PER_KG_HEIGHT_TO_WIDTH_RATIO *
        labelConstants.DESCRIPTIVE_IMPACT_AND_SCALE_PER_KG_BASE_SVG_WIDTH_PIXELS,
      width:
        labelConstants.DESCRIPTIVE_IMPACT_AND_SCALE_PER_KG_BASE_SVG_WIDTH_PIXELS,
    };
  } else {
    return {
      height:
        labelConstants.DESCRIPTIVE_IMPACT_AND_SCALE_PER_SERVING_HEIGHT_TO_WIDTH_RATIO *
        labelConstants.DESCRIPTIVE_IMPACT_AND_SCALE_PER_SERVING_BASE_SVG_WIDTH_PIXELS,
      width:
        labelConstants.DESCRIPTIVE_IMPACT_AND_SCALE_PER_SERVING_BASE_SVG_WIDTH_PIXELS,
    };
  }
}

// TODO: Remove duplication of logic between effect type and functional unit
export function labelFunctionalUnitToDimensions(
  functionalUnit: FunctionalUnit
): LabelDimensions {
  switch (functionalUnit) {
    case FunctionalUnit.KG:
      return {
        height:
          labelConstants.DESCRIPTIVE_IMPACT_AND_SCALE_PER_KG_HEIGHT_TO_WIDTH_RATIO *
          labelConstants.DESCRIPTIVE_IMPACT_AND_SCALE_PER_KG_BASE_SVG_WIDTH_PIXELS,
        width:
          labelConstants.DESCRIPTIVE_IMPACT_AND_SCALE_PER_KG_BASE_SVG_WIDTH_PIXELS,
      };
    case FunctionalUnit.PORTION:
      return {
        height:
          labelConstants.DESCRIPTIVE_IMPACT_AND_SCALE_PER_SERVING_HEIGHT_TO_WIDTH_RATIO *
          labelConstants.DESCRIPTIVE_IMPACT_AND_SCALE_PER_SERVING_BASE_SVG_WIDTH_PIXELS,
        width:
          labelConstants.DESCRIPTIVE_IMPACT_AND_SCALE_PER_SERVING_BASE_SVG_WIDTH_PIXELS,
      };
  }
}
