import { FunctionalUnit } from "../../domain/functionalUnits";
import { ImpactCategory } from "../../domain/impactCategories";
import { ImpactRating } from "../../domain/impactRatings";
import assertNever from "../../util/assertNever";
import { convertSvgToBase64Png } from "../../util/convertSvgToPng";
import DescriptiveImpactAndScale from "../utils/Vectors/labels/DescriptiveImpactAndScale";
import DescriptiveRating from "../utils/Vectors/labels/DescriptiveRating";
import DescriptiveRatingScale from "../utils/Vectors/labels/DescriptiveRatingScale";
import LetterRating from "../utils/Vectors/labels/LetterRating";
import RatingScale from "../utils/Vectors/labels/RatingScale";
import TitledRatingScale from "../utils/Vectors/labels/TitledRatingScale";
import {
  labelFunctionalUnitToDimensions,
  labelSizeToDimensions,
  labelTypeToDimensions,
} from "./labelDimensions";
import { LabelColourSetting, LabelSizeOption, LabelType } from "./labelTypes";

interface LabelProps {
  colourSetting: LabelColourSetting;
  impactCategory: ImpactCategory;
  impactMagnitude?: number;
  impactRating: ImpactRating;
  type: LabelType;
  width: number;
  locale: string | null;
}

export default function Label(props: LabelProps) {
  const {
    colourSetting,
    impactCategory,
    impactMagnitude,
    impactRating,
    locale,
    type,
    width,
  } = props;

  switch (type) {
    case "descriptiveImpactAndScale":
      if (impactMagnitude === undefined) {
        throw new Error(
          "impactMagnitude must be provided for descriptiveImpactAndScale label type"
        );
      } else {
        return (
          <DescriptiveImpactAndScale
            greyscale={colourSetting === "monochrome"}
            locale={locale}
            impactCategory={impactCategory}
            functionalUnit={FunctionalUnit.KG}
            impactMagnitude={impactMagnitude}
            impactRating={impactRating}
            width={width}
          />
        );
      }
    case "descriptiveRating":
      return (
        <DescriptiveRating
          greyscale={colourSetting === "monochrome"}
          impactCategory={impactCategory}
          impactRating={impactRating}
          locale={locale}
          width={width}
        />
      );
    case "descriptiveRatingScale":
      return (
        <DescriptiveRatingScale
          greyscale={colourSetting === "monochrome"}
          impactCategory={impactCategory}
          impactRating={impactRating}
          locale={locale}
          width={width}
        />
      );
    case "letterRating":
      return (
        <LetterRating
          greyscale={colourSetting === "monochrome"}
          impactCategory={impactCategory}
          impactRating={impactRating}
          width={width}
        />
      );
    case "ratingScale":
      return (
        <RatingScale
          greyscale={colourSetting === "monochrome"}
          impactCategory={impactCategory}
          impactRating={impactRating}
          width={width}
        />
      );
    case "titledRatingScale":
      return (
        <TitledRatingScale
          greyscale={colourSetting === "monochrome"}
          impactCategory={impactCategory}
          impactRating={impactRating}
          locale={locale}
          width={width}
        />
      );
  }
}

export async function generateLabelPngBase64(
  colourSetting: LabelColourSetting,
  impactCategory: ImpactCategory,
  impactMagnitude: number,
  impactRating: ImpactRating,
  type: LabelType,
  functionalUnit: FunctionalUnit,
  size: LabelSizeOption,
  locale: string | null
) {
  let dimensions;

  if (type === "descriptiveImpactAndScale") {
    dimensions = labelFunctionalUnitToDimensions(functionalUnit);
  } else if (
    type === "descriptiveRatingScale" ||
    type === "titledRatingScale"
  ) {
    dimensions = labelTypeToDimensions(type);
  } else if (
    type === "descriptiveRating" ||
    type === "letterRating" ||
    type === "ratingScale"
  ) {
    dimensions = labelSizeToDimensions(type, size);
  } else {
    assertNever(type, "Invalid label type");
  }

  const svgElement = (
    <Label
      colourSetting={colourSetting}
      width={dimensions.width}
      type={type}
      impactCategory={impactCategory}
      impactMagnitude={impactMagnitude}
      impactRating={impactRating}
      locale={locale}
    />
  );

  return await convertSvgToBase64Png({
    height: dimensions.height,
    svgElement,
    width: dimensions.width,
  });
}
