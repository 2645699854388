import classNames from "classnames";
import gql from "graphql-tag";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useMemo } from "use-memo-one";

import { useDataStore } from "../../data-store";
import useUserInfo from "../../data-store/useUserInfo";
import { DataQualityScore } from "../../domain/DataQualityScore";
import { impactCategoryToEffectTypePerKg } from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import {
  getImpactCategoryRating,
  ImpactRating,
  useImpactRatingToLongName,
} from "../../domain/impactRatings";
import {
  useCountryOfOriginSourcing,
  useDataQualityScore,
} from "../../services/useOrganizationFeatures";
import assertNever from "../../util/assertNever";
import * as statuses from "../../util/statuses";
import UserVisibleError from "../../util/UserVisibleError";
import usePagedQuery, {
  extractNodesFromPagedQueryResult,
} from "../graphql/usePagedQuery";
import ImpactRatingDisplay from "../impacts/ImpactRatingDisplay";
import {
  useOrganization,
  useOrganizationId,
} from "../organizations/OrganizationProvider";
import { IngredientsListPageQueryParams } from "../pages";
import useRecipeLabel from "../recipes/useRecipeLabel";
import StatusDisplay from "../StatusDisplay";
import DQSInformationModalTooltip from "../utils/DQS/DQSInformationModalTooltip";
import impactCategoryDataQualityScore from "../utils/DQS/impactCategoryDataQualityScore";
import useDataQualityScoreToRatingString from "../utils/DQS/useDataQualityScoreToRatingString";
import { getNameForLocale } from "../utils/getNameForLocale";
import { Panel } from "../utils/Panel";
import Spinner from "../utils/Spinner";
import { PagedTable, TableColumn, TableSort } from "../utils/Table";
import Toast, { ErrorToast } from "../utils/Toast";
import TooltipOverlay from "../utils/TooltipOverlay";
import UpgradeRequestModal from "../utils/UpgradeRequestModal";
import useId from "../utils/useId";
import { FilledCheckmark } from "../utils/Vectors";
import { locationsToString } from "./ingredientOrigins";
import { IngredientOriginInformationModalTooltip } from "./IngredientsPage";
import {
  ColumnKey,
  ingredientsOrderByToTableSort,
  tableSortToIngredientsOrderBy,
} from "./IngredientsQueryControls";
import {
  IngredientsTable_FoodClass as FoodClass,
  IngredientsTable_Location as Location,
  IngredientsTable_Query as Query,
  IngredientsTable_QueryVariables as QueryVariables,
  IngredientsTable_WeightedLocationOption as WeightedLocationOption,
} from "./IngredientsTable.graphql";
import "./IngredientsTable.css";

interface IngredientSummary {
  dataQualityScore: DataQualityScore | null;
  foodClassId: number;
  ghgPerKg: number | null;
  impactRating: ImpactRating | null;
  isStale: boolean;
  key: number;
  landUsePerKg: number | null;
  name: string;
  numProducts: number;
  waterUsePerKg: number | null;
}

function summarizeIngredients(
  foodClasses: Array<FoodClass>,
  impactCategory: ImpactCategory,
  localeForFoodClasses: string
): Array<IngredientSummary> {
  return foodClasses.map((foodClass, key) => {
    return {
      dataQualityScore: foodClass.impact.dataQualityScore,
      foodClassId: foodClass.id,
      ghgPerKg: foodClass.impact.ghgPerKg,
      impactRating: getImpactCategoryRating(foodClass.impact, impactCategory),
      isStale: foodClass.impact.isStale,
      key,
      landUsePerKg: foodClass.impact.landUsePerKg,
      name: getNameForLocale(foodClass, localeForFoodClasses),
      numProducts: foodClass.recipeCount,
      waterUsePerKg: foodClass.impact.waterUsePerKg,
    };
  });
}

const ingredientSummaryKey = (summary: IngredientSummary) => summary.key;

interface IngredientsTableProps {
  impactCategory: ImpactCategory;
  locations: Array<Location>;
  queryParams: IngredientsListPageQueryParams;
  setQueryParams: (update: Partial<IngredientsListPageQueryParams>) => void;
  selectFoodClassId: (foodClassId: number | null) => void;
  setFetchingUpdatedFoodClasses: (fetchingUpdatedFoodClasses: boolean) => void;
  weightedLocationOptions: Array<WeightedLocationOption>;
}

export default function IngredientsTable(props: IngredientsTableProps) {
  const { impactCategory, queryParams, setQueryParams } = props;
  const [organizationId] = useOrganizationId();

  const { status, fetchNextPage } = usePagedQuery<
    Query,
    QueryVariables,
    FoodClass
  >(
    query,
    {
      first: 60,
      organizationId,
      fetchStaleImpacts: true,
      foodClassFilter: {
        usedAsIngredientInOrganizationId: organizationId,
        search: queryParams.searchTerm,
      },
      orderBy: queryParams.orderBy,
      impactCategory,
    },
    (data) => data.foodClasses
  );

  const foodClassesStatus = statuses.map(
    status,
    extractNodesFromPagedQueryResult
  );

  return (
    <StatusDisplay status={foodClassesStatus}>
      {(foodClasses) => (
        <IngredientsTableContent
          {...props}
          foodClasses={foodClasses}
          fetchNextPage={fetchNextPage}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
        />
      )}
    </StatusDisplay>
  );
}

type CalculationState =
  | "init"
  | "calculating"
  | "check for stale impacts"
  | "sorting"
  | "complete"
  | "failed";

interface IngredientsTableContentProps extends IngredientsTableProps {
  foodClasses: Array<FoodClass>;
  fetchNextPage: (() => Promise<void>) | null;
  queryParams: IngredientsListPageQueryParams;
  setQueryParams: (update: Partial<IngredientsListPageQueryParams>) => void;
}

export function IngredientsTableContent(props: IngredientsTableContentProps) {
  const {
    fetchNextPage,
    impactCategory,
    locations,
    foodClasses,
    queryParams,
    selectFoodClassId,
    setFetchingUpdatedFoodClasses,
    setQueryParams,
    weightedLocationOptions,
  } = props;

  const dataStore = useDataStore();
  const intl = useIntl();
  const [organizationId] = useOrganizationId();
  const recipeLabel = useRecipeLabel();
  const [userInfo] = useUserInfo();
  const [organization] = useOrganization();
  const impactRatingToLongName = useImpactRatingToLongName;

  const [displayedFoodClasses, setDisplayedFoodClasses] =
    useState<Array<FoodClass>>(foodClasses);
  const [calculationState, setCalculationState] =
    useState<CalculationState>("init");
  const prevCalculationState = useRef<CalculationState>("init");
  const [showToast, setShowToast] = useState<boolean>(false);
  const [showUpgradeRequestModal, setShowUpgradeRequestModal] =
    useState<boolean>(false);
  const [sort, setSort] = useState<TableSort | null>(
    ingredientsOrderByToTableSort(queryParams.orderBy)
  );
  const hasFeatureDataQualityScore = useDataQualityScore();
  const canChangeIngredientSourcing = useCountryOfOriginSourcing();

  const hasStaleFoodClass = (foodClasses: Array<FoodClass>) =>
    foodClasses.some((foodClass) => foodClass.impact.isStale);

  useEffect(() => {
    if (
      (calculationState === "calculating" &&
        (prevCalculationState.current === "init" ||
          prevCalculationState.current === "complete")) ||
      calculationState === "complete" ||
      calculationState === "failed"
    ) {
      setShowToast(true);
    }
    setFetchingUpdatedFoodClasses(calculationState === "calculating");
    prevCalculationState.current = calculationState;
  }, [calculationState, setFetchingUpdatedFoodClasses]);

  useEffect(() => {
    async function updateFoodClasses() {
      const staleFoodClassIds = displayedFoodClasses
        .filter((foodClass) => foodClass.impact.isStale)
        .map((foodClass) => foodClass.id);
      setCalculationState("calculating");
      const data = await dataStore.fetchGraphQL<Query, QueryVariables>({
        query,
        variables: {
          fetchStaleImpacts: false,
          first: 10000,
          foodClassFilter: { ids: staleFoodClassIds },
          organizationId,
        },
      });
      if (data.foodClasses === null) {
        setCalculationState("failed");
        reportError(
          new UserVisibleError("Could not fetch updated food classes.")
        );
      } else {
        const updatedFoodClasses = data.foodClasses.edges.map(
          (edge) => edge.node
        );
        setDisplayedFoodClasses(
          displayedFoodClasses.map(
            (foodClass) =>
              updatedFoodClasses.find(
                (updatedFoodClass) => updatedFoodClass.id === foodClass.id
              ) || foodClass
          )
        );
        setCalculationState("check for stale impacts");
      }
    }

    // Add newly loaded food classes to the table
    if (foodClasses.length !== displayedFoodClasses.length) {
      const newFoodClasses = foodClasses.filter(
        (foodClass) =>
          !displayedFoodClasses
            .map((displayedFoodClass) => displayedFoodClass.id)
            .includes(foodClass.id)
      );
      setDisplayedFoodClasses([...displayedFoodClasses, ...newFoodClasses]);
    }

    if (
      calculationState !== "calculating" &&
      hasStaleFoodClass(displayedFoodClasses)
    ) {
      updateFoodClasses();
    }

    if (
      calculationState === "check for stale impacts" &&
      !hasStaleFoodClass(displayedFoodClasses) &&
      foodClasses.length === displayedFoodClasses.length
    ) {
      setCalculationState("complete");
    }

    if (calculationState === "sorting") {
      setDisplayedFoodClasses(foodClasses);
    }
  }, [
    calculationState,
    dataStore,
    displayedFoodClasses,
    foodClasses,
    organizationId,
    setCalculationState,
  ]);

  function handleSortChange(sort: TableSort | null) {
    const orderBy = tableSortToIngredientsOrderBy(
      sort === null
        ? sort
        : {
            ...sort,
            columnKey: sort.columnKey as ColumnKey,
          }
    );
    setSort(sort);
    setCalculationState("sorting");
    setQueryParams({ orderBy });
  }

  const ingredientSummaries = useMemo(() => {
    return summarizeIngredients(
      displayedFoodClasses,
      impactCategory,
      organization.localeForFoodClasses
    );
  }, [displayedFoodClasses, impactCategory, organization.localeForFoodClasses]);

  const getLocationById = (locationId: number) =>
    locations.find((location) => locationId === location.id) ?? null;

  const locationsByFoodClassId = new Map(
    weightedLocationOptions.map((locationOption) => [
      locationOption.foodClassId,
      locationOption.locationProportions.map(
        (locationProportion) => locationProportion.locationId
      ),
    ])
  );

  const locationsForFoodClassId = (foodClassId: number): string => {
    return locationsToString(
      locationsByFoodClassId.get(foodClassId)?.map(getLocationById),
      intl
    );
  };

  const effectTypePerKg = impactCategoryToEffectTypePerKg(impactCategory);

  const formatImpactMagnitude = (impactMagnitude: number | null) => {
    return impactMagnitude !== null ? impactMagnitude.toFixed(2) : "-";
  };

  const impactMagnitude = (summary: IngredientSummary): number | null => {
    if (impactCategory === ImpactCategory.GHG) {
      return summary.ghgPerKg;
    } else if (impactCategory === ImpactCategory.LAND_USE) {
      return summary.landUsePerKg;
    } else if (impactCategory === ImpactCategory.WATER_USE) {
      return summary.waterUsePerKg;
    } else {
      assertNever(impactCategory, "Unsupported ImpactCategory.");
    }
  };

  const columns: Array<TableColumn<IngredientSummary>> = [
    {
      key: "ingredient",
      className: "medium-font col-4",
      label: intl.formatMessage({
        id: "components/ingredients/IngredientsTable:columns/ingredient/label",
        defaultMessage: "Ingredient",
      }),
      renderCell: (summary) => summary.name,
    },
    {
      key: "origin",
      label: intl.formatMessage({
        id: "components/ingredients/IngredientsTable:columns/origin/label",
        defaultMessage: "Ingredient Origin",
      }),
      labelPrefix: <IngredientOriginInformationModalTooltip />,
      locked: !canChangeIngredientSourcing,
      lockedMessage: (
        <FormattedMessage
          id="components/ingredients/IngredientsTable:columns/origin/lockedMessage"
          defaultMessage="Choose country-specific ingredient origins"
        />
      ),
      onLockClick: () => setShowUpgradeRequestModal(true),
      renderCell: (summary) => {
        const label = locationsForFoodClassId(summary.foodClassId);

        return userInfo.isReadonly || !canChangeIngredientSourcing ? (
          label
        ) : (
          <div
            className="action-link"
            onClick={() => selectFoodClassId(summary.foodClassId)}
          >
            {label}
          </div>
        );
      },
    },
    {
      key: "recipeCount",
      align: "right",
      label: recipeLabel.pluralUppercase,
      renderCell: (summary) => summary.numProducts,
    },
    ...(hasFeatureDataQualityScore
      ? [
          {
            key: "dqs",
            align: "right",
            label: intl.formatMessage({
              id: "components/ingredients/IngredientsTable:columns/dqs/label",
              defaultMessage: "Data Quality",
            }),
            labelPrefix: <DQSInformationModalTooltip />,
            renderCell: (summary) => (
              <DataQualityScoreCell
                dataQualityScore={
                  summary.dataQualityScore === null
                    ? null
                    : impactCategoryDataQualityScore(
                        summary.dataQualityScore,
                        impactCategory
                      )
                }
              />
            ),
          } as TableColumn<IngredientSummary>,
        ]
      : []),
    {
      align: "right",
      key: "impactPerKg",
      label: effectTypePerKg.title(intl),
      units: effectTypePerKg.unit,
      renderCell: (summary) => (
        <div
          className={classNames(
            "d-flex justify-content-end align-items-center flex-row",
            { "text-inactive": summary.isStale }
          )}
        >
          {summary.isStale && calculationState !== "failed" && (
            <Spinner className="mr-2" size="sm" />
          )}
          {formatImpactMagnitude(impactMagnitude(summary))}
        </div>
      ),
    },
    {
      align: "right",
      key: "impactRating",
      label: intl.formatMessage({
        id: "components/ingredients/IngredientsTable:columns/impactRating/label",
        defaultMessage: "Rating",
      }),
      renderCell: (summary: IngredientSummary) =>
        summary.impactRating == null ? null : (
          <div
            className={classNames("d-flex w-100 justify-content-end", {
              "text-inactive": summary.isStale,
            })}
          >
            <ImpactRatingDisplay
              value={summary.impactRating}
              text={impactRatingToLongName(summary.impactRating)}
            />
          </div>
        ),
    },
  ];

  const closeToast = () => setShowToast(false);

  return (
    <div style={{ minHeight: 0 }}>
      <Panel className="IngredientsTable_Panel">
        <PagedTable<IngredientSummary>
          fullWidth
          columns={columns}
          onLoadMore={fetchNextPage}
          onSortChange={handleSortChange}
          rowKey={ingredientSummaryKey}
          rows={ingredientSummaries}
          sort={sort}
          sortDisabled={hasStaleFoodClass(displayedFoodClasses)}
          sortRequired={false}
        />
      </Panel>
      <Toast
        show={showToast && calculationState === "calculating"}
        title={
          <FormattedMessage
            id="components/recipes/IngredientsTable:CalculatingImpactsToast/title"
            defaultMessage="Calculating Impacts."
          />
        }
        onClose={closeToast}
        message={
          <FormattedMessage
            id="components/recipes/IngredientsTable:CalculatingImpactsToast/message"
            defaultMessage="Foodsteps is busy calculating the impact of your ingredients. It may take a few minutes to see changes."
          />
        }
        symbol={
          <Spinner className="IngredientsTable_CalculatingImpactsSpinner" />
        }
      />
      <Toast
        show={showToast && calculationState === "complete"}
        title={
          <FormattedMessage
            id="components/recipes/IngredientsTable:CalculationsCompletedToast/title"
            defaultMessage="Calculations Completed."
          />
        }
        onClose={closeToast}
        message={
          <FormattedMessage
            id="components/recipes/IngredientsTable:CalculationsCompletedMessage/message"
            defaultMessage="Foodsteps has finished calculating the impact of your ingredients."
          />
        }
        symbol={<FilledCheckmark width={20} />}
      />
      <ErrorToast
        show={showToast && calculationState === "failed"}
        title={
          <FormattedMessage
            id="components/recipes/IngredientsTable:CalculationsFailedToast/title"
            defaultMessage="Calculations failed."
          />
        }
        onClose={closeToast}
        message={
          <FormattedMessage
            id="components/recipes/IngredientsTable:CalculationsFailedMessage/message"
            defaultMessage="An error occurred while calculating the new impacts of your ingredients. Try refreshing the page."
          />
        }
      />
      <UpgradeRequestModal
        onHide={() => setShowUpgradeRequestModal(false)}
        show={showUpgradeRequestModal}
      />
    </div>
  );
}

interface DataQualityScoreCellProps {
  dataQualityScore: number | null;
}

function DataQualityScoreCell(props: DataQualityScoreCellProps) {
  const { dataQualityScore } = props;

  const id = useId();
  const dataQualityScoreToRatingString = useDataQualityScoreToRatingString();

  return dataQualityScore ? (
    <TooltipOverlay
      overlay={`${dataQualityScore.toFixed(1)}/5`}
      id={id}
      placement="top"
    >
      {dataQualityScoreToRatingString(dataQualityScore)}
    </TooltipOverlay>
  ) : (
    <>-</>
  );
}

IngredientsTable.fragments = {
  foodClass: gql`
    fragment IngredientsTable_FoodClass on FoodClass {
      id
      impact(
        organizationId: $organizationId
        fetchStaleImpacts: $fetchStaleImpacts
      ) {
        dataQualityScore {
          ghg
          land_use
          water_use
        }
        ghgPerKg
        landUsePerKg
        waterUsePerKg
        impactRatingGhg
        impactRatingLandUse
        impactRatingWaterUse
        isStale
      }
      name
      recipeCount(organizationId: $organizationId)
      synonyms {
        name
        locale
        isDefaultForLocale
      }
    }
  `,

  location: gql`
    fragment IngredientsTable_Location on Location {
      id
      name
      type
    }
  `,

  weightedLocationOption: gql`
    fragment IngredientsTable_WeightedLocationOption on WeightedLocationOption {
      foodClassId
      locationProportions {
        ...IngredientsTable_LocationProportion
      }
    }

    fragment IngredientsTable_LocationProportion on LocationProportion {
      locationId
      proportion
    }
  `,
};

const query = gql`
  query IngredientsTable_Query(
    $after: String
    $first: Int!
    $organizationId: UUID!
    $fetchStaleImpacts: Boolean!
    $foodClassFilter: FoodClassFilter!
    $impactCategory: ImpactCategory
    $orderBy: FoodClassOrder
  ) {
    foodClasses(
      after: $after
      first: $first
      filter: $foodClassFilter
      impactCategory: $impactCategory
      orderBy: $orderBy
      organizationId: $organizationId
    ) {
      edges {
        node {
          ...IngredientsTable_FoodClass
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }

  ${IngredientsTable.fragments.foodClass}
`;
