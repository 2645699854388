import { useState } from "react";
import { FormattedMessage } from "react-intl";

import useUserInfo from "../../data-store/useUserInfo";
import { ImpactCategory } from "../../domain/impactCategories";
import { ImpactRating } from "../../domain/impactRatings";
import { SecondaryButton } from "../utils/Button";
import { Generate } from "../utils/Vectors";
import DownloadLabelsModal from "./DownloadLabelsModal";
import Label from "./Label";

interface RecipePageCarbonLabelProps {
  impactCategory: ImpactCategory;
  impactMagnitude: number;
  impactRating: ImpactRating;
  recipeId: number;
  showExportButton: boolean;
}

export default function RecipePageLabel(props: RecipePageCarbonLabelProps) {
  const {
    impactCategory,
    impactMagnitude,
    impactRating,
    recipeId,
    showExportButton,
  } = props;

  const [{ locale }] = useUserInfo();

  const [showDownloadModal, setShowDownloadModal] = useState(false);

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="mb-4">
        <Label
          colourSetting="colour"
          locale={locale}
          impactCategory={impactCategory}
          impactRating={impactRating}
          impactMagnitude={impactMagnitude}
          type="descriptiveImpactAndScale"
          width={90}
        />
      </div>
      {showDownloadModal && (
        <DownloadLabelsModal
          onHide={() => setShowDownloadModal(false)}
          page="Recipe"
          recipeFilter={{ ids: [recipeId] }}
          show={showDownloadModal}
        />
      )}
      {showExportButton && (
        <SecondaryButton
          icon={<Generate className="mb-1" width={20} />}
          onClick={() => setShowDownloadModal(true)}
        >
          <FormattedMessage
            id="components/labels/CarbonLabelCard:export"
            defaultMessage="Export"
          />
        </SecondaryButton>
      )}
    </div>
  );
}
