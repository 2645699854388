import { ImpactCategory } from "../../../../domain/impactCategories";
import {
  ImpactRating,
  useImpactRatingToColor,
} from "../../../../domain/impactRatings";
import { graphikSemiboldWebCssString } from "../../../../fonts/graphik-web/graphikSemiboldWebCssString";
import {
  darkTurquoise,
  foodstepsTurquoise,
  mint,
} from "../../../graphs/colors";
import { impactRatingLetterColor } from "../../impactRatingLetterColor";
import { impactCategoryTitle, impactRatingToLetter } from "./translations";

const lineHeight = 10;

interface TitledRatingScaleProps {
  greyscale?: boolean;
  impactCategory: ImpactCategory;
  impactRating: ImpactRating;
  width: number;
  locale: string | null;
}

export default function TitledRatingScale(props: TitledRatingScaleProps) {
  const {
    greyscale = false,
    impactCategory,
    impactRating,
    width,
    locale,
  } = props;

  const impactCategoryTitleOver2Lines =
    locale === "fr-FR" &&
    (impactCategory === ImpactCategory.LAND_USE ||
      impactCategory === ImpactCategory.WATER_USE);
  const setHeight = (defaultHeight: number) => {
    return impactCategoryTitleOver2Lines
      ? defaultHeight + lineHeight
      : defaultHeight;
  };

  return (
    <svg
      width={width}
      viewBox={`0 0 135 ${setHeight(62)}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>{graphikSemiboldWebCssString}</style>
      <g
        transform={
          impactCategoryTitleOver2Lines ? `translate(0, ${lineHeight / 2})` : ""
        }
      >
        <ImpactCategoryIllustration
          greyscale={greyscale}
          impactCategory={impactCategory}
        />
      </g>
      <rect
        x="44.8799"
        y="1"
        width="88"
        height={setHeight(60)}
        rx="7"
        fill="white"
      />
      <rect
        x="44.8799"
        y="1"
        width="88"
        height={setHeight(60)}
        rx="7"
        stroke="black"
        strokeWidth="2"
      />
      <Title impactCategory={impactCategory} locale={locale} />
      <g
        transform={
          impactCategoryTitleOver2Lines ? `translate(0, ${lineHeight})` : ""
        }
      >
        <ImpactRatingScale greyscale={greyscale} impactRating={impactRating} />
      </g>
      <defs>
        <clipPath id="clip0_150_3128">
          <rect width="66.96" height="62" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

interface ImpactRatingScaleProps {
  greyscale: boolean;
  impactRating: ImpactRating;
}

function ImpactRatingScale(props: ImpactRatingScaleProps) {
  const { greyscale, impactRating } = props;

  const impactRatingToColor = useImpactRatingToColor(greyscale);

  const ratingLetterColor = impactRatingLetterColor(impactRating, greyscale);
  const ratingLetter = impactRatingToLetter(impactRating);

  const ratingLetterY = 45.5;
  const ratingLetterXA = 66;
  const ratingLetterFontSize = 18;
  const distanceBetweenRatingLetters = 12;

  switch (impactRating) {
    case "VLOW":
      return (
        <>
          <rect
            x="54"
            y="25"
            width="24"
            height="28"
            rx="5"
            fill={impactRatingToColor("VLOW")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <path d="M82 30H91V48H82V30Z" fill={impactRatingToColor("LOW")} />
          <rect
            x="94"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor("MEDIUM")}
          />
          <rect
            x="106"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor("HIGH")}
          />
          <path
            d="M118 30H123C125.209 30 127 31.7909 127 34V44C127 46.2091 125.209 48 123 48H118V30Z"
            fill={impactRatingToColor("VHIGH")}
          />
        </>
      );
    case "LOW":
      return (
        <>
          <path
            d="M53 34C53 31.7909 54.7909 30 57 30H62V48H57C54.7909 48 53 46.2091 53 44V34Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="66"
            y="25"
            width="24"
            height="28"
            rx="5"
            fill={impactRatingToColor("LOW")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <rect
            x="94"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor("MEDIUM")}
          />
          <rect
            x="106"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor("HIGH")}
          />
          <path
            d="M118 30H123C125.209 30 127 31.7909 127 34V44C127 46.2091 125.209 48 123 48H118V30Z"
            fill={impactRatingToColor("VHIGH")}
          />
        </>
      );
    case "MEDIUM":
      return (
        <>
          <path
            d="M53 34C53 31.7909 54.7909 30 57 30H62V48H57C54.7909 48 53 46.2091 53 44V34Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="65"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor("LOW")}
          />
          <rect
            x="78"
            y="25"
            width="24"
            height="28"
            rx="5"
            fill={impactRatingToColor("MEDIUM")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 2 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <rect
            x="106"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor("HIGH")}
          />
          <path
            d="M118 30H123C125.209 30 127 31.7909 127 34V44C127 46.2091 125.209 48 123 48H118V30Z"
            fill={impactRatingToColor("VHIGH")}
          />
        </>
      );
    case "HIGH":
      return (
        <>
          <path
            d="M53 34C53 31.7909 54.7909 30 57 30H62V48H57C54.7909 48 53 46.2091 53 44V34Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="65"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor("LOW")}
          />
          <rect
            x="77"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor("MEDIUM")}
          />
          <rect
            x="90"
            y="25"
            width="24"
            height="28"
            rx="5"
            fill={impactRatingToColor("HIGH")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 3 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <path
            d="M118 30H123C125.209 30 127 31.7909 127 34V44C127 46.2091 125.209 48 123 48H118V30Z"
            fill={impactRatingToColor("VHIGH")}
          />
        </>
      );
    case "VHIGH":
      return (
        <>
          <path
            d="M53 34C53 31.7909 54.7909 30 57 30H62V48H57C54.7909 48 53 46.2091 53 44V34Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="65"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor("LOW")}
          />
          <rect
            x="77"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor("MEDIUM")}
          />
          <path d="M89 30H98V48H89V30Z" fill={impactRatingToColor("HIGH")} />
          <rect
            x="102"
            y="25"
            width="24"
            height="28"
            rx="5"
            fill={impactRatingToColor("VHIGH")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 4 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
        </>
      );
  }
}

interface ImpactCategoryIllustrationProps {
  greyscale: boolean;
  impactCategory: ImpactCategory;
}

function ImpactCategoryIllustration(props: ImpactCategoryIllustrationProps) {
  const { greyscale, impactCategory } = props;

  switch (impactCategory) {
    case ImpactCategory.GHG:
      return (
        <>
          <path
            d="M34.9199 60.8099C51.3835 60.8099 64.7299 47.4635 64.7299 30.9999C64.7299 14.5363 51.3835 1.18994 34.9199 1.18994C18.4563 1.18994 5.10986 14.5363 5.10986 30.9999C5.10986 47.4635 18.4563 60.8099 34.9199 60.8099Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M20.1899 33.2701C18.2699 29.2501 20.8899 26.0901 20.8899 26.0901C14.9899 26.0901 15.3899 20.3501 15.3899 20.3501C10.9099 23.4601 7.61992 19.7501 7.61992 19.7501C-0.510076 20.4401 1.40992 13.1301 1.40992 13.1301C-0.420076 5.00008 7.70992 5.09008 7.70992 5.09008C15.8399 -1.84992 21.3199 6.19008 21.3199 6.19008C32.0099 5.92008 29.3599 16.8801 29.3599 16.8801C34.1899 20.4801 29.5499 24.6701 29.5499 24.6701C29.5499 24.6701 33.7199 26.9901 30.2399 33.2701"
            fill={mint(greyscale)}
          />
          <path
            d="M20.1899 33.2701C18.2699 29.2501 20.8899 26.0901 20.8899 26.0901C14.9899 26.0901 15.3899 20.3501 15.3899 20.3501C10.9099 23.4601 7.61992 19.7501 7.61992 19.7501C-0.510076 20.4401 1.40992 13.1301 1.40992 13.1301C-0.420076 5.00008 7.70992 5.09008 7.70992 5.09008C15.8399 -1.84992 21.3199 6.19008 21.3199 6.19008C32.0099 5.92008 29.3599 16.8801 29.3599 16.8801C34.1899 20.4801 29.5499 24.6701 29.5499 24.6701C29.5499 24.6701 33.7199 26.9901 30.2399 33.2701"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.9202 60.81C35.9002 60.81 36.8702 60.76 37.8302 60.66L34.3102 34.71C34.1802 33.75 33.3602 33.03 32.3802 33.03H19.0502C18.0802 33.03 17.2502 33.75 17.1202 34.71L14.6602 52.83C19.9802 57.77 27.0802 60.81 34.9102 60.81H34.9202Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
    case ImpactCategory.LAND_USE:
      return (
        <>
          <path
            d="M34.9999 60.8099C51.4635 60.8099 64.8099 47.4635 64.8099 30.9999C64.8099 14.5363 51.4635 1.18994 34.9999 1.18994C18.5363 1.18994 5.18994 14.5363 5.18994 30.9999C5.18994 47.4635 18.5363 60.8099 34.9999 60.8099Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M12.9502 51.01C18.4002 57.02 26.2502 60.82 35.0102 60.82C43.7702 60.82 51.6102 57.02 57.0702 51.01H12.9502Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.32981 45.5501H14.3398C15.4098 45.5501 16.2698 46.5601 16.2698 47.8101V54.1001C19.1898 56.4801 22.5598 58.3301 26.2498 59.4601V47.8001C26.2498 46.5501 27.1098 45.5401 28.1798 45.5401H38.1898C40.9498 45.5401 42.2698 42.1501 40.2498 40.2801L31.3398 32.0401H34.7698C37.5298 32.0401 38.8498 28.6501 36.8298 26.7801L28.8498 19.4001H31.0398C33.7998 19.4001 35.1198 16.0201 33.0998 14.1401L24.0398 6.16011C22.4498 4.76011 20.0698 4.76011 18.4698 6.16011L9.40981 14.1401C7.37981 16.0101 8.70981 19.4001 11.4698 19.4001H13.6598L5.67981 26.7801C3.64981 28.6501 4.97981 32.0401 7.73981 32.0401H11.1698L2.25981 40.2801C0.229807 42.1501 1.55981 45.5401 4.31981 45.5401L4.32981 45.5501Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
    case ImpactCategory.WATER_USE:
      return (
        <>
          <path
            d="M34.8901 60.8099C51.3537 60.8099 64.7001 47.4635 64.7001 30.9999C64.7001 14.5363 51.3537 1.18994 34.8901 1.18994C18.4265 1.18994 5.08008 14.5363 5.08008 30.9999C5.08008 47.4635 18.4265 60.8099 34.8901 60.8099Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M34.8902 60.8101C45.6402 60.8101 55.0402 55.1001 60.2802 46.5601C57.7402 44.2201 56.0802 41.0801 56.0802 41.0801C46.1202 58.4301 36.7702 41.0801 36.7702 41.0801C25.8702 57.7801 17.4602 40.05 17.4602 40.05C14.7702 43.48 12.1702 45.63 9.7002 46.89C14.9802 55.24 24.2802 60.8101 34.8902 60.8101Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.16992 37.1C1.16992 27.28 12.4799 7.33997 12.4799 7.33997C13.6999 4.67997 17.8799 4.67997 19.0999 7.33997C19.0999 7.33997 30.4099 26.94 30.4099 37.11C30.4099 45.18 23.8699 52.73 15.7899 52.73C7.70992 52.73 1.16992 45.18 1.16992 37.1Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
  }
}

interface TitleProps {
  impactCategory: ImpactCategory;
  locale: string | null;
}

function Title(props: TitleProps) {
  const { impactCategory, locale } = props;

  const titleString = impactCategoryTitle(impactCategory, locale, true);

  const y = 17;

  const commonProps = {
    y,
    x: 90,
    textAnchor: "middle",
    fontFamily: "Graphik Web",
    fontWeight: 500,
    fontSize: impactCategoryTitleFontSize(impactCategory, locale),
    fill: "black",
  };

  if (
    locale === "fr-FR" &&
    (impactCategory === ImpactCategory.LAND_USE ||
      impactCategory === ImpactCategory.WATER_USE)
  ) {
    const words = titleString.split(" ");
    const firstWord = words.shift();
    return (
      <>
        <text {...commonProps}>{firstWord}</text>
        <text {...commonProps} y={y + lineHeight}>
          {words.join(" ")}
        </text>
      </>
    );
  } else {
    return <text {...commonProps}>{titleString}</text>;
  }
}

function impactCategoryTitleFontSize(
  impactCategory: ImpactCategory,
  locale: string | null
): number {
  if (locale === "de-DE" && impactCategory === ImpactCategory.WATER_USE) {
    return 9;
  }
  return 10;
}
