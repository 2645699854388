import Card from "../Card";
import illustrationsStories from "./illustrations/stories";
import * as Vectors from "./index";
import labelStories from "./labels/stories";

const stories = {
  name: "Vector",
  children: [
    {
      name: "All",
      render: () => (
        <div style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)" }}>
          {Object.entries(Vectors).map(([vectorName, Vector], index) => (
            <Card
              className="d-flex flex-column justify-content-between m-2 p-2"
              key={index}
              style={{ border: "1px solid" }}
            >
              <Vector className="mb-2" width={24} />
              {vectorName}
            </Card>
          ))}
        </div>
      ),
    },
    ...Object.entries(Vectors).map(([vectorName, Vector]) => ({
      name: vectorName,
      render: () => {
        return <Vector width={24} />;
      },
    })),
    illustrationsStories,
    labelStories,
  ],
};

export default stories;
