import { LocationDescriptorObject } from "history";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { createOAuth2Client } from "../../services/oauth2Client";
import { useSession } from "../../sessions";
import ErrorAlert from "../utils/ErrorAlert";

const clientId: string = (window as any).FOODSTEPS_PLATFORM_OAUTH2_CLIENT_ID;
const platformUri: string = (window as any).FOODSTEPS_PLATFORM_URI;

interface ExtraState {
  location: LocationDescriptorObject;
}

const oauth2Client = createOAuth2Client<ExtraState>({
  authorizeUri: `${platformUri}oauth2/authorize`,
  clientId,
  tokenUri: `${platformUri}oauth2/token`,
});

export function OAuth2AuthorizeRedirect() {
  useEffect(() => {
    oauth2Client.authorizeRedirect({
      location: {
        pathname: window.location.pathname,
        search: window.location.search,
        hash: window.location.hash,
      },
    });
  }, []);

  return null;
}

export function OAuth2AuthorizedPage() {
  const [error, setError] = useState<unknown>(null);

  const history = useHistory();
  const session = useSession();

  useEffect(() => {
    (async function () {
      // If we see an error, redirect to the main page will restart the auth flow
      // which may fix the error - especially ones related to accessing session specific state
      // However if the error persists we don't want to keep redirecting
      // so save state we can use to display an error 2nd time around
      try {
        const { accessToken, state } =
          await oauth2Client.exchangeCodeForToken();
        await session.setToken(accessToken);
        // If we fetched the token successfully, clean up state so we can try redirecting again next time it errors
        window.sessionStorage.removeItem("errorFetchingToken");
        history.replace(state.location);
      } catch (error: unknown) {
        const savedErrorState =
          window.sessionStorage.getItem("errorFetchingToken");
        if (savedErrorState === null) {
          console.error(error, { savedErrorState });
          window.sessionStorage.setItem(
            "errorFetchingToken",
            JSON.stringify(error)
          );
          history.replace("/");
        } else {
          setError(error);
        }
      }
    })();
  }, [history, session]);

  return error === null ? null : (
    <ErrorAlert
      defaultMessage="An error occurred while authenticating. Please reload the page or contact support."
      error={error}
    />
  );
}
