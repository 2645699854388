import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { DashboardProduct, ProductsDashboardData } from "../../data-store";
import useProductsDashboardData from "../../data-store/useProductsDashboardData";
import { ImpactCategory } from "../../domain/impactCategories";
import useImpactRatingInfos from "../../services/useImpactRatingInfos";
import useAvailableImpactCategories, {
  useAdditionalImpactCategories,
  useIsHeadOffice,
} from "../../services/useOrganizationFeatures";
import assertNever from "../../util/assertNever";
import Page from "../Page";
import { usePages } from "../pages";
import ImpactCategoryToggle, {
  ImpactCategoryToggleType,
} from "../recipes/ImpactCategoryToggle";
import { ProportionsChartImpactRatingInfos } from "../scope-3/ImpactRatingProportionsChart";
import StatusDisplay from "../StatusDisplay";
import "./ProductsDashboardPage.css";
import "../Page.css";
import { Panel } from "../utils/Panel";
import { AverageImpactIntensityByTag } from "./AverageImpactIntensityByTag";
import { DashboardOrganizationFilter } from "./DashboardOrganizationFilter";
import { DashboardOrganizationProvider } from "./DashboardOrganizationProvider";
import HighestAndLowestImpactProducts from "./HighestAndLowestImpactProducts";
import ProductsByDietChart from "./ProductsByDietChart";
import { ProductsByImpactRating } from "./ProductsByImpactRating";
import ProductsDashboardSummary from "./ProductsDashboardSummary";

const tracking = { pageName: "productsDashboard" };

export default function ProductsDashboardPage() {
  const pages = usePages();
  const [impactCategory, setImpactCategory] = useState<ImpactCategory>(
    ImpactCategory.GHG
  );

  return (
    <Page contentHasPadding={false} tracking={tracking}>
      <DashboardOrganizationProvider>
        <div className="StickyPageTitle ProductsDashboardPage_Header">
          <Page.Title
            breadcrumb={pages.Dashboard().breadcrumb()}
            title={pages.Dashboard().title}
            inlineComponentEnd={
              <ProductsDashboardPageControls
                impactCategory={impactCategory}
                setImpactCategory={setImpactCategory}
              />
            }
            rightAlignEndComponent={true}
          />
        </div>
        <ProductsDashboardPageContent impactCategory={impactCategory} />
      </DashboardOrganizationProvider>
    </Page>
  );
}

interface ProductsDashboardPageControlsProps {
  impactCategory: ImpactCategory;
  setImpactCategory: (impactCategory: ImpactCategory) => void;
}

export function ProductsDashboardPageControls(
  props: ProductsDashboardPageControlsProps
) {
  const { impactCategory, setImpactCategory } = props;

  const additionalImpactCategories = useAdditionalImpactCategories();
  const availableImpactCategories = useAvailableImpactCategories();
  const isHeadOffice = useIsHeadOffice();

  const impactCategoryToggle = additionalImpactCategories ? (
    <ImpactCategoryToggle
      onChange={setImpactCategory}
      options={availableImpactCategories}
      selectedImpactCategory={impactCategory}
      type={ImpactCategoryToggleType.PAGE}
    />
  ) : null;

  return (
    <div className="ProductsDashboardPageControls">
      {isHeadOffice && <DashboardOrganizationFilter />}
      {impactCategoryToggle}
    </div>
  );
}

interface ProductsDashboardPageContentProps {
  impactCategory: ImpactCategory;
}

export function ProductsDashboardPageContent(
  props: ProductsDashboardPageContentProps
) {
  const { impactCategory } = props;
  const [dashboardDataStatus] = useProductsDashboardData();
  const { status: impactRatingInfosStatus } = useImpactRatingInfos();

  return (
    <StatusDisplay.Many<
      [ProductsDashboardData | null, ProportionsChartImpactRatingInfos]
    >
      statuses={[dashboardDataStatus, impactRatingInfosStatus]}
    >
      {(dashboardData, impactRatingInfos) => {
        return dashboardData !== null ? (
          <div className="ProductsDashboardPageContent">
            <ProductsDashboardSummary
              impacts={dashboardData}
              impactCategory={impactCategory}
              impactRatingProportions={dashboardData.impactRatingProportions}
              productsCount={dashboardData.numberOfProducts}
              numChildOrganizations={
                dashboardData.numberOfDescendantOrganizations
              }
            />
            <div className="ProductsDashboardCharts">
              <div className="ProductsByRating_And_HighestAndLowestImpactProducts_Container">
                <Panel className="ProductsByRatingChart ProductsDashboardPage_Panel">
                  <ProductsByImpactRating
                    impactCategory={impactCategory}
                    impactRatingInfos={impactRatingInfos}
                    impactRatingProportions={
                      dashboardData.impactRatingProportions
                    }
                  />
                </Panel>
                <Panel className="HighestAndLowestImpactProducts ProductsDashboardPage_Panel">
                  <HighestAndLowestImpactProducts
                    highestImpactProduct={getHighestOrLowestImpactProduct(
                      dashboardData,
                      impactCategory,
                      "highest"
                    )}
                    impactCategory={impactCategory}
                    lowestImpactProduct={getHighestOrLowestImpactProduct(
                      dashboardData,
                      impactCategory,
                      "lowest"
                    )}
                  />
                </Panel>
              </div>
              <div className="ProductsByDietChart_Container">
                <Panel className="ProductsByDietChart ProductsDashboardPage_Panel">
                  <ProductsByDietChart
                    dietaryCategories={dashboardData.dietaryCategories}
                  />
                </Panel>
              </div>
            </div>
            <Panel className="AverageImpactIntensityByTag ProductsDashboardPage_Panel">
              <AverageImpactIntensityByTag
                impactCategory={impactCategory}
                tags={dashboardData.tags}
              />
            </Panel>
          </div>
        ) : (
          <FormattedMessage
            id="components/dashboard/ProductsDashboardPage:noDataFound"
            defaultMessage="No product data found."
          />
        );
      }}
    </StatusDisplay.Many>
  );
}

function getHighestOrLowestImpactProduct(
  data: {
    highestImpactProductGhg: DashboardProduct | null;
    lowestImpactProductGhg: DashboardProduct | null;
    highestImpactProductLandUse: DashboardProduct | null;
    lowestImpactProductLandUse: DashboardProduct | null;
    highestImpactProductWaterUse: DashboardProduct | null;
    lowestImpactProductWaterUse: DashboardProduct | null;
  },
  impactCategory: ImpactCategory,
  highestOrLowest: "highest" | "lowest"
) {
  if (impactCategory === ImpactCategory.GHG) {
    switch (highestOrLowest) {
      case "highest":
        return data.highestImpactProductGhg;
      case "lowest":
        return data.lowestImpactProductGhg;
    }
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    switch (highestOrLowest) {
      case "highest":
        return data.highestImpactProductLandUse;
      case "lowest":
        return data.lowestImpactProductLandUse;
    }
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    switch (highestOrLowest) {
      case "highest":
        return data.highestImpactProductWaterUse;
      case "lowest":
        return data.lowestImpactProductWaterUse;
    }
  } else {
    assertNever(impactCategory, "Unsupported impact category");
  }
}
