import classNames from "classnames";
import { gql } from "graphql-tag";
import { ReactElement } from "react";
import FlipMove from "react-flip-move";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import useUserInfo from "../../data-store/useUserInfo";
import EffectType, {
  impactCategoryToEffectTypePerKg,
} from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import { useLabels } from "../../services/useOrganizationFeatures";
import assertNever from "../../util/assertNever";
import {
  foodstepsTurquoiseCol,
  interfaceGrey,
  whiteCol,
} from "../graphs/colors";
import Label from "../labels/Label";
import { RecipesListPageQueryParams } from "../pages";
import Card from "../utils/Card";
import Checkbox from "../utils/Checkbox";
import LoadMoreButton from "../utils/LoadMoreButton";
import { ProgressBar } from "../utils/ProgressBar";
import Spinner from "../utils/Spinner";
import {
  AttentionTriangle,
  ErrorTriangle,
  ImpactPendingCircle,
  ImpactUnavailableDash,
  Sharing,
} from "../utils/Vectors";
import * as RecipesListPageLocationState from "./RecipesListPageLocationState";
import * as RecipeStatus from "./RecipeStatus";
import { ResultsGrid_Recipe as Recipe } from "./ResultsGrid.graphql";
import { useRecipeImpactIsUnavailable } from "./useRecipeImpactIsUnavailable";
import "./ResultsGrid.css";

type RecipeImpact = Recipe["impact"];

const EIGHTY_FIFTH_PERCENTILE_LAND_USE_VALUE = 8.514821;
const EIGHTY_FIFTH_PERCENTILE_WATER_USE_VALUE = 1094.554349;
const EIGHTY_FIFTH_PERCENTILE_GHG_VALUE = 7.5; // Corresponds to the D/E label boundary.

interface GridItemProgressBarProps {
  backgroundColour: string;
  effectType: EffectType;
  isStale: boolean;
  proportion: number;
  unit: string;
  value: number | null;
}

function GridItemProgressBar(props: GridItemProgressBarProps) {
  const { backgroundColour, effectType, isStale, proportion, unit, value } =
    props;
  return (
    <div
      className={classNames("d-flex mb-0 mt-auto", {
        ResultsGrid_StaleImpact: isStale,
      })}
    >
      {value !== null ? (
        <div className="ResultsGrid_GridItemProgressBarContainer">
          <h6 className="small text-nowrap my-auto">{unit}</h6>
          <ProgressBar
            backgroundColour={backgroundColour}
            colour={foodstepsTurquoiseCol}
            style={{ width: "100%" }}
            value={proportion}
          />
          <h6 className="small text-nowrap my-auto">
            {value.toFixed(effectType.decimals)}
          </h6>
        </div>
      ) : null}
    </div>
  );
}

interface GridItemProps {
  impactCategory: ImpactCategory;
  item: Recipe;
  onSelect: (id: number, newSelected: boolean) => void;
  queryParams: RecipesListPageQueryParams;
  recipeUrl: (recipe: Recipe) => string;
  selected: boolean;
  showSharedIcon: boolean;
  waitingForFreshImpacts: boolean;
}

function GridItem(props: GridItemProps) {
  const {
    impactCategory,
    item,
    onSelect,
    queryParams,
    recipeUrl,
    selected,
    showSharedIcon,
    waitingForFreshImpacts,
  } = props;

  const intl = useIntl();
  const recipeImpactIsUnavailable = useRecipeImpactIsUnavailable;

  const effectType = impactCategoryToEffectTypePerKg(impactCategory);

  const handleSelect = (selected: boolean) => {
    onSelect(item.id, selected);
  };

  const getValue = () => {
    if (recipeImpactIsUnavailable(item.impact, impactCategory)) {
      return null;
    }
    return effectType.get({
      effects: item.impact.effects,
    });
  };

  const value = getValue();

  const proportion = () => {
    if (impactCategory === ImpactCategory.GHG) {
      /* The boundary for an E rating is 10.845 CO2e */
      return value ? Math.min(1, value / EIGHTY_FIFTH_PERCENTILE_GHG_VALUE) : 0;
    } else if (impactCategory === ImpactCategory.LAND_USE) {
      return value
        ? Math.min(1, value / EIGHTY_FIFTH_PERCENTILE_LAND_USE_VALUE)
        : 0;
    } else if (impactCategory === ImpactCategory.WATER_USE) {
      return value
        ? Math.min(1, value / EIGHTY_FIFTH_PERCENTILE_WATER_USE_VALUE)
        : 0;
    } else {
      assertNever(impactCategory, "Unsupported ImpactCategory");
    }
  };

  const isStale = item.impact.isStale;

  return (
    <Link
      className="d-block text-decoration-none h-100"
      to={RecipesListPageLocationState.queryParamsToLocationDescriptor(
        recipeUrl(item),
        queryParams
      )}
    >
      <Card
        className="grid-card"
        fullHeight
        selected={selected}
        styleCardOnHover={false}
      >
        <div className="d-flex h-100">
          <div className="flex-grow-1 d-flex h-100 flex-column">
            <div className="d-flex mb-4 flex-row">
              <Checkbox
                checked={selected}
                propagateOnClick={false}
                onChange={handleSelect}
              />
              {showSharedIcon && (
                <Sharing
                  className="sharing-icon"
                  fill={selected ? whiteCol : interfaceGrey}
                  width={22}
                  height={22}
                />
              )}
              <div className="medium-font">{item.name}</div>
            </div>
            {isStale && waitingForFreshImpacts && (
              <div className="ResultsGrid_GridItem_SpinnerContainer">
                <Spinner className="ResultsGrid_GridItem_Spinner" />
              </div>
            )}
            <GridItemProgressBar
              backgroundColour={selected ? "#ffffff" : "#e0e0de"}
              effectType={effectType}
              value={value}
              proportion={proportion()}
              isStale={isStale}
              unit={effectType.unitString(intl)}
            />
          </div>
          <div
            className={classNames("mt-auto", {
              ResultsGrid_StaleImpact: isStale,
            })}
          >
            <RecipeStatusDisplay
              impact={item.impact}
              impactCategory={impactCategory}
            />
          </div>
        </div>
      </Card>
    </Link>
  );
}

interface RecipeStatusDisplayProps {
  impact: RecipeImpact;
  impactCategory: ImpactCategory;
}

function RecipeStatusDisplay(
  props: RecipeStatusDisplayProps
): ReactElement | null {
  const { impact, impactCategory } = props;

  const status = RecipeStatus.useRecipeStatus(impact, impactCategory);
  const hasFeatureLabels = useLabels();
  const [{ locale }] = useUserInfo();

  if (status.type === "internalError") {
    return (
      <RecipeStatusBaseDisplayNew
        icon={ErrorTriangle}
        paragraphClassName="internal-error"
        text={
          <FormattedMessage
            defaultMessage="Unknown Error!"
            id="components/recipes/ResultsGrid:UnknownError"
          />
        }
      />
    );
  } else if (status.type === "requiresClientAttention") {
    return (
      <RecipeStatusBaseDisplayNew
        icon={AttentionTriangle}
        paragraphClassName="needs-attention"
        text={
          <FormattedMessage
            defaultMessage="Needs Attention!"
            id="components/recipes/ResultsGrid:NeedsAttention"
          />
        }
      />
    );
  } else if (status.type === "pending") {
    return (
      <RecipeStatusBaseDisplayNew
        icon={ImpactPendingCircle}
        paragraphClassName="pending"
        text={
          <FormattedMessage
            defaultMessage="Impact Pending"
            id="components/recipes/ResultsGrid:ImpactPending"
          />
        }
      />
    );
  } else if (status.type === "noImpactRating") {
    return null;
  } else if (status.type === "hasImpactRating") {
    return hasFeatureLabels ? (
      <div className="ml-3">
        <Label
          colourSetting="colour"
          type="ratingScale"
          impactCategory={impactCategory}
          impactRating={status.impactRating}
          width={104}
          locale={locale}
        />
      </div>
    ) : null;
  } else if (status.type === "unavailable") {
    return (
      <RecipeStatusBaseDisplayNew
        icon={ImpactUnavailableDash}
        paragraphClassName="unavailable"
        text={
          <FormattedMessage
            defaultMessage="Impact Unavailable"
            id="components/recipes/ResultsGrid:ImpactUnavailable"
          />
        }
      />
    );
  } else {
    return assertNever(status, "unhandled status");
  }
}

interface RecipeStatusBaseDisplayNewProps {
  icon: ({
    className,
    width,
  }: {
    className: string;
    width: string;
  }) => ReactElement;
  paragraphClassName: string;
  text: ReactElement;
}

function RecipeStatusBaseDisplayNew(props: RecipeStatusBaseDisplayNewProps) {
  const { icon, paragraphClassName, text } = props;

  return (
    <div className="grid-recipe-status ml-3">
      <div className="icon-wrapper">
        {icon({ className: "icon", width: "100%" })}
      </div>
      <p className={classNames("Graphik-Medium-Web", paragraphClassName)}>
        {text}
      </p>
    </div>
  );
}

interface ResultsGridProps {
  impactCategory: ImpactCategory;
  onRecipesLoadMore: null | (() => Promise<void>);
  onSelect: (id: number, selected: boolean) => void;
  selectedRecipeIds: number[];
  queryParams: RecipesListPageQueryParams;
  recipes: Array<Recipe>;
  recipeUrl: (recipe: Recipe) => string;
  showSharedIcon: boolean;
  waitingForFreshImpacts: boolean;
}

export default function ResultsGrid(props: ResultsGridProps) {
  const {
    impactCategory,
    onRecipesLoadMore,
    onSelect,
    queryParams,
    recipes,
    recipeUrl,
    selectedRecipeIds,
    showSharedIcon,
    waitingForFreshImpacts,
  } = props;

  return (
    <>
      <FlipMove
        duration={240}
        staggerDelayBy={50}
        appearAnimation="elevator"
        enterAnimation="elevator"
        leaveAnimation="fade"
        style={{
          display: "grid",
          gridGap: "0 30px",
          gridTemplateColumns: "repeat(auto-fill, minmax(25rem, 1fr))",
        }}
      >
        {recipes.map((x) => (
          <div className="mb-3" key={x.id}>
            <GridItem
              impactCategory={impactCategory}
              item={x}
              onSelect={onSelect}
              queryParams={queryParams}
              recipeUrl={recipeUrl}
              selected={selectedRecipeIds.includes(x.id)}
              showSharedIcon={showSharedIcon}
              waitingForFreshImpacts={waitingForFreshImpacts}
            />
          </div>
        ))}
      </FlipMove>
      <LoadMoreButton onClick={onRecipesLoadMore} />
    </>
  );
}

ResultsGrid.fragments = {
  recipes: gql`
    fragment ResultsGrid_Recipe on Recipe {
      id
      ownerOrganizationId
      impact(excludePackaging: false, fetchStaleImpacts: $fetchStaleImpacts) {
        effects {
          ghgPerKg
          ghgPerRootRecipeServing
          landUsePerKg
          landUsePerRootRecipeServing
          waterUsePerKg
          waterUsePerRootRecipeServing
        }
        isStale
        weightKgPerServing
        ...RecipeStatus_RecipeImpact
      }
      name
    }
    ${RecipeStatus.fragments.recipeImpact}
  `,
};
