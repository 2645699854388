import { ImpactRating } from "../../__generated__/globalTypes";
import { ImpactCategory } from "../../domain/impactCategories";
import Card from "../utils/Card";
import RecipePageLabel from "./RecipePageLabel";

const stories = {
  name: "RecipePageCarbonLabel",
  render: () => (
    <Card className="w-25">
      <RecipePageLabel
        impactCategory={ImpactCategory.GHG}
        impactMagnitude={1.23}
        impactRating={ImpactRating.LOW}
        recipeId={-1}
        showExportButton={true}
      />
    </Card>
  ),
};

export default stories;
