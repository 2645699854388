import VectorProps from "./VectorProps";

export default function TooltipCaratLeft(props: VectorProps) {
  const { className = "", fill = "currentColor", width } = props;

  return (
    <svg
      className={className}
      width={width}
      viewBox="0 0 14 6"
      xmlns="http://www.w3.org/2000/svg"
      transform="rotate(90)"
    >
      <path d="M7 6L0.0717964 0L13.9282 0L7 6Z" fill={fill} />
    </svg>
  );
}
