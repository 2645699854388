import classNames from "classnames";
import { FormattedMessage } from "react-intl";

import { SystemBoundary } from "../../__generated__/globalTypes";
import { SystemBoundary as SystemBoundaryIcon } from "../utils/Vectors";
import SystemBoundaryText from "./SystemBoundaryText";
import "./SystemBoundaryDisplay.css";

interface SystemBoundaryDisplayProps {
  className?: string;
  inactive?: boolean;
  systemBoundary: SystemBoundary;
}

export default function SystemBoundaryDisplay(
  props: SystemBoundaryDisplayProps
) {
  const { className = "", inactive = false, systemBoundary } = props;
  return (
    <div
      className={classNames(className, "SystemBoundaryDisplay_Card", {
        SystemBoundaryDisplay_Inactive: inactive,
      })}
    >
      <div>
        <SystemBoundaryIcon fill="var(--input-border)" width={20} />
      </div>
      <div className="small-copy">
        <FormattedMessage
          id="components/recipes/SystemBoundaryDisplay:systemBoundary"
          defaultMessage="System Boundary"
        />
        :
        <div className="medium-font">
          <SystemBoundaryText systemBoundary={systemBoundary} />
        </div>
      </div>
    </div>
  );
}
